import $ from 'jquery';
import DOMPurify from 'dompurify';
import * as marked from 'marked';
import { mangle } from 'marked-mangle';
import axios from 'axios';
// import Functions
import * as ChatFuncJSX from './Chat';
import API_VERSION from '../ApiVersion';
// import i18n
import i18n from '../../../i18n/i18n';
import Cookies from 'js-cookie';

// -- Only images, videos and audio
const acceptedImage = [
    // IMAGE
    "image/apng",
    "image/x-bmp",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/tiff",
    "image/x-icon",
    "image/avif",
    "image/svg+xml",
    "image/webp",
    "image/heif",
    "image/heif-sequence",
    "image/heic",
    "image/heic-sequence",
    "image/jp2",
    "image/jpx",
    "image/jpm",
    "image/avci",
    "image/aces",
]
const acceptedVideo = [
    "video/mj2",
    "video/mp4",
    "video/mp2t",
    "video/x-msvideo",
    "video/mpeg",
    "video/ogg",
    "video/webm",
    "video/3gpp",
    "video/3gpp2",
    "video/3gpp-tt",
    "video/AV1",
    "video/H264",
    "video/matroska",
    "video/raw",
]

// Set default functions and variables
const isEmpty = (e) => {
    return !e.html()
}

const handleEnter = (evt) => {
    if (evt.key === 'Enter' && !evt.shiftKey) {
        evt.preventDefault();
        $(".textArea_bBAChatMVB1").trigger("input");
        if ($(".textArea_bBAChatMVB1").text().trim() !== "") {
            $('#send').trigger('click');
        }
    }
}

// Go to [message]
const goToMessage = (msg) => {
    window.scrollTo(0, document.querySelector('.msg_'+msg).scrollHeight - 20);
}

// -- Auto-resize text area
const resizeTextArea = () => {
    var e = document.querySelector('.textArea_bBAChatMVB1');
    $(".textArea_bBAChatMVB1").css({overflowY:"hidden",margin:10,marginRight:0})

    e.style.height = 0;
    e.style.height = (e.scrollHeight) + "px";
    if (document.getElementById("filesContainer").style.display === "flex" && !isEmpty($("#filesContainer"))) {
        document.getElementById("reply_edit").style.marginBottom = 'calc('+e.scrollHeight+'px + 118px)';
        document.getElementById("filesContainer").style.marginBottom = 'calc('+e.scrollHeight+'px - 58px)';
        if(document.getElementById("reply_edit").style.display === "flex"){
            document.getElementById("typing").style.marginBottom = 'calc('+e.scrollHeight+'px + 167px)';
        }else{
            document.getElementById("typing").style.marginBottom = '168px';
        }
        // Know the current theme
        $("#filesContainer").css('border-top', '2px solid #D9D9D9');
        if(document.body.className === "darkTheme"){
            $("#filesContainer").css('border-color', '#26262C');
        }
    }else {
        document.getElementById("reply_edit").style.marginBottom = 0;
    }

    // If 180px height
    if (e.scrollHeight >= 180) {
        e.style.overflowY = "scroll";
        e.style.height = "180px";
        if (document.getElementById("filesContainer").style.display === "flex" && !isEmpty($("#filesContainer"))) {
            document.getElementById("reply_edit").style.marginBottom = '296px';
            document.getElementById("filesContainer").style.marginBottom = '120px';
        }else {
            document.getElementById("reply_edit").style.marginBottom = '120px';
        }
    }
}

/* --------------
 * CHAT FUNCTIONS
 * --------------
 */
// Edit a message/chat
const editChat = ({uid, content, userID, key}) => {
    var bottomBarActionsHeight = document.querySelector('.bottomBarActions_chatFMVB1').offsetHeight;

    $("#reply_edit").css({display:'flex',bottom: bottomBarActionsHeight});
    // -- If file menu is displayed
    if (document.getElementById("filesContainer").style.display === "flex" && !isEmpty($("#filesContainer"))) {
        totalHeight = $('.bottomBarActions_chatFMVB1').height() + $('#filesContainer').height();
        document.getElementById("reply_edit").style.marginBottom = 'calc('+totalHeight+'px - 83px)';
    }
    // -- If typing container is displayed
    if (document.getElementById("typing").style.display === "flex" && document.getElementById("filesContainer").style.display === "none") {
        totalHeight = $('.bottomBarActions_chatFMVB1').height() + $('#typing').height();
        document.getElementById("typing").style.marginBottom = 'calc('+totalHeight+'px - 83px)';
    }else if(document.getElementById("typing").style.display === "flex" && document.getElementById("filesContainer").style.display === "flex" && !isEmpty($("#filesContainer"))){
        var totalHeight = $('.bottomBarActions_chatFMVB1').height() + $("#filesContainer").height() + $('#reply_edit').height();
        document.getElementById("typing").style.marginBottom = 'calc('+totalHeight+'px - 83px)';
    }
    // UI / UX
    $("#reply_editText").attr("data-action", "edit");
    $("#reply_editText").text("Edit this chat");
    if(i18n.resolvedLanguage === "fr"){
        $("#reply_editText").text("Modifier le message");
    }
    $('.textArea_bBAChatMVB1').css({height: 'auto', marginBottom: 18});
    $('.textArea_bBAChatMVB1').text(content);

    $("#send").off('click').on('click', () => {
        var textarea = $(".textArea_bBAChatMVB1").text().trim();
        if (textarea !== "") {
            // For Marked.js
            const renderer = {
                link(href) {
                    return `<a href="${href}" target="_blank" rel="noreferrer" title="${href.replace('mailto:', '')}">${href.replace('mailto:', '')}</a>`;
                }
            };

            marked.use({
                breaks: true,
                renderer,
            }, mangle());

            // Set variables for text inputs
            var message = DOMPurify.sanitize(marked.parseInline($('.textArea_bBAChatMVB1').text()), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']});
            var textonly = DOMPurify.sanitize($('.textArea_bBAChatMVB1').text(), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']});

            if (textarea !== "" && isEmpty($('.msg_'+uid+' .attachFiles_chatFMMVB1'))) {
                // Edit message
                message = message.replaceAll(" <img", "&nbsp;<img");
                message = message.replaceAll("\"> ", "\">&nbsp;");
                // ...
                axios.put(
                    `https://api.snot.fr/v${API_VERSION}/chat/${key}/edit/${uid}`,
                    { sender: userID, content: message, textonly: textonly },
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        }
                    }
                ).then(res => {
                    $('.textArea_bBAChatMVB1').text('');
                    $("#reply_edit").css('display', 'none');
                    $("#reply_editText").attr("data-action", "");
                    $("#reply_editText").html("");
                })
            }else if (!isEmpty($('.msg_'+uid+' .attachFiles_chatFMMVB1'))) {
                // Edit message
                message = message.replaceAll(" <img", "&nbsp;<img");
                message = message.replaceAll("\"> ", "\">&nbsp;");
                // ...
                axios.put(
                    `https://api.snot.fr/v${API_VERSION}/chat/${key}/edit/${uid}`,
                    { sender: userID, content: message, textonly: textonly },
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        }
                    }
                ).then(res => {
                    $('.textArea_bBAChatMVB1').text('');
                    $('.textArea_bBAChatMVB1').css('height', 50);
                    $("#reply_edit").css('display', 'none');
                    $("#reply_editText").attr("data-action", "");
                    $("#reply_editText").html("");
                })
            }
        }
    });
}

// Close "Reply/Edit" container
const closeRE = () => {
    var container = $("#reply_edit");
    container.css("display", "none");
    if ($('#reply_editText').attr('data-action') === "edit") {
        $('.textArea_bBAChatMVB1').text(null);
    }
    // -- If typing container is displayed
    if (document.getElementById("typing").style.display === "flex" && document.getElementById("filesContainer").style.display === "none") {
        document.getElementById("typing").style.marginBottom = 0;
    }else if(document.getElementById("typing").style.display === "flex" && document.getElementById("filesContainer").style.display === "flex" && !isEmpty($("#filesContainer"))){
        var totalHeight = $('.bottomBarActions_chatFMVB1').height() + $("#filesContainer").height();
        document.getElementById("typing").style.marginBottom = 'calc('+totalHeight+'px - 83px)';
    }
}

// -- Reply to a chat
const replyChat = ({user, friend, key, reply: {uid, content, to}, pusher, theme}) => {
    var totalHeight = null;

    $("#reply_edit").css('display', 'flex');
    // -- If file menu is displayed
    if (document.getElementById("filesContainer").style.display === "flex" && !isEmpty($("#filesContainer"))) {
        totalHeight = $('.bottomBarActions_chatFMVB1').height() + $('#filesContainer').height();
        document.getElementById("reply_edit").style.marginBottom = 'calc('+totalHeight+'px - 83px)';
    }
    // -- If typing container is displayed
    if (document.getElementById("typing").style.display === "flex" && document.getElementById("filesContainer").style.display === "none") {
        totalHeight = $('.bottomBarActions_chatFMVB1').height() + $('#typing').height();
        document.getElementById("typing").style.marginBottom = 'calc('+totalHeight+'px - 83px)';
    }else if(document.getElementById("typing").style.display === "flex" && document.getElementById("filesContainer").style.display === "flex" && !isEmpty($("#filesContainer"))){
        var totalHeight = $('.bottomBarActions_chatFMVB1').height() + $("#filesContainer").height() + $('#reply_edit').height();
        document.getElementById("typing").style.marginBottom = 'calc('+totalHeight+'px - 83px)';
    }
    // UI / UX
    $("#reply_editText").attr("data-action", "reply");
    $("#reply_editText").text("Reply to @"+to.toLowerCase().replace('.', '_'));
    if(i18n.resolvedLanguage === "fr"){
        $("#reply_editText").text("Répondre à @"+to.toLowerCase().replace('.', '_'));
    }
    // -- Focus to chat bar
    $('.textArea_bBAChatMVB1').trigger('focus');
    // -- For vocal reply
    $("#reply_editText").attr("data-reply-uid", uid);
    $("#reply_editText").attr("data-reply-content", content);
    // Send message
    $("#send").on('click', () => {
        if (DOMPurify.sanitize($(".textArea_bBAChatMVB1").text().trim(), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']}) !== "") {
            ChatFuncJSX.sendMessage({ user: user, friend: friend, key: key, action: "reply", reply: { content: content, uid: uid }, pusher: { presence: pusher }, theme: theme });
        }
    })
}

/* ---------------------
 * FILE UPLOAD FUNCTIONS
 * ---------------------
 */
var fileList = [];

const filePreview = (evt, settingImages) => {
    if ($("#file")[0].files.length > 10) {
        $("#open-error.fileLimit").trigger('click');
    }else {
        if(window.File && window.FileList && window.FileReader) {
            var files = evt.target.files;
            var filesContainer = document.getElementById('filesContainer');

            for(var i = 0; i < files.length; i++){
                fileList.push(files[i]);

                if (files[i].size <= 50000000) {
                    // -- Create div
                    var div = document.createElement("div");
                        div.id = "file_uploaded";

                    if(settingImages === "Enabled"){
                        if (validFileType(files[i].type)) {
                            // UX
                            document.getElementById("reply_edit").style.marginBottom = '176px';
                            if (document.getElementById("reply_edit").style.display === 'none') {
                                document.getElementById("typing").style.marginBottom = '176px';
                            }else {
                                document.getElementById("typing").style.marginBottom = '226px';
                            }
                            // UI
                            $("#filesContainer").css('border-top', '2px solid #D9D9D9');
                            if(document.body.className === "darkTheme"){
                                $("#filesContainer").css('border-top', '2px solid #26262C');
                            }
                            
                            // -- Display preview
                            fileList.forEach((image, index) => {
                                localStorage.setItem('file_'+index, JSON.stringify({type: image.type, name: image.name, preview: URL.createObjectURL(image)}));
                                // UI
                                var preview = URL.createObjectURL(image);
                                div.innerHTML = '<div id="actions"><div class="deleteFile_fileFUMVB1" data-index="'+index+'"><i class="fi fi-rr-trash"></i></div></div><div class="fileContainer_fileFUMVB1"><img id="preview" title="'+image.name+'" src="'+preview+'"></div><div id="fileInfos"><span id="name">'+image.name+'</span></div>';
                            })
                            // -- -- Append 'div'
                            filesContainer.insertBefore(div, null);
                            filesContainer.style.display = "flex";
                            // ...
                            localStorage.setItem('file_length', fileList.length);
                            resizeTextArea();
                        }else {
                            $("#open-error.notAllowed").trigger('click');
                        }
                    }else{
                        $("#filesContainer").css('border-top', '2px solid #D9D9D9');
                        if(document.body.className === "darkTheme"){
                            $("#filesContainer").css('border-color', '#26262C');
                        }

                        // -- Display preview
                        fileList.forEach((image, index) => {
                            localStorage.setItem('file_'+index, JSON.stringify({type: image.type, name: image.name, preview: URL.createObjectURL(image)}));
                            // UI
                            div.className = 'file_'+index;
                            var preview = URL.createObjectURL(image);
                            if (validFileType(image.type)) {
                                div.innerHTML = '<div id="actions"><div class="deleteFile_fileFUMVB1" data-index="'+index+'"><i class="fi fi-rr-trash"></i></div></div><div class="fileContainer_fileFUMVB1"><img id="preview" title="'+image.name+'" src="'+preview+'"></div><div id="fileInfos"><span id="name">'+image.name+'</span></div>';
                            }else if (validFileTypeVideo(image.type)) {
                                div.innerHTML = '<div id="actions"><div class="deleteFile_fileFUMVB1" data-index="'+index+'"><i class="fi fi-rr-trash"></i></div></div><div class="fileContainer_fileFUMVB1"><img id="preview" title="'+image.name+'" src="https://api.snot.fr/v'+API_VERSION+'/content/video_icon?ext=png"></div><div id="fileInfos"><span id="name">'+files[i].name+'</span></div>';
                            }else if (image.type === "text/plain") {
                                div.innerHTML = '<div id="actions"><div class="deleteFile_fileFUMVB1" data-index="'+index+'"><i class="fi fi-rr-trash"></i></div></div><div class="fileContainer_fileFUMVB1"><img id="preview" title="'+image.name+'" src="https://api.snot.fr/v'+API_VERSION+'/content/txt_icon?ext=png"></div><div id="fileInfos"><span id="name">'+files[i].name+'</span></div>';
                            }else if (image.type === "application/pdf") {
                                div.innerHTML = '<div id="actions"><div class="deleteFile_fileFUMVB1" data-index="'+index+'"><i class="fi fi-rr-trash"></i></div></div><div class="fileContainer_fileFUMVB1"><img id="preview" title="'+image.name+'" src="https://api.snot.fr/v'+API_VERSION+'/content/pdf_icon?ext=png"></div><div id="fileInfos"><span id="name">'+files[i].name+'</span></div>';
                            }else {
                                div.innerHTML = '<div id="actions"><div class="deleteFile_fileFUMVB1" data-index="'+index+'"><i class="fi fi-rr-trash"></i></div></div><div class="fileContainer_fileFUMVB1"><img id="preview" title="'+image.name+'" src="https://api.snot.fr/v'+API_VERSION+'/content/base_file?ext=png"></div><div id="fileInfos"><span id="name">'+files[i].name+'</span></div>';
                            }
                            // -- -- Add event listener
                            div.querySelector('.deleteFile_fileFUMVB1').addEventListener('click', () => deleteFile(index));
                        });
                        // -- -- Append 'div'
                        filesContainer.insertBefore(div, null);
                        filesContainer.style.display = "flex";
                        // ...
                        localStorage.setItem('file_length', fileList.length);
                        resizeTextArea();
                    }
                }else {
                    $("#open-error.sizeLimit").trigger('click');
                }
            }
        }else {
            console.log("Your browser does not support File API");
        }
    }
}

const validFileType = (file) => {
    return acceptedImage.includes(file);
}
const validFileTypeVideo = (file) => {
    return acceptedVideo.includes(file);
}

// Delete file preview
const deleteFile = (index) => {
    fileList.splice(index, 1);
    localStorage.removeItem('file_'+index);
    if(fileList.length === 0){
        localStorage.removeItem('file_length');
    }else{
        localStorage.setItem('file_length', fileList.length);
    }
    // UI
    $('.file_'+index).remove();
    document.getElementById("reply_edit").style.marginBottom = 'auto';
    if (document.getElementById("reply_edit").style.display === 'none') {
        document.getElementById("typing").style.marginBottom = 'auto';
    }else {
        document.getElementById("typing").style.marginBottom = '50px';
    }
    // ...
    if(isEmpty($('#filesContainer'))){
        $("#filesContainer").css({border: 'none',display:'none'});
    }
    resizeTextArea();
}

export {
    isEmpty,
    handleEnter,
    goToMessage,
    editChat,
    replyChat,
    closeRE,
    filePreview,
    deleteFile,
}