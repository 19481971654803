import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HtmlToReact from 'html-to-react';
import $ from 'jquery';
import axios from "axios";
import Cookies from "js-cookie";
import copy from 'copy-to-clipboard';
// import i18n
import { useTranslation } from "react-i18next";
import i18n from "../../i18n/i18n";
// import Loading Components
import PostLoading, { SpostLoading, PostLoadingWithMedia } from '../../Components/Loading/LoadingPost';
// import Components
import Video from '../Common/Media/VideoPlayer';
import Audio from '../Common/Media/AudioPlayer';
import { CopyComButton, CopyReplyButton, DeleteComButton, DeleteReplyButton, EditComButton, EditReplyButton, ReportComButton, ReportReplyButton } from "../Modals/Home/ButtonsMore";
import { EditCommentPopUp } from "../Modals/Home/Edit/EditComment";
import { EditReplyPopUp } from "../Modals/Home/Edit/EditReply";
import {ReplyPopUp, ReplyToPopUp} from '../Modals/Home/AddReply';
// import Functions
import { API_VERSION, changeMedia } from "../Functions";
import * as Home from "../Functions/Home";
import * as Profile from "../Functions/Profile";
import { openModal } from "../Functions/Modal";
import { retreiveInfos } from "../Functions/Auth";
import { openMenu } from "../Functions/Menu";
import * as Settings from '../Functions/Settings';
// import Images
import errorMediaDark from '../../img/error/media_dark.png';
import errorMediaLight from '../../img/error/media_light.png';

// -- Only images, videos and audio
const acceptedImage = [
    "apng",
    "bmp",
    "gif",
    "jpg",
    "jpeg",
    "pjpeg",
    "png",
    "tiff",
    "tif",
    "ico",
    "avif",
    "svg",
    "webp",
    "heif",
    "heifs",
    "heic",
    "heics",
    "jp2",
    "j2k",
    "jpx",
    "jpm",
    "avci",
    "aces",
]
const acceptedVideo = [
    "webm",
    "mj2",
    "mjp2",
    "mp4",
    "m4v",
    "ts",
    "avi",
    "mpeg",
    "ogv",
    "3gp",
    "3g2",
    "mov",
    "mkv",
    "mk3d",
    "flv",
    "wmf",
    "vob",
]
const acceptedAudio = [
    '3gp',
    '3g2',
    'aac',
    'ac3',
    'aa',
    'aax',
    "aiff",
    "alac",
    'mka',
    'midi',
    'mid',
    'm4a',
    "m4b",
    "m4p",
    "mpc",
    'mp3',
    'opus',
    'oga',
    'wav',
    'weba'
]

// NSFW TERMS
const nsfwTerms = ["nsfw","nsfw (+18)","nsfw +18","nsfw+18","nsfw 18+","nsfw(18+)","nsfw18+","nsfw 18","nsfw(18)","nsfw18","porn","porns","pornography","porno","gore","gory"]

// TIME SINCE
function timeSince(date, langYear, langDay) {
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + langYear;
    }
    interval = seconds / 2592000;

    if (interval > 1) {
      return Math.floor(interval) + "mo";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + langDay;
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + "h";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + "min";
    }
    return Math.floor(seconds) + " s";
}

export const PostUID = ({ data: {uid, sender, title, content, content_clear, media, tags, date, likes, shares}, theme, logged }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // Capitalize first letter
    function ucFirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // Set data
    const [infos, setInfos] = useState({
        username: '',
        usertag: '',
        avatar: '',
        uid: '',
        date_account: '',
        aboutme: ''
    });
    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>,
    });
    const [commentCount, setCommentCount] = useState(0);
    const [mediaHTML, setMedia] = useState(<></>);
    const [myInfos, setMyInfos] = useState([]);
    const [verified, setVerified] = useState({
        follows: '',
        friend: '',
        friendRequest: null
    });
    const [generatedKey, setKey] = useState('');
    const [actionsButton, setActionsButton] = useState({
        follows: <>
        <div className="subscribeBtn_profilePreviewMVB1" role="button" tabIndex={2} data-modal="loginModal">
            <span>Suivre</span>
            <i className="fi fi-rr-plus"></i>
        </div>
        </>,
        friend: <>
        <div className="friendBtn_profilePreviewMVB1" role="button" tabIndex={1} data-modal="loginModal">
            <span>Ajouter en ami</span>
            <i className="fi fi-rr-user-add"></i>
        </div>
        </>
    });
    const [blockedMe, setBlockedMe] =  useState(false);
    const [cantMessage, setCantMessage] = useState(false);
    // Set like & share actions
    const [likeEvent, setLikeEvent] = useState('like');
    const [likeClass, setLikeClass] = useState('unlike');
    const [shareClass, setShareClass] = useState("unshare");
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);
    const [loading, setLoading] = useState(true);

    // Set tags
    var htmlTags = '';
    var nsfw = false;
    if(tags !== null) {
        var arrayTags = JSON.parse(tags);
        for(let i = 0; i < arrayTags.length; i++){
            var nameTag = ucFirst(arrayTags[i]);
            // Personnalize NSFW tag
            if(arrayTags[i].toLowerCase() === "nsfw") { nameTag = "NSFW (+18)";nsfw = true; };
            // NSFW Terms (set NSFW)
            if(nsfwTerms.includes(arrayTags[i].toLowerCase())) {
                if(arrayTags[i].toLowerCase() !== "nsfw"){
                    htmlTags += `<div id="NSFW (+18)" title="NSFW (+18)">NSFW (+18)</div>`;
                }
                nsfw = true;
            };
            // Auto Translation
            if(arrayTags[i] === "popular" || arrayTags[i] === "news" || arrayTags[i] === "advertising"){ nameTag = t('add.form.tags.'+arrayTags[i]) }
            
            // Create tag div
            var translate = {
                "ä": "a", "â": "a", "à": "a", "æ": "ae",
                "ö": "o", "ô": "o",
                "ü": "u", "û": "u", "ù": "u",
                "é": "e", "è": "e", "ê": "e", "ë": "e", "œ": "oe",
                "ï": "i", "î": "i",
                "ñ": "n",
                "ç": "c",
                "Ä": "A", "Â": "A", "À": "A", "Æ": "AE",
                "Ö": "O", "Ô": "O",
                "Ü": "U", "Û": "U", "Ù": "U",
                "É": "e", "È": "E", "Ê": "e", "Ë": "e", "Œ": "OE",
                "Ï": "I", "Î": "I",
                "Ñ": "N",
                "Ç": "C"
            };
            var translate_re = /[öôäâàæüûùéèêëœïîñçÖÔÄÂÀÆÜÛÙÉÈÊËŒÏÎÑÇ]/g;

            htmlTags += `<div id="${arrayTags[i].replace(/[\s]/g, "_").replace(translate_re, (match) => translate[match])}" tabindex="${i+1}" title="${nameTag}">${nameTag}</div>`;
            $('#'+arrayTags[i].replace(/[\s]/g, "_").replace(translate_re, (match) => translate[match])).on('click', () => navigate('/tags?t='+arrayTags[i].replace(/[\s]/g, "_").replace(translate_re, (match) => translate[match])))
        }
    }

    // Set date format
    const sendDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const match_date = new Date(date);
    match_date.setHours(0, 0, 0, 0);

    const diff = Math.floor((today - match_date) / (1000 * 60 * 60 * 24));
    let diffDays = parseInt(diff);
        
    var date_format = "";
    // Get time since
    var langYear = "y";
    var langDay = "d";
    if(i18n.resolvedLanguage === "fr"){
        langYear = "a";
        langDay = "j"
    }

    if(i18n.resolvedLanguage === "fr"){
        switch (diffDays) {
            default:
                date_format = "il y a " + timeSince(sendDate, langYear, langDay);
        } 
    }else {
        switch (diffDays) {
            default:
                date_format = timeSince(sendDate, langYear, langDay) + " ago";
        }
    }

    // For account preview
    var created = t('home.general.preview.before');
    if(infos.date_account !== "Created before the 09/08/23"){
        created = t('home.general.preview.the')+' '+new Date(infos.date_account).toLocaleDateString(i18n.resolvedLanguage, { day: "2-digit", month: "2-digit", year: '2-digit' });
    }
    
    // Set likes and shares count
    // -- For likes
    if (likes >= 1000 && likes < 10000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'K';
    } else if (likes >= 10000 && likes < 100000) {
        likes = likes.toString().substring(0, 2)+','+likes.toString().substring(2, 3)+'K';
    } else if (likes >= 100000 && likes < 1000000) {
        likes = likes.toString().substring(0, 3) + 'K';
    } else if (likes >= 1000000 && likes < 10000000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'M';
    } else if (likes >= 10000000 && likes < 100000000) {
        likes = likes.toString().substring(0, 2)+','+likes.toString().substring(2, 3)+'M';
    } else if (likes >= 100000000 && likes < 1000000000) {
        likes = likes.toString().substring(0, 3) + 'M';
    } else if (likes >= 1000000000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'B';
    }
    // -- For shares
    if (shares >= 1000 && shares < 10000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'K';
    } else if (shares >= 10000 && shares < 100000) {
        shares = shares.toString().substring(0, 2)+','+shares.toString().substring(2, 3)+'K';
    } else if (shares >= 100000 && shares < 1000000) {
        shares = shares.toString().substring(0, 3) + 'K';
    } else if (shares >= 1000000 && shares < 10000000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'M';
    } else if (shares >= 10000000 && shares < 100000000) {
        shares = shares.toString().substring(0, 2)+','+shares.toString().substring(2, 3)+'M';
    } else if (shares >= 100000000 && shares < 1000000000) {
        shares = shares.toString().substring(0, 3) + 'M';
    } else if (shares >= 1000000000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'B';
    }

    // Know if [user] already liked or shared [post]
    const verifyLikeShare = (user, type) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/${user}/actions/${uid}/verify/${type}`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data.count);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    // Generate PM key
    const generateKeyPM = () => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/friends/generate/key`,
                headers: {
                    "Content-Type": "application/json",
                }
            }).then((response) => {
                resolve(response.data.key);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    // Verify if [user] has blocked [user_2]
    const blockedUser = (uid, blocked) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/user/${uid}/blocked/${blocked}`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web',
                    inverse: true
                }
            }).then((response) => {
                resolve(response.data.blocked);
            }).catch((error) => {
                resolve(0);
            })
        })
    }

    /* -----------
     * BUILD MEDIA
     * -----------
     */
    const showMedia = () => {
        // -- If there's a link to a media
        const fileRgx = /\.[0-9a-z]+?=[?#]|\.(?:[\w]+)($|\?)/mi;
        const patterns = /https?:\/\/?[\da-z\.-]+\.[a-z\.]{2,6}[\w\S]*\/?/mis;
        let m1 = fileRgx.exec(content_clear);
        let m2 = patterns.exec(content_clear);

        if ((m1 = fileRgx.exec(content_clear)) !== null) {
            m1.forEach((match) => {
                if(match !== '' && match !== '?'){
                    let fileExt = match.replace('?', '');
                    if (fileExt === '.png' || fileExt === '.jpg' || fileExt === '.jpeg' || fileExt === '.gif' || fileExt === '.bmp' || fileExt === '.tif' || fileExt === '.tiff' || fileExt === '.webp' || fileExt === '.mp4' || fileExt === '.mov' || fileExt === '.avi' || fileExt === '.wmf' || fileExt === '.flv' || fileExt === '.webm' || fileExt === '.mkv' || fileExt === '.ogg' || fileExt === '.vob' || fileExt === '.3gp' || fileExt === '.aa' || fileExt === '.aax' || fileExt === '.aac' || fileExt === '.aiff' || fileExt === '.alac' || fileExt === '.m4a' || fileExt === '.m4b' || fileExt === '.m4p' || fileExt === '.mp3' || fileExt === '.mpc' || fileExt === '.wav') {
                        if ((m2 = patterns.exec(content_clear)) !== null) {
                            m2.forEach((matchImage) => {
                                let spostImage = matchImage;
                                
                                if (spostImage.includes('.mp4') || spostImage.includes('.mov') || spostImage.includes('.avi') || spostImage.includes('.wmf') || spostImage.includes('flv') || spostImage.includes('.webm') || spostImage.includes('.mkv') || spostImage.includes('.ogg') || spostImage.includes('.vob')) {
                                    setMedia(
                                        <div className="mediaSpost" style={{cursor: "default" }}>
                                            <Video videoRef={uid} videoSrc={spostImage} params={{ stopPropagation: true, nsfw: nsfw }} />
                                        </div>
                                    );
                                }else if (spostImage.includes('.png') || spostImage.includes('.jpg') || spostImage.includes('.jpeg') || spostImage.includes('.gif') || spostImage.includes('.bmp') || spostImage.includes('.tif') || spostImage.includes('.tiff') || spostImage.includes('.webp')) {
                                    setMedia(
                                        <div className="mediaSpost" onClick={openModal}>
                                            <img src={spostImage} alt={spostImage} title={spostImage} {...(nsfw === true ? { style: { filter: 'blur(16px)', pointerEvents: "none" } }: {} )}
                                                onError={({ currentTarget }) => {
                                                    if(theme === "darkTheme"){
                                                        currentTarget.src = errorMediaDark;
                                                    }else{
                                                        currentTarget.src = errorMediaLight;
                                                    }
                                                }}
                                                referrerPolicy="no-referrer"
                                                data-modal="seeMedia"
                                            />
                                        </div>
                                    );
                                }else if(spostImage.includes('.3gp') || spostImage.includes('.aa') || spostImage.includes('.aax') || spostImage.includes('.aac') || spostImage.includes('.aiff') || spostImage.includes('.alac') || spostImage.includes('.m4a') || spostImage.includes('.m4b') || spostImage.includes('.m4p') || spostImage.includes('.mpc') || spostImage.includes('.wav') || spostImage.includes('.mp3')) {
                                    setMedia(
                                        <Audio audioRef={uid} audioSrc={spostImage} params={{ stopPropagation: true, marginLeft: 0 }} />
                                    )
                                }
                            });
                        }
                    }
                }
            });
        }

        // -- If there is a media (no link)
        if(JSON.parse(media).length !== 0) {
            var arrayMedia = JSON.parse(media).toString();
            const params = new URL(arrayMedia).searchParams;

            if(acceptedVideo.includes(params.get('ext'))) {
                setMedia(
                    <div className="mediaSpost">
                        <Video videoRef={uid} videoSrc={arrayMedia} params={{ stopPropagation: true, nsfw: nsfw }} />
                    </div>
                );
            }else if(acceptedImage.includes(params.get('ext'))) {
                setMedia(
                    <div className="mediaSpost" data-modal="seeMedia" onClick={(e) => { if(nsfw === false) { openModal(e); }}} {...(nsfw === true ? { style: { cursor: 'default' } }: {} )}>
                        <img src={arrayMedia} alt={arrayMedia} title={arrayMedia} {...(nsfw === true ? { style: { filter: 'blur(16px)', pointerEvents: "none" } }: {} )}
                            onError={({ currentTarget }) => {
                                if(theme === "darkTheme"){
                                    currentTarget.src = errorMediaDark;
                                }else{
                                    currentTarget.src = errorMediaLight;
                                }
                            }}
                            referrerPolicy="no-referrer"
                            data-modal="seeMedia"
                        />
                    </div>
                )
            }else if(acceptedAudio.includes(params.get('ext'))) {
                setMedia(
                    <Audio audioRef={uid} audioSrc={arrayMedia} params={{ stopPropagation: true, marginLeft: 0 }} />
                )
            }
        }
    }

    /* ----------------
     * ACTIONS FUNCTION
     * ----------------
     */
    const action = (e, uid, user, type) => {
        if (type === 'like') {
            if(logged === 1){
                const likeCount = document.querySelector('.like_'+uid+' span');
                let likes = likeCount.textContent;
                likes++;

                likeCount.innerHTML = likes;
                $('.like_'+uid).addClass('liked');
                $('.like_'+uid).removeClass('unlike');
                // Set dislike for click event
                setLikeEvent('dislike');

                /* --------------
                * LIKE ANIMATION
                * --------------
                */
                $.rnd = (m,n) => {
                    m = parseInt(m);
                    n = parseInt(n);
                    return Math.floor( Math.random() * (n - m + 1) ) + m;
                }
                $.each($('.like_'+uid), () => {
                    var confetticount = 8;
                    for(var i = 0; i <= confetticount; i++) {
                        $('.like_'+uid).append('<span className="particle c' + $.rnd(1,2) + '" style="position:absolute;margin-top:' + $.rnd(0,15) + 'px;margin-left:' + $.rnd(0,25) + 'px;width:' + $.rnd(4,6) + 'px; height:' + $.rnd(3,4) + 'px;animation-delay: ' + ($.rnd(0,30)/10) + 's;"></span>');
                    }
                });
                
                // Add like
                axios.post(
                    `https://api.snot.fr/v${API_VERSION}/${myInfos.uid}/actions/${uid}/add/${type}`,
                    { post_user: user },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        }
                    }
                ).then(response => {
                    $('.like_'+uid+' i').removeClass("fi-sr-heart");
                    $('.like_'+uid+' i').addClass("fi-ss-social-network");
                    $('.like_'+uid+' i').css({
                        transform: "scale(130%)",
                        marginTop: "-5px",
                        color: "#2E88BB",
                    });

                    setTimeout(() => {
                        $('.like_'+uid+' i').css({
                            transform: "scale(100%)",
                            marginTop: "0px",
                        });
                    }, 250);
                    setTimeout(() => {
                        $('.like_'+uid+' i').css({ color: "rgb(212, 87, 87)" });
                        $('.like_'+uid+' i').removeClass("fi-ss-social-network");
                        $('.like_'+uid+' i').addClass("fi-sr-heart");
                    }, 1000);
                    setTimeout(() => {
                        $.each($('.like_'+uid+' span'), () => {
                            $('.like_'+uid+' span').remove('.particle.c1');
                            $('.like_'+uid+' span').remove('.particle.c2');
                        });
                    }, 1750);
                });
            }else{
                openModal(e);
            }
        }else if (type === 'dislike') {
            if(logged === 1){
                const likeCount = document.querySelector('.like_'+uid+' span');
                let likes = likeCount.textContent;
                likes--;

                likeCount.innerHTML = likes;
                $('.like_'+uid).addClass('unlike');
                $('.like_'+uid).removeClass('liked');
                // Like icon
                $('.like_'+uid+' i').removeAttr('style');
                // Set like for click event
                setLikeEvent('like');

                // Remove like
                axios.delete(
                    `https://api.snot.fr/v${API_VERSION}/${myInfos.uid}/actions/${uid}/remove/like`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        },
                        data: {post_user: user}
                    }
                )
            }else{
                openModal(e);
            }
        }
    }

    /* -----------------
     * INTERACT FUNCTION
     * -----------------
     */
    const interact = (e, uid, type) => {
        if(logged === 1){
            if(type === "add"){
                const button = $('.friendBtn_profilePreviewMVB1');
                // UI
                if(cantMessage === true){
                    document.querySelector('.friendBtn_profilePreviewMVB1 i').className = "fi fi-rr-clock";
                    $('.friendBtn_profilePreviewMVB1 span').text(t('profile.buttons.pending'));
                    // UX
                    button.off('click').on('click', (e) => interact(e, uid, 'cancelRequest'));
                }else{
                    document.querySelector('.friendBtn_profilePreviewMVB1 i').className = "fi fi-rr-paper-plane";
                    $('.friendBtn_profilePreviewMVB1 span').text(t('profile.buttons.chat'));
                    // UX
                    button.off('click').on('click', () => navigate('/chat/'+generatedKey));
                }
                // API call
                axios({
                    method: 'POST',
                    url: `https://api.snot.fr/v${API_VERSION}/user/${uid}/friends/pending`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: myInfos.uid,
                        app: 'web',
                        key: generatedKey
                    }
                })
            }else if(type === "cancelRequest"){
                const button = $('.friendBtn_profilePreviewMVB1');
                // UI
                document.querySelector('.friendBtn_profilePreviewMVB1 i').className = "fi fi-rr-user-add";
                $('.friendBtn_profilePreviewMVB1 span').text(t('profile.buttons.addFriend'));
                // UX
                button.off('click').on('click', (e) => interact(e, uid, 'sub'));
                button.off('mouseenter').off('mouseleave');
                // API call
                axios({
                    method: 'DELETE',
                    url: `https://api.snot.fr/v${API_VERSION}/user/${uid}/friends/pending`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: myInfos.uid,
                        app: 'web'
                    }
                })
            }else if(type === "sub"){
                const button = $('.subscribeBtn_profilePreviewMVB1');
                // UX
                button.off('click').on('click', (e) => interact(e, uid, 'unsub'));
                button.off('mouseenter').off('mouseleave');
                // API call
                axios({
                    method: 'POST',
                    url: `https://api.snot.fr/v${API_VERSION}/user/${uid}/followers`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: myInfos.uid,
                        app: 'web'
                    }
                }).then(res => {
                    if(!res.data.request){
                        // UI
                        document.querySelector('.subscribeBtn_profilePreviewMVB1 i').className = "fi fi-rr-minus";
                        $('.subscribeBtn_profilePreviewMVB1 span').text("Ne plus suivre");
                    }else{
                        // UI
                        document.querySelector('.subscribeBtn_profilePreviewMVB1 i').className = "fi fi-rr-clock";
                        $('.subscribeBtn_profilePreviewMVB1 span').text("En attente");
                        button.off('mouseenter').on('mouseenter', () => document.querySelector('.subscribeBtn_profilePreviewMVB1 i').className = "fi fi-rr-cross");
                        button.off('mouseleave').on('mouseleave', () => document.querySelector('.subscribeBtn_profilePreviewMVB1 i').className = "fi fi-rr-clock");
                    }
                })
            }else if(type === "unsub"){
                const button = $('.subscribeBtn_profilePreviewMVB1');
                // UI
                document.querySelector('.subscribeBtn_profilePreviewMVB1 i').className = "fi fi-rr-plus";
                $('.subscribeBtn_profilePreviewMVB1 span').text("Suivre");
                // UX
                button.off('click').on('click', (e) => interact(e, uid, 'sub'));
                button.off('mouseenter').off('mouseleave');
                // API call
                axios({
                    method: 'DELETE',
                    url: `https://api.snot.fr/v${API_VERSION}/user/${uid}/followers`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: myInfos.uid,
                        app: 'web'
                    }
                })
            }
        }else{
            openModal(e);
        }
    }

    useEffect(() => {
        // Retreive [user] infos
        Home.retreiveCreatorInfos(sender).then((res) => {
            if(res !== "This user doesn't exist.") {
                setInfos({...res});

                // Set badges
                if (res.certified === "Yes" && res.staff === "Yes") {
                    setBadges({ certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
                }else{
                    if (res.staff === "Yes") {
                        setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
                    }
                    if (res.certified === "Yes") {
                        setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>});
                    }
                }

                // Retreive my user infos
                if(logged === 1){
                    generateKeyPM().then(resKey => setKey(resKey));

                    // Retreive settings
                    Settings.retreiveSettings(res.uid).then(setting => {
                        if(setting !== null && (setting[0].security_setting3 !== "Enabled")){
                            setCantMessage(true);
                        }else if(setting === null){
                            setCantMessage(true);
                        }
                    })

                    retreiveInfos().then(resMine => {
                        // Verify if [user] has blocked me
                        blockedUser(res.uid, resMine.uid).then(resBlock => {
                            if(resBlock !== 0){
                                setBlockedMe(true);
                            }else{
                                setBlockedMe(false);
                            }
                        });
                        // ...
                        setMyInfos(resMine);
                        // Check if [user] already liked or shared
                        // -- Like
                        verifyLikeShare(resMine.uid, 'like').then(r => {
                            if(r >= 1){
                                setLikeClass("liked");
                                setLikeEvent('dislike');
                            }
                        });
                        // -- Share
                        verifyLikeShare(resMine.uid, 'share').then(r => {
                            if(r >= 1) setShareClass("shared");      
                        })

                        // Verify he's friend or follow...
                        // -- Follows
                        Profile.verifyTypeUser('follows', resMine.uid, res.uid).then(verifyFollows => {
                            // -- Friends
                            Profile.verifyTypeUser('friends', resMine.uid, res.uid).then(verifyFriend => {
                                if(verifyFriend === null){
                                    Profile.verifyTypeUser('friendsrequest', resMine.uid, res.uid).then(verifyFriendReq => {   
                                        setVerified({
                                            friend: verifyFriendReq,
                                            follows: verifyFollows
                                        })
                                    })
                                }else{
                                    setVerified({
                                        friend: verifyFriend,
                                        follows: verifyFollows
                                    })
                                }
                            })
                        })
                    })
                }
            }

            // Get comment count
            Home.getCountComment(uid).then((count) => setCommentCount(count));
            // Set loading
            setLoading(false);
        })
        // For Media
        showMedia();
    }, [logged])

    useEffect(() => {
        if($('.seeProfileBtn_profilePreviewMVB1')) {
            $('.seeProfileBtn_profilePreviewMVB1').off('click').on('click', () => navigate('/@'+infos.usertag.replace('.', '_').toLowerCase()));
        }
        // IF IS NOT LOGGED
        if(logged === 0){
            if($('.friendBtn_profilePreviewMVB1')){
                $('.friendBtn_profilePreviewMVB1').off('click').on('click', (e) => openModal(e));
            }
            if($('.subscribeBtn_profilePreviewMVB1')){
                $('.subscribeBtn_profilePreviewMVB1').off('click').on('click', (e) => openModal(e));
            }
        }else{
            var friend = <></>;
            var follow = <></>;
            if(infos.uid !== myInfos.uid){
                // -- Add friend / Message (chat)
                if(verified.friend !== null){
                    if(verified.friendRequest){
                        if(cantMessage){
                            friend = <>
                            <div className={"friendBtn_profilePreviewMVB1 "+theme} role="button" tabIndex={1} data-modal="loginModal">
                                <span data-modal="loginModal">{t('profile.buttons.pending')}</span>
                                <i className="fi fi-rr-clock" data-modal="loginModal"></i>
                            </div>
                            </>;
                            // ...
                            $('.friendBtn_profilePreviewMVB1').off('click').on('click', (e) => interact(e, infos.uid, 'cancelRequest'));
                            $('.friendBtn_profilePreviewMVB1').off('mouseenter').on('mouseenter', () => document.querySelector('.friendBtn_profilePreviewMVB1 i').className = "fi fi-rr-cross");
                            $('.friendBtn_profilePreviewMVB1').off('mouseleave').on('mouseleave', () => document.querySelector('.friendBtn_profilePreviewMVB1 i').className = "fi fi-rr-clock");
                        }else{
                            friend = <>
                            <div className={"friendBtn_profilePreviewMVB1 "+theme} role="button" tabIndex={1} data-modal="loginModal">
                                <span data-modal="loginModal">{t('profile.buttons.chat')}</span>
                                <i className="fi fi-rr-paper-plane" data-modal="loginModal"></i>
                            </div>
                            </>;
                            // ...
                            $('.friendBtn_profilePreviewMVB1').off('click').on('click', () => navigate('/chat/'+verified.friend.keyPM));
                        }
                    }else{
                        friend = <>
                        <div className={"friendBtn_profilePreviewMVB1 "+theme} role="button" tabIndex={1} data-modal="loginModal">
                            <span data-modal="loginModal">{t('profile.buttons.chat')}</span>
                            <i className="fi fi-rr-paper-plane" data-modal="loginModal"></i>
                        </div>
                        </>;
                    // ...
                        $('.friendBtn_profilePreviewMVB1').off('click').on('click', () => navigate('/chat/'+verified.friend.keyPM));
                    }
                }else{
                    friend = <>
                    <div className={"friendBtn_profilePreviewMVB1 "+theme} role="button" tabIndex={1} data-modal="loginModal">
                        <span data-modal="loginModal">{t('profile.buttons.addFriend')}</span>
                        <i className="fi fi-rr-user-add" data-modal="loginModal"></i>
                    </div>
                    </>;
                    // ...
                    $('.friendBtn_profilePreviewMVB1').off('click').on('click', (e) => interact(e, infos.uid, 'add'));
                }
                // -- Follows
                if(verified.follows !== null){
                    if(verified.follows.request === "Yes"){
                        follow = <>
                        <div className={"subscribeBtn_profilePreviewMVB1 "+theme} tabIndex={2} role="button" data-modal="loginModal">
                            <span data-modal="loginModal">{t('profile.buttons.pending')}</span>
                            <i className="fi fi-rr-clock" data-modal="loginModal"></i>
                        </div>
                        </>;
                        // ...
                        $('.subscribeBtn_profilePreviewMVB1').off('mouseenter').on('mouseenter', () => document.querySelector('.subscribeBtn_profilePreviewMVB1 i').className = "fi fi-rr-cross");
                        $('.subscribeBtn_profilePreviewMVB1').off('mouseleave').on('mouseleave', () => document.querySelector('.subscribeBtn_profilePreviewMVB1 i').className = "fi fi-rr-clock");
                    }else{
                        follow = <>
                        <div className={"subscribeBtn_profilePreviewMVB1 "+theme} tabIndex={2} role="button" data-modal="loginModal">
                            <span data-modal="loginModal">{t('profile.buttons.stopFollow')}</span>
                            <i className="fi fi-rr-minus" data-modal="loginModal"></i>
                        </div>
                        </>;
                        // ...
                        $('.subscribeBtn_profilePreviewMVB1').off('mouseleave').off('mouseenter');
                    }
                    // ...
                    $('.subscribeBtn_profilePreviewMVB1').off('click').on('click', (e) => interact(e, infos.uid, 'unsub'));
                }else{
                    follow = <>
                    <div className={"subscribeBtn_profilePreviewMVB1 "+theme} tabIndex={2} role="button" data-modal="loginModal">
                        <span data-modal="loginModal">{t('profile.buttons.follow')}</span>
                        <i className="fi fi-rr-plus" data-modal="loginModal"></i>
                    </div>
                    </>;
                    // ...
                    $('.subscribeBtn_profilePreviewMVB1').off('click').on('click', (e) => interact(e, infos.uid, 'sub'));
                }
                setActionsButton({
                    friend: friend,
                    follows: follow
                })
            }else{
                setActionsButton({
                    follows: <></>,
                    add: <></>
                })
            }
        }
    }, [infos, myInfos, verified, logged])

    if(loading === true){
        return (
            <>
            <SpostLoading />
            </>
        )
    }else {
        return (
            <>
                {/* Profile Preview */}
                <aside id="profilePreview" className={"profilePreview menu "+theme} aria-hidden="true" aria-modal="false" data-user="false" style={{display:"none"}}>
                    <div className="menu-wrapper js-menu-stop">
                        <div className="profilePart_profilePreviewMVB1">
                            <img alt={"@"+infos.usertag.replace('.', '_').toLowerCase()} title={infos.username} src={infos.avatar} className="avatar_profilePpPreviewB1"
                                onError={(e) => {
                                    if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                        e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp'
                                    }
                                }}
                                style={loadingAvatar ? {display: "none"}: {}} referrerPolicy="no-referrer"
                            />
                            <p id="username" style={{maxWidth:135}}>{infos.username} {badges.certified}{badges.staff}</p>
                            <p id="usertag" style={{maxWidth:135}}>@{infos.usertag.replace('.', '_').toLowerCase()}</p>
                        </div>
                        <div className="aboutMe_profilePreviewMVB1">
                            <p id="about">{HtmlToReact.Parser().parse(infos.aboutme)}</p>
                        </div>
                        <div style={{margin: "10px 0 20px 10px",display: "flex"}}>
                            {actionsButton.follows}{actionsButton.friend}
                        </div>
                        <div className="seeProfileBtn_profilePreviewMVB1" role="button" tabIndex={3}>
                            <span>{t('home.general.preview.see')}</span>
                        </div>
                        <div className="dateAccCreated_profilePreviewMVB1">{t('home.general.preview.account')} {created}</div>
                    </div>
                </aside>
                <div className="topPart_mAppMVB1">
                    <h1 style={{ marginBottom: 0 }}>Spost - {title}</h1>
                </div>
                <div className={"tags_sMAMVeB1 "+theme}>
                    {HtmlToReact.Parser().parse(htmlTags)}
                </div>
                <div className={"spost_sMAMVeB1 "+theme} style={{ marginTop: 10 }}>
                    <div id="spostMain">
                        <div className={"topPart_sMainMVB1 "+theme}>
                            <div className="profilePart_sMainMVB1" id="profilePart" style={{flex: 1, flexDirection: "row", alignItems: "flex-start", zIndex: 1, marginTop: 0}} data-user={infos.uid}>
                                <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme } style={{ width: 50, height: 50 }} data-container="profilePreview">
                                    <img alt={"@"+infos.usertag.replace('.', '_').toLowerCase()} title={infos.username} src={infos.avatar} className="avatar_pPSMaMvB1" data-container="profilePreview"
                                        onError={(e) => {
                                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                                e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp'
                                            }
                                        }}
                                        style={loadingAvatar ? {display: "none"}: {}}
                                        onLoad={() => setLoadingAvatar(false)}
                                        onClick={(e) => openMenu(e)}
                                        referrerPolicy="no-referrer"
                                    />
                                </div>
                                <span id="username" data-container="profilePreview" onClick={(e) => openMenu(e)}>{infos.username}</span>
                                <span id="usertag" data-container="profilePreview" onClick={(e) => openMenu(e)}>@{infos.usertag.replace('.', '_').toLowerCase()} {badges.certified}{badges.staff}</span>
                            </div>
                            <div className="datePart_sMainMVB1">{date_format}</div>
                        </div>
                        <div className={"spostContent_sMainMVB1 "+theme}>
                            <span className="content">{HtmlToReact.Parser().parse(content)}</span>
                        </div>
                        {mediaHTML}
                    </div>
                </div>
                <div className={"countInteractions_sMAMVeB1 "+theme}>
                    <div id="like" className={"like_"+uid+" "+likeClass} onClick={(e) => action(e, uid, sender, likeEvent)} data-modal="loginModal">
                        <span data-modal="loginModal">{likes}</span>
                        <i className="fi fi-sr-heart" data-modal="loginModal" />
                    </div>
                    <div id="separator"></div>
                    <div id="comment" onClick={() => $('.addComBtn_sMAMVeB1').trigger('click')}>
                        <span>{commentCount}</span>
                        <i className="fi fi-sr-comment-alt" />
                    </div>
                    <div id="separator"></div>
                    <div id="share" className={"share_"+uid+" "+shareClass} onClick={(e) => openMenu(e)} data-container="shareBM">
                        <span data-container="shareBM">{shares}</span>
                        <i className="fi fi-sr-share" data-container="shareBM" />
                    </div>
                </div>
                <div className={"moreInteractions_sMAMVeB1 "+theme} data-container="moreBM" onClick={(e) => openMenu(e)}>
                    <div id="more" data-container="moreBM">
                        <i className="fi fi-sr-menu-dots-vertical" data-container="moreBM"></i>
                    </div>
                </div>
            </>
        )
    }
}

export const PostIntegrate = ({ data: {uid, sender, title, content, content_clear, media, tags, date, likes, shares}, theme, lang }) => {
    const { t } = useTranslation();

    // Set data
    const [infos, setInfos] = useState({
        username: '',
        usertag: '',
        avatar: '',
        uid: '',
        date_account: '',
        aboutme: ''
    });
    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>,
    });
    const [commentCount, setCommentCount] = useState(0);
    const [mediaHTML, setMedia] = useState(<></>);
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);
    const [loading, setLoading] = useState(true);

    // Set tags
    var nsfw = false;
    if(tags !== null) {
        var arrayTags = JSON.parse(tags);
        for(let i = 0; i < arrayTags.length; i++){
            // NSFW Terms (set NSFW)
            if(nsfwTerms.includes(arrayTags[i].toLowerCase())) nsfw = true;
        }
    }

    // Set date format
    const sendDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const match_date = new Date(date);
    match_date.setHours(0, 0, 0, 0);

    const diff = Math.floor((today - match_date) / (1000 * 60 * 60 * 24));
    let diffDays = parseInt(diff);
        
    var date_format = "";
    // Get time since
    var langYear = "y";
    var langDay = "d";
    if(i18n.resolvedLanguage === "fr"){
        langYear = "a";
        langDay = "j"
    }

    if(i18n.resolvedLanguage === "fr"){
        switch (diffDays) {
            default:
                date_format = "il y a " + timeSince(sendDate, langYear, langDay);
        } 
    }else {
        switch (diffDays) {
            default:
                date_format = timeSince(sendDate, langYear, langDay) + " ago";
        }
    }
    
    // Set likes and shares count
    // -- For likes
    if (likes >= 1000 && likes < 10000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'K';
    } else if (likes >= 10000 && likes < 100000) {
        likes = likes.toString().substring(0, 2)+','+likes.toString().substring(2, 3)+'K';
    } else if (likes >= 100000 && likes < 1000000) {
        likes = likes.toString().substring(0, 3) + 'K';
    } else if (likes >= 1000000 && likes < 10000000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'M';
    } else if (likes >= 10000000 && likes < 100000000) {
        likes = likes.toString().substring(0, 2)+','+likes.toString().substring(2, 3)+'M';
    } else if (likes >= 100000000 && likes < 1000000000) {
        likes = likes.toString().substring(0, 3) + 'M';
    } else if (likes >= 1000000000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'B';
    }
    // -- For shares
    if (shares >= 1000 && shares < 10000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'K';
    } else if (shares >= 10000 && shares < 100000) {
        shares = shares.toString().substring(0, 2)+','+shares.toString().substring(2, 3)+'K';
    } else if (shares >= 100000 && shares < 1000000) {
        shares = shares.toString().substring(0, 3) + 'K';
    } else if (shares >= 1000000 && shares < 10000000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'M';
    } else if (shares >= 10000000 && shares < 100000000) {
        shares = shares.toString().substring(0, 2)+','+shares.toString().substring(2, 3)+'M';
    } else if (shares >= 100000000 && shares < 1000000000) {
        shares = shares.toString().substring(0, 3) + 'M';
    } else if (shares >= 1000000000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'B';
    }

    /* -----------
     * BUILD MEDIA
     * -----------
     */
    const showMedia = () => {
        // -- If there's a link to a media
        const fileRgx = /\.[0-9a-z]+?=[?#]|\.(?:[\w]+)($|\?)/mi;
        const patterns = /https?:\/\/?[\da-z\.-]+\.[a-z\.]{2,6}[\w\S]*\/?/mis;
        let m1 = fileRgx.exec(content_clear);
        let m2 = patterns.exec(content_clear);

        if ((m1 = fileRgx.exec(content_clear)) !== null) {
            m1.forEach((match) => {
                if(match !== '' && match !== '?'){
                    let fileExt = match.replace('?', '');
                    if (fileExt === '.png' || fileExt === '.jpg' || fileExt === '.jpeg' || fileExt === '.gif' || fileExt === '.bmp' || fileExt === '.tif' || fileExt === '.tiff' || fileExt === '.webp' || fileExt === '.mp4' || fileExt === '.mov' || fileExt === '.avi' || fileExt === '.wmf' || fileExt === '.flv' || fileExt === '.webm' || fileExt === '.mkv' || fileExt === '.ogg' || fileExt === '.vob' || fileExt === '.3gp' || fileExt === '.aa' || fileExt === '.aax' || fileExt === '.aac' || fileExt === '.aiff' || fileExt === '.alac' || fileExt === '.m4a' || fileExt === '.m4b' || fileExt === '.m4p' || fileExt === '.mp3' || fileExt === '.mpc' || fileExt === '.wav') {
                        if ((m2 = patterns.exec(content_clear)) !== null) {
                            m2.forEach((matchImage) => {
                                let spostImage = matchImage;
                                
                                if (spostImage.includes('.mp4') || spostImage.includes('.mov') || spostImage.includes('.avi') || spostImage.includes('.wmf') || spostImage.includes('flv') || spostImage.includes('.webm') || spostImage.includes('.mkv') || spostImage.includes('.ogg') || spostImage.includes('.vob')) {
                                    setMedia(
                                        <div className="mediaSpost" style={{cursor: "default" }}>
                                            <Video videoRef={uid} videoSrc={spostImage} params={{ stopPropagation: true, nsfw: nsfw }} />
                                        </div>
                                    );
                                }else if (spostImage.includes('.png') || spostImage.includes('.jpg') || spostImage.includes('.jpeg') || spostImage.includes('.gif') || spostImage.includes('.bmp') || spostImage.includes('.tif') || spostImage.includes('.tiff') || spostImage.includes('.webp')) {
                                    setMedia(
                                        <div className="mediaSpost" onClick={openModal}>
                                            <img src={spostImage} alt={spostImage} title={spostImage} {...(nsfw === true ? { style: { filter: 'blur(16px)', pointerEvents: "none" } }: {} )}
                                                onError={({ currentTarget }) => {
                                                    if(theme === "darkTheme"){
                                                        currentTarget.src = errorMediaDark;
                                                    }else{
                                                        currentTarget.src = errorMediaLight;
                                                    }
                                                }}
                                                referrerPolicy="no-referrer"
                                                data-modal="seeMedia"
                                            />
                                        </div>
                                    );
                                }else if(spostImage.includes('.3gp') || spostImage.includes('.aa') || spostImage.includes('.aax') || spostImage.includes('.aac') || spostImage.includes('.aiff') || spostImage.includes('.alac') || spostImage.includes('.m4a') || spostImage.includes('.m4b') || spostImage.includes('.m4p') || spostImage.includes('.mpc') || spostImage.includes('.wav') || spostImage.includes('.mp3')) {
                                    setMedia(
                                        <Audio audioRef={uid} audioSrc={spostImage} params={{ stopPropagation: true, marginLeft: 0 }} />
                                    )
                                }
                            });
                        }
                    }
                }
            });
        }

        // -- If there is a media (no link)
        if(JSON.parse(media).length !== 0) {
            var arrayMedia = JSON.parse(media).toString();
            const params = new URL(arrayMedia).searchParams;

            if(acceptedVideo.includes(params.get('ext'))) {
                setMedia(
                    <div className="mediaSpost">
                        <Video videoRef={uid} videoSrc={arrayMedia} params={{ stopPropagation: true, nsfw: nsfw }} />
                    </div>
                );
            }else if(acceptedImage.includes(params.get('ext'))) {
                setMedia(
                    <div className="mediaSpost">
                        <img src={arrayMedia} alt={arrayMedia} title={arrayMedia} {...(nsfw === true ? { style: { filter: 'blur(16px)', pointerEvents: "none" } }: {} )}
                            onError={({ currentTarget }) => {
                                if(theme === "darkTheme"){
                                    currentTarget.src = errorMediaDark;
                                }else{
                                    currentTarget.src = errorMediaLight;
                                }
                            }}
                            referrerPolicy="no-referrer"
                        />
                    </div>
                )
            }else if(acceptedAudio.includes(params.get('ext'))) {
                setMedia(
                    <Audio audioRef={uid} audioSrc={arrayMedia} params={{ stopPropagation: true, marginLeft: 0 }} />
                )
            }
        }
    }

    useEffect(() => {
        // Retreive [user] infos
        Home.retreiveCreatorInfos(sender).then((res) => {
            if(res !== "This user doesn't exist.") {
                setInfos({...res});

                // Set badges
                if (res.certified === "Yes" && res.staff === "Yes") {
                    setBadges({ certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
                }else{
                    if (res.staff === "Yes") {
                        setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
                    }
                    if (res.certified === "Yes") {
                        setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>});
                    }
                }
            }

            // Get comment count
            Home.getCountComment(uid).then((count) => setCommentCount(count));
        })
        // For Media
        showMedia();
        // Set loading
        setLoading(false);
    }, [])

    if(loading === true){
        return (
            <></>
        )
    }else {
        return (
            <>
                <div id="mainSpost_sUB1Integrate" className={theme}>
                    <div id="spostInfo_IntsUAaDAvB1" className={theme}>
                        <a href={"https://snot.fr/@"+infos.usertag.toLowerCase().replace('.', '_')} target="_blank">
                            <div className={loadingAvatar ? "avatarUser_IntsIAB1 skeleton "+theme: "avatarUser_IntsIAB1 "+theme }>
                                <img alt={"@"+infos.usertag.replace('.', '_').toLowerCase()} title={infos.username} src={infos.avatar}
                                    onError={(e) => {
                                        if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                            e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp'
                                        }
                                    }}
                                    style={loadingAvatar ? {display: "none"}: {}}
                                    onLoad={() => setLoadingAvatar(false)}
                                    referrerPolicy="no-referrer"
                                />
                            </div>
                        </a>
                        <div id="userNaDpub_IntsIAB1">
                            <span className="username">{infos.username} {badges.certified}{badges.staff}</span><br/>
                            <span className="date">{date_format}</span>
                        </div>
                        <div id="logoSNOT_IntsIAB1">
                            <img alt="SNOT" title="SNOT" {...(theme === "darkTheme" ? { src: "https://api.snot.fr/v"+API_VERSION+"/content/logo_vbanner?ext=png&folder=logo" }: { src: "https://api.snot.fr/v"+API_VERSION+"/content/logo_vbanner_black?ext=png&folder=logo" })} />
                        </div>
                    </div>
                    <div id="spostContent_IntsUCB1" className={theme}>
                        <p>{content}</p>
                    </div>
                    {mediaHTML}
                    <div id="spostCount_IntsUCoB1" className={theme}>
                        <div className="like">
                            <span><span className="text">{likes}</span><i className="fi fi-sr-heart"></i></span>
                        </div>
                        <div className="comment">
                            <span><span className="text">{commentCount}</span><i className="fi fi-sr-comment-alt"></i></span>
                        </div>
                        <div className="share">
                            <span><span className="text">{shares}</span><i className="fi fi-sr-share"></i></span>
                        </div>
                    </div>
                    <div id="linkTSpost_IntsUCB1" className={theme}>
                        <a href={"https://snot.fr/spost/"+uid} target="_blank">
                            <p>
                                {
                                    lang === "fr" ?
                                    "Voir ce post"
                                    : "See this post"
                                }
                            </p>
                        </a>
                    </div>
                </div>
            </>
        )
    }
}

export const Post = ({ data: {uid, sender: { uuid, username, usertag, avatar, certified, staff }, content, content_clear, media, tags, date, likes, shares}, theme, logged, profile=false }) => {
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(document.location.search);

    // Set data
    const [commentCount, setCommentCount] = useState(0);
    const [mediaHTML, setMedia] = useState(<></>);
    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>,
    });
    const [myInfos, setMyInfos] = useState([]);
    // Set like & share actions
    const [likeEvent, setLikeEvent] = useState('like');
    const [likeClass, setLikeClass] = useState('unlike');
    const [shareClass, setShareClass] = useState("unshare");
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);
    const [loading, setLoading] = useState(true);

    // Set NSFW
    var nsfw = false;
    if(tags !== undefined || tags !== null) {
        var arrayTags = JSON.parse(tags);
        for(let i = 0; i < arrayTags.length; i++){
            if(nsfwTerms.includes(arrayTags[i].toLowerCase())) { nsfw = true; };
        }
    }

    // Set date format
    const sendDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const match_date = new Date(date);
    match_date.setHours(0, 0, 0, 0);

    const diff = Math.floor((today - match_date) / (1000 * 60 * 60 * 24));
    let diffDays = parseInt(diff);
        
    var date_format = "";
    // Get time since
    var langYear = "y";
    var langDay = "d";
    if(i18n.resolvedLanguage === "fr"){
        langYear = "a";
        langDay = "j"
    }

    if(i18n.resolvedLanguage === "fr"){
        switch (diffDays) {
            default:
                date_format = "il y a " + timeSince(sendDate, langYear, langDay);
        } 
    }else {
        switch (diffDays) {
            default:
                date_format = timeSince(sendDate, langYear, langDay) + " ago";
        }
    }

    // Set likes and shares count
    // -- For likes
    if (likes >= 1000 && likes < 10000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'K';
    } else if (likes >= 10000 && likes < 100000) {
        likes = likes.toString().substring(0, 2)+','+likes.toString().substring(2, 3)+'K';
    } else if (likes >= 100000 && likes < 1000000) {
        likes = likes.toString().substring(0, 3) + 'K';
    } else if (likes >= 1000000 && likes < 10000000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'M';
    } else if (likes >= 10000000 && likes < 100000000) {
        likes = likes.toString().substring(0, 2)+','+likes.toString().substring(2, 3)+'M';
    } else if (likes >= 100000000 && likes < 1000000000) {
        likes = likes.toString().substring(0, 3) + 'M';
    } else if (likes >= 1000000000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'B';
    }
    // -- For shares
    if (shares >= 1000 && shares < 10000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'K';
    } else if (shares >= 10000 && shares < 100000) {
        shares = shares.toString().substring(0, 2)+','+shares.toString().substring(2, 3)+'K';
    } else if (shares >= 100000 && shares < 1000000) {
        shares = shares.toString().substring(0, 3) + 'K';
    } else if (shares >= 1000000 && shares < 10000000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'M';
    } else if (shares >= 10000000 && shares < 100000000) {
        shares = shares.toString().substring(0, 2)+','+shares.toString().substring(2, 3)+'M';
    } else if (shares >= 100000000 && shares < 1000000000) {
        shares = shares.toString().substring(0, 3) + 'M';
    } else if (shares >= 1000000000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'B';
    }

    // Know if [user] already liked or shared [post]
    const verifyLikeShare = (user, type) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/${user}/actions/${uid}/verify/${type}`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data.count);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    /* -----------
     * BUILD MEDIA
     * -----------
     */
    const showMedia = () => {
        // -- If there's a link to a media
        const fileRgx = /\.[0-9a-z]+?=[?#]|\.(?:[\w]+)($|\?)/mi;
        const patterns = /https?:\/\/?[\da-z\.-]+\.[a-z\.]{2,6}[\w\S]*\/?/mis;
        let m1 = fileRgx.exec(content_clear);
        let m2 = patterns.exec(content_clear);

        if ((m1 = fileRgx.exec(content_clear)) !== null) {
            m1.forEach((match) => {
                if(match !== '' && match !== '?'){
                    let fileExt = match.replace('?', '');
                    if (fileExt === '.png' || fileExt === '.jpg' || fileExt === '.jpeg' || fileExt === '.gif' || fileExt === '.bmp' || fileExt === '.tif' || fileExt === '.tiff' || fileExt === '.webp' || fileExt === '.mp4' || fileExt === '.mov' || fileExt === '.avi' || fileExt === '.wmf' || fileExt === '.flv' || fileExt === '.webm' || fileExt === '.mkv' || fileExt === '.ogg' || fileExt === '.vob' || fileExt === '.3gp' || fileExt === '.aa' || fileExt === '.aax' || fileExt === '.aac' || fileExt === '.aiff' || fileExt === '.alac' || fileExt === '.m4a' || fileExt === '.m4b' || fileExt === '.m4p' || fileExt === '.mp3' || fileExt === '.mpc' || fileExt === '.wav') {
                        if ((m2 = patterns.exec(content_clear)) !== null) {
                            m2.forEach((matchImage) => {
                                let spostImage = matchImage;
                                
                                if (spostImage.includes('.mp4') || spostImage.includes('.mov') || spostImage.includes('.avi') || spostImage.includes('.wmf') || spostImage.includes('.flv') || spostImage.includes('.webm') || spostImage.includes('.mkv') || spostImage.includes('.ogg') || spostImage.includes('.vob')) {
                                    setMedia(
                                        <div className="mediaSpost" style={{ cursor: "default", marginLeft: 45 }}>
                                            <Video videoRef={uid} videoSrc={spostImage} params={{ stopPropagation: true, nsfw: nsfw }} />
                                        </div>
                                    );
                                }else if (spostImage.includes('.png') || spostImage.includes('.jpg') || spostImage.includes('.jpeg') || spostImage.includes('.gif') || spostImage.includes('.bmp') || spostImage.includes('.tif') || spostImage.includes('.tiff') || spostImage.includes('.webp')) {
                                    setMedia(
                                        <div className="mediaSpost" {...(nsfw === true ? { style: { cursor: 'default', marginLeft: 45 } }: {marginLeft: 45} )}>
                                            <img src={arrayMedia} alt={arrayMedia} title={arrayMedia} {...(nsfw === true ? { style: { filter: 'blur(16px)', pointerEvents: "none" } }: {} )}
                                                onError={({ currentTarget }) => {
                                                    if(theme === "darkTheme"){
                                                        currentTarget.src = errorMediaDark;
                                                    }else{
                                                        currentTarget.src = errorMediaLight;
                                                    }
                                                }}
                                                referrerPolicy="no-referrer"
                                            />
                                        </div>
                                    );
                                }else if(spostImage.includes('.3gp') || spostImage.includes('.aa') || spostImage.includes('.aax') || spostImage.includes('.aac') || spostImage.includes('.aiff') || spostImage.includes('.alac') || spostImage.includes('.m4a') || spostImage.includes('.m4b') || spostImage.includes('.m4p') || spostImage.includes('.mpc') || spostImage.includes('.wav') || spostImage.includes('.mp3')) {
                                    setMedia(
                                        <Audio audioRef={uid} audioSrc={spostImage} params={{ stopPropagation: true, marginLeft: 45 }} />
                                    )
                                }
                            });
                        }
                    }
                }
            });
        }

        // -- If there is a media (no link)
        if(JSON.parse(media).length !== 0) {
            var arrayMedia = JSON.parse(media).toString();
            const params = new URL(arrayMedia).searchParams;

            if(acceptedVideo.includes(params.get('ext'))) {
                setMedia(
                    <div className="mediaSpost" style={{ marginLeft: 45 }}>
                        <Video videoRef={uid} videoSrc={arrayMedia} params={{ stopPropagation: true, nsfw: nsfw }} />
                    </div>
                );
            }else if(acceptedImage.includes(params.get('ext'))) {
                setMedia(
                    <div className="mediaSpost" {...(nsfw === true ? { style: { cursor: 'default', marginLeft: 45 } }: { style: {marginLeft: 45} } )}>
                        <img src={arrayMedia} alt={arrayMedia} title={arrayMedia} {...(nsfw === true ? { style: { filter: 'blur(16px)', pointerEvents: "none" } }: {} )}
                            onError={({ currentTarget }) => {
                                if(theme === "darkTheme"){
                                    currentTarget.src = errorMediaDark;
                                }else{
                                    currentTarget.src = errorMediaLight;
                                }
                            }}
                            referrerPolicy="no-referrer"
                        />
                    </div>
                )
            }else if(acceptedAudio.includes(params.get('ext'))) {
                setMedia(
                    <Audio audioRef={uid} audioSrc={arrayMedia} params={{ stopPropagation: true, marginLeft: 45 }} />
                )
            }
        }
    }

    /* ----------------
     * ACTIONS FUNCTION
     * ----------------
     */
    const action = (e, uid, user, type) => {
        if (type === 'like') {
            if(logged === 1){
                const likeCount = document.querySelector('.like_'+uid+' span');
                let likes = likeCount.textContent;
                likes++;

                likeCount.innerHTML = likes;
                $('.like_'+uid).addClass('liked');
                $('.like_'+uid).removeClass('unlike');
                // Set dislike for click event
                setLikeEvent('dislike');

                /* --------------
                * LIKE ANIMATION
                * --------------
                */
                $.rnd = (m,n) => {
                    m = parseInt(m);
                    n = parseInt(n);
                    return Math.floor( Math.random() * (n - m + 1) ) + m;
                }
                $.each($('.like_'+uid), () => {
                    var confetticount = 8;
                    for(var i = 0; i <= confetticount; i++) {
                        $('.like_'+uid).append('<span className="particle c' + $.rnd(1,2) + '" style="position:absolute;margin-top:' + $.rnd(0,15) + 'px;margin-left:' + $.rnd(0,25) + 'px;width:' + $.rnd(4,6) + 'px; height:' + $.rnd(3,4) + 'px;animation-delay: ' + ($.rnd(0,30)/10) + 's;"></span>');
                    }
                });
                
                // Add like
                axios.post(
                    `https://api.snot.fr/v${API_VERSION}/${myInfos.uid}/actions/${uid}/add/${type}`,
                    { post_user: user },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        }
                    }
                ).then(response => {
                    $('.like_'+uid+' i').removeClass("fi-sr-heart");
                    $('.like_'+uid+' i').addClass("fi-ss-social-network");
                    $('.like_'+uid+' i').css({
                        transform: "scale(130%)",
                        marginTop: "-5px",
                        color: "#2E88BB",
                    });

                    setTimeout(() => {
                        $('.like_'+uid+' i').css({
                            transform: "scale(100%)",
                            marginTop: "0px",
                        });
                    }, 250);
                    setTimeout(() => {
                        $('.like_'+uid+' i').css({ color: "rgb(212, 87, 87)" });
                        $('.like_'+uid+' i').removeClass("fi-ss-social-network");
                        $('.like_'+uid+' i').addClass("fi-sr-heart");
                    }, 1000);
                    setTimeout(() => {
                        $.each($('.like_'+uid+' span'), () => {
                            $('.like_'+uid+' span').remove('.particle.c1');
                            $('.like_'+uid+' span').remove('.particle.c2');
                        });
                    }, 1750);
                })
            }else{
                openModal(e);
            }
        }else if (type === 'dislike') {
            if(logged === 1){
                const likeCount = document.querySelector('.like_'+uid+' span');
                let likes = likeCount.textContent;
                likes--;

                likeCount.innerHTML = likes;
                $('.like_'+uid).addClass('unlike');
                $('.like_'+uid).removeClass('liked');
                // Like icon
                $('.like_'+uid+' i').removeAttr('style');
                // Set like for click event
                setLikeEvent('like');

                // Remove like
                axios.delete(
                    `https://api.snot.fr/v${API_VERSION}/${myInfos.uid}/actions/${uid}/remove/like`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        },
                        data: {post_user: user}
                    }
                )
            }else{
                openModal(e);
            }
        }
    }

    /* ----------
     * SET BADGES
     * ----------
     */
    useEffect(() => {
        // Set badges
        if (certified === "Yes" && staff === "Yes") {
            setBadges({ certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
        }else{
            if (staff === "Yes") {
                setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
            }
            if (certified === "Yes") {
                setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>});
            }
        }
        // Get comment count
        Home.getCountComment(uid).then((count) => setCommentCount(count));
        // For Media
        showMedia();

        // Retreive my user infos
        if(logged === 1){
            retreiveInfos().then(res => {
                setMyInfos(res);
                // Check if [user] already liked or shared
                // -- Like
                verifyLikeShare(res.uid, 'like').then(r => {
                    if(r >= 1){
                        setLikeClass("liked");
                        setLikeEvent('dislike');
                    }
                });
                // -- Share
                verifyLikeShare(res.uid, 'share').then(r => {
                    if(r >= 1){ setShareClass("shared") };      
                })
                // Set loading
                setLoading(false);
            })
        }else{
            // Set loading
            setLoading(false);
        }

        // Permit to click on a link
        $(".spostContent_spostMAppMVB1 .content a").on('click', function(e) {
            e.stopPropagation();
        });
    }, [])

    if(loading === true){
        return (
            <>
                <PostLoading />
                <PostLoading />
                <PostLoadingWithMedia />
            </>
        )
    }else {
        if(certified === "Yes" && searchParams.get('filters') === "certified") {
            return (
                <>
                    <div className={"spost_mAppMVeB1 "+theme}>
                        <div onClick={() => navigate('/spost/'+uid)} style={{cursor: "pointer"}}>
                            <div className="profilePart_spostMAppMVB1" style={{flex: 1, flexDirection: "row", alignItems: "flex-start", zIndex: 1, marginTop: 0}}>
                                <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                                    <img alt={"@"+usertag.replace('.', '_').toLowerCase()} title={username} src={avatar} className="avatar_pPSMaMvB1"
                                        onError={(e) => {
                                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                                e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp'
                                            }
                                        }}
                                    style={loadingAvatar ? {display: "none"}: {}} onLoad={() => setLoadingAvatar(false)} referrerPolicy="no-referrer" />
                                </div>
                                <div className={"user "+theme} style={{width: "auto", height: "auto"}}>
                                    <span id="username">{username}</span>
                                </div>
                                <span id="usertag" style={{display: "flex"}}>@{usertag.replace('.', '_').toLowerCase()} {badges.certified}{badges.staff}</span>
                                {
                                    nsfw === true ?
                                    <div className="nsfwTag_comPcVB1">
                                        <i className="fi fi-rr-age-restriction-eighteen"></i>
                                        <span>NSFW</span>
                                    </div>
                                    : ''
                                }
                            </div>
                            <div className="datePart_spostMAppMVB1" style={{ display: "block" }}>
                                <span>{date_format}</span>
                            </div>
                            <div className="spostContent_spostMAppMVB1" style={{ marginTop: 0, marginLeft: 50 }}>
                                <span className="content">{HtmlToReact.Parser().parse(content)}</span>
                            </div>
                            {mediaHTML}
                        </div>
                        <div className={"countInteractions_spostMAppMVB1 "+theme}>
                            <div id="like" className={"like_"+uid+" "+likeClass} onClick={(e) => action(e, uid, uuid, likeEvent)} data-modal="loginModal">
                                <span data-modal="loginModal">{likes}</span>
                                <i className="fi fi-sr-heart" data-modal="loginModal" />
                            </div>
                            <div id="separator"></div>
                            <div id="comment" onClick={() => navigate('/spost/'+uid+'?add=comment')}>
                                <span>{commentCount}</span>
                                <i className="fi fi-sr-comment-alt" />
                            </div>
                            <div id="separator"></div>
                            <div id="share" className={"share_"+uid+" "+shareClass} onClick={() => navigate('/spost/'+uid)}>
                                <span>{shares}</span>
                                <i className="fi fi-sr-share" />
                            </div>
                        </div>
                    </div>
                </>
            )
        }else if (searchParams.get('filters') !== "certified"){
            return (
                <>
                    <div className={"spost_mAppMVeB1 "+theme}>
                        <div onClick={() => navigate('/spost/'+uid)} style={{cursor: "pointer"}}>
                            <div className="profilePart_spostMAppMVB1" style={{flex: 1, flexDirection: "row", alignItems: "flex-start", zIndex: 1, marginTop: 0}}>
                                <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                                    <img alt={"@"+usertag.replace('.', '_').toLowerCase()} title={username} src={avatar} className="avatar_pPSMaMvB1"
                                        onError={(e) => {
                                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                                e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp'
                                            }
                                        }}
                                    style={loadingAvatar ? {display: "none"}: {}} onLoad={() => setLoadingAvatar(false)} referrerPolicy="no-referrer" />
                                </div>
                                <div className={"user "+theme} style={{width: "auto", height: "auto"}}>
                                    <span id="username">{username}</span>
                                </div>
                                <span id="usertag" style={{display: "flex"}}>@{usertag.replace('.', '_').toLowerCase()} {badges.certified}{badges.staff}</span>
                                {
                                    nsfw === true ?
                                    <div className="nsfwTag_comPcVB1">
                                        <i className="fi fi-rr-age-restriction-eighteen"></i>
                                        <span>NSFW</span>
                                    </div>
                                    : ''
                                }
                            </div>
                            <div className="datePart_spostMAppMVB1" style={{ display: "block" }}>
                                <span>{date_format}</span>
                            </div>
                            <div className="spostContent_spostMAppMVB1" style={{ marginTop: 0, marginLeft: 50 }}>
                                <span className="content">
                                    {HtmlToReact.Parser().parse(content)}
                                </span>
                            </div>
                            {mediaHTML}
                        </div>
                        <div className={"countInteractions_spostMAppMVB1 "+theme}>
                            <div id="like" className={"like_"+uid+" "+likeClass} onClick={(e) => action(e, uid, uuid, likeEvent)} data-modal="loginModal">
                                <span data-modal="loginModal">{likes}</span>
                                <i className="fi fi-sr-heart" data-modal="loginModal" />
                            </div>
                            <div id="separator"></div>
                            <div id="comment" onClick={() => navigate('/spost/'+uid+'?add=comment')}>
                                <span>{commentCount}</span>
                                <i className="fi fi-sr-comment-alt" />
                            </div>
                            <div id="separator"></div>
                            <div id="share" className={"share_"+uid+" "+shareClass} onClick={() => navigate('/spost/'+uid)}>
                                <span>{shares}</span>
                                <i className="fi fi-sr-share" />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
}

export const PostPopular = ({ data: {uid, sender: { uuid, username, usertag, avatar, certified, staff }, content, content_clear, media, tags, date, likes, shares}, filters: {filter_certified}, theme, logged }) => {
    const navigate = useNavigate();

    // Set data
    const [commentCount, setCommentCount] = useState(0);
    const [mediaHTML, setMedia] = useState(<></>);
    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>,
    });
    const [myInfos, setMyInfos] = useState([]);
    // Set like & share actions
    const [likeEvent, setLikeEvent] = useState('like');
    const [likeClass, setLikeClass] = useState('unlike');
    const [shareClass, setShareClass] = useState("unshare");
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);
    const [loading, setLoading] = useState(true);

    // Set NSFW
    var nsfw = false;
    if(tags !== null) {
        var arrayTags = JSON.parse(tags);
        for(let i = 0; i < arrayTags.length; i++){
            if(nsfwTerms.includes(arrayTags[i].toLowerCase())) { nsfw = true; };
        }
    }

    // Set date format
    const sendDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const match_date = new Date(date);
    match_date.setHours(0, 0, 0, 0);

    const diff = Math.floor((today - match_date) / (1000 * 60 * 60 * 24));
    let diffDays = parseInt(diff);
        
    var date_format = "";
    // Get time since
    var langYear = "y";
    var langDay = "d";
    if(i18n.resolvedLanguage === "fr"){
        langYear = "a";
        langDay = "j"
    }

    if(i18n.resolvedLanguage === "fr"){
        switch (diffDays) {
            default:
                date_format = "il y a " + timeSince(sendDate, langYear, langDay);
        } 
    }else {
        switch (diffDays) {
            default:
                date_format = timeSince(sendDate, langYear, langDay) + " ago";
        }
    }

    // Set likes and shares count
    // -- For likes
    if (likes >= 1000 && likes < 10000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'K';
    } else if (likes >= 10000 && likes < 100000) {
        likes = likes.toString().substring(0, 2)+','+likes.toString().substring(2, 3)+'K';
    } else if (likes >= 100000 && likes < 1000000) {
        likes = likes.toString().substring(0, 3) + 'K';
    } else if (likes >= 1000000 && likes < 10000000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'M';
    } else if (likes >= 10000000 && likes < 100000000) {
        likes = likes.toString().substring(0, 2)+','+likes.toString().substring(2, 3)+'M';
    } else if (likes >= 100000000 && likes < 1000000000) {
        likes = likes.toString().substring(0, 3) + 'M';
    } else if (likes >= 1000000000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'B';
    }
    // -- For shares
    if (shares >= 1000 && shares < 10000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'K';
    } else if (shares >= 10000 && shares < 100000) {
        shares = shares.toString().substring(0, 2)+','+shares.toString().substring(2, 3)+'K';
    } else if (shares >= 100000 && shares < 1000000) {
        shares = shares.toString().substring(0, 3) + 'K';
    } else if (shares >= 1000000 && shares < 10000000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'M';
    } else if (shares >= 10000000 && shares < 100000000) {
        shares = shares.toString().substring(0, 2)+','+shares.toString().substring(2, 3)+'M';
    } else if (shares >= 100000000 && shares < 1000000000) {
        shares = shares.toString().substring(0, 3) + 'M';
    } else if (shares >= 1000000000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'B';
    }

    // Know if [user] already liked or shared [post]
    const verifyLikeShare = (user, type) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/${user}/actions/${uid}/verify/${type}`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data.count);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    /* -----------
     * BUILD MEDIA
     * -----------
     */
    const showMedia = () => {
        // -- If there's a link to a media
        const fileRgx = /\.[0-9a-z]+?=[?#]|\.(?:[\w]+)($|\?)/mi;
        const patterns = /https?:\/\/?[\da-z\.-]+\.[a-z\.]{2,6}[\w\S]*\/?/mis;
        let m1 = fileRgx.exec(content_clear);
        let m2 = patterns.exec(content_clear);

        if ((m1 = fileRgx.exec(content_clear)) !== null) {
            m1.forEach((match) => {
                if(match !== '' && match !== '?'){
                    let fileExt = match.replace('?', '');
                    if (fileExt === '.png' || fileExt === '.jpg' || fileExt === '.jpeg' || fileExt === '.gif' || fileExt === '.bmp' || fileExt === '.tif' || fileExt === '.tiff' || fileExt === '.webp' || fileExt === '.mp4' || fileExt === '.mov' || fileExt === '.avi' || fileExt === '.wmf' || fileExt === '.flv' || fileExt === '.webm' || fileExt === '.mkv' || fileExt === '.ogg' || fileExt === '.vob' || fileExt === '.3gp' || fileExt === '.aa' || fileExt === '.aax' || fileExt === '.aac' || fileExt === '.aiff' || fileExt === '.alac' || fileExt === '.m4a' || fileExt === '.m4b' || fileExt === '.m4p' || fileExt === '.mp3' || fileExt === '.mpc' || fileExt === '.wav') {
                        if ((m2 = patterns.exec(content_clear)) !== null) {
                            m2.forEach((matchImage) => {
                                let spostImage = matchImage;
                                
                                if (spostImage.includes('.mp4') || spostImage.includes('.mov') || spostImage.includes('.avi') || spostImage.includes('.wmf') || spostImage.includes('.flv') || spostImage.includes('.webm') || spostImage.includes('.mkv') || spostImage.includes('.ogg') || spostImage.includes('.vob')) {
                                    setMedia(
                                        <div className="mediaSpost" style={{ cursor: "default", marginLeft: 45 }}>
                                            <Video videoRef={uid} videoSrc={spostImage} params={{ stopPropagation: true, nsfw: nsfw }} />
                                        </div>
                                    );
                                }else if (spostImage.includes('.png') || spostImage.includes('.jpg') || spostImage.includes('.jpeg') || spostImage.includes('.gif') || spostImage.includes('.bmp') || spostImage.includes('.tif') || spostImage.includes('.tiff') || spostImage.includes('.webp')) {
                                    setMedia(
                                        <div className="mediaSpost" {...(nsfw === true ? { style: { cursor: 'default', marginLeft: 45 } }: {marginLeft: 45} )}>
                                            <img src={arrayMedia} alt={arrayMedia} title={arrayMedia} {...(nsfw === true ? { style: { filter: 'blur(16px)', pointerEvents: "none" } }: {} )}
                                                onError={({ currentTarget }) => {
                                                    if(theme === "darkTheme"){
                                                        currentTarget.src = errorMediaDark;
                                                    }else{
                                                        currentTarget.src = errorMediaLight;
                                                    }
                                                }}
                                                referrerPolicy="no-referrer"
                                            />
                                        </div>
                                    );
                                }else if(spostImage.includes('.3gp') || spostImage.includes('.aa') || spostImage.includes('.aax') || spostImage.includes('.aac') || spostImage.includes('.aiff') || spostImage.includes('.alac') || spostImage.includes('.m4a') || spostImage.includes('.m4b') || spostImage.includes('.m4p') || spostImage.includes('.mpc') || spostImage.includes('.wav') || spostImage.includes('.mp3')) {
                                    setMedia(
                                        <Audio audioRef={uid} audioSrc={spostImage} params={{ stopPropagation: true, marginLeft: 45 }} />
                                    )
                                }
                            });
                        }
                    }
                }
            });
        }

        // -- If there is a media (no link)
        if(JSON.parse(media).length !== 0) {
            var arrayMedia = JSON.parse(media).toString();
            const params = new URL(arrayMedia).searchParams;

            if(acceptedVideo.includes(params.get('ext'))) {
                setMedia(
                    <div className="mediaSpost" style={{ marginLeft: 45 }}>
                        <Video videoRef={uid} videoSrc={arrayMedia} params={{ stopPropagation: true, nsfw: nsfw }} />
                    </div>
                );
            }else if(acceptedImage.includes(params.get('ext'))) {
                setMedia(
                    <div className="mediaSpost" {...(nsfw === true ? { style: { cursor: 'default', marginLeft: 45 } }: { style: {marginLeft: 45} } )}>
                        <img src={arrayMedia} alt={arrayMedia} title={arrayMedia} {...(nsfw === true ? { style: { filter: 'blur(16px)', pointerEvents: "none" } }: {} )}
                            onError={({ currentTarget }) => {
                                if(theme === "darkTheme"){
                                    currentTarget.src = errorMediaDark;
                                }else{
                                    currentTarget.src = errorMediaLight;
                                }
                            }}
                            referrerPolicy="no-referrer"
                        />
                    </div>
                )
            }else if(acceptedAudio.includes(params.get('ext'))) {
                setMedia(
                    <Audio audioRef={uid} audioSrc={arrayMedia} params={{ stopPropagation: true, marginLeft: 45 }} />
                )
            }
        }
    }

    /* ----------------
     * ACTIONS FUNCTION
     * ----------------
     */
    const action = (e, uid, user, type) => {
        if (type === 'like') {
            if(logged === 1){
                const likeCount = document.querySelector('.like_'+uid+' span');
                let likes = likeCount.textContent;
                likes++;

                likeCount.innerHTML = likes;
                $('.like_'+uid).addClass('liked');
                $('.like_'+uid).removeClass('unlike');
                // Set dislike for click event
                setLikeEvent('dislike');

                /* --------------
                * LIKE ANIMATION
                * --------------
                */
                $.rnd = (m,n) => {
                    m = parseInt(m);
                    n = parseInt(n);
                    return Math.floor( Math.random() * (n - m + 1) ) + m;
                }
                $.each($('.like_'+uid), () => {
                    var confetticount = 8;
                    for(var i = 0; i <= confetticount; i++) {
                        $('.like_'+uid).append('<span className="particle c' + $.rnd(1,2) + '" style="position:absolute;margin-top:' + $.rnd(0,15) + 'px;margin-left:' + $.rnd(0,25) + 'px;width:' + $.rnd(4,6) + 'px; height:' + $.rnd(3,4) + 'px;animation-delay: ' + ($.rnd(0,30)/10) + 's;"></span>');
                    }
                });
                
                // Add like
                axios.post(
                    `https://api.snot.fr/v${API_VERSION}/${myInfos.uid}/actions/${uid}/add/${type}`,
                    { post_user: user },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        }
                    }
                ).then(response => {
                    $('.like_'+uid+' i').removeClass("fi-sr-heart");
                    $('.like_'+uid+' i').addClass("fi-ss-social-network");
                    $('.like_'+uid+' i').css({
                        transform: "scale(130%)",
                        marginTop: "-5px",
                        color: "#2E88BB",
                    });

                    setTimeout(() => {
                        $('.like_'+uid+' i').css({
                            transform: "scale(100%)",
                            marginTop: "0px",
                        });
                    }, 250);
                    setTimeout(() => {
                        $('.like_'+uid+' i').css({ color: "rgb(212, 87, 87)" });
                        $('.like_'+uid+' i').removeClass("fi-ss-social-network");
                        $('.like_'+uid+' i').addClass("fi-sr-heart");
                    }, 1000);
                    setTimeout(() => {
                        $.each($('.like_'+uid+' span'), () => {
                            $('.like_'+uid+' span').remove('.particle.c1');
                            $('.like_'+uid+' span').remove('.particle.c2');
                        });
                    }, 1750);
                })
            }else{
                openModal(e);
            }
        }else if (type === 'dislike') {
            if(logged === 1){
                const likeCount = document.querySelector('.like_'+uid+' span');
                let likes = likeCount.textContent;
                likes--;

                likeCount.innerHTML = likes;
                $('.like_'+uid).addClass('unlike');
                $('.like_'+uid).removeClass('liked');
                // Like icon
                $('.like_'+uid+' i').removeAttr('style');
                // Set like for click event
                setLikeEvent('like');

                // Remove like
                axios.delete(
                    `https://api.snot.fr/v${API_VERSION}/${myInfos.uid}/actions/${uid}/remove/like`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        },
                        data: {post_user: user}
                    }
                )
            }else{
                openModal(e);
            }
        }
    }

    /* ----------
     * SET BADGES
     * ----------
     */
    useEffect(() => {
        // Set badges
        if (certified === "Yes" && staff === "Yes") {
            setBadges({ certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
        }else{
            if (staff === "Yes") {
                setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
            }
            if (certified === "Yes") {
                setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>});
            }
        }

        // Get comment count
        Home.getCountComment(uid).then((count) => setCommentCount(count));
        // For Media
        showMedia();

        // Retreive my user infos
        if(logged === 1){
            retreiveInfos().then(res => {
                setMyInfos(res);
                // Check if [user] already liked or shared
                // -- Like
                verifyLikeShare(res.uid, 'like').then(r => {
                    if(r >= 1){
                        setLikeClass("liked");
                        setLikeEvent('dislike');
                    }
                });
                // -- Share
                verifyLikeShare(res.uid, 'share').then(r => {
                    if(r >= 1){ setShareClass("shared") };      
                })
                // Set loading
                setLoading(false);
            })
        }else{
            // Set loading
            setLoading(false);
        }

        // Permit to click on a link
        $(".spostContent_spostMAppMVB1 .content a").on('click', function(e) {
            e.stopPropagation();
        });
    }, [])

    if(loading === true){
        return (
            <>
                <PostLoading />
                <PostLoading />
                <PostLoadingWithMedia />
            </>
        )
    }else {
        if(certified === "Yes" && filter_certified === "on") {
            return (
                <>
                    <div className={"spost_mAppMVeB1 "+theme}>
                        <div onClick={() => navigate('/spost/'+uid)} style={{cursor: "pointer"}}>
                            <div className="profilePart_spostMAppMVB1" style={{flex: 1, flexDirection: "row", alignItems: "flex-start", zIndex: 1, marginTop: 0}}>
                                <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                                    <img alt={"@"+usertag.replace('.', '_').toLowerCase()} title={username} src={avatar} className="avatar_pPSMaMvB1"
                                        onError={(e) => {
                                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                                e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp'
                                            }
                                        }}
                                    style={loadingAvatar ? {display: "none"}: {}} onLoad={() => setLoadingAvatar(false)} referrerPolicy="no-referrer" />
                                </div>
                                <div className={"user "+theme} style={{width: "auto", height: "auto"}}>
                                    <span id="username">{username}</span>
                                </div>
                                <span id="usertag" style={{display: "flex"}}>@{usertag.replace('.', '_').toLowerCase()} {badges.certified}{badges.staff}</span>
                                {
                                    nsfw === true ?
                                    <div className="nsfwTag_comPcVB1">
                                        <i className="fi fi-rr-age-restriction-eighteen"></i>
                                        <span>NSFW</span>
                                    </div>
                                    : ''
                                }
                            </div>
                            <div className="datePart_spostMAppMVB1" style={{ display: "block" }}>
                                <span>{date_format}</span>
                            </div>
                            <div className="spostContent_spostMAppMVB1" style={{ marginTop: 0, marginLeft: 50 }}>
                                <span className="content">{HtmlToReact.Parser().parse(content)}</span>
                            </div>
                            {mediaHTML}
                        </div>
                        <div className={"countInteractions_spostMAppMVB1 "+theme}>
                            <div id="like" className={"like_"+uid+" "+likeClass} onClick={(e) => action(e, uid, uuid, likeEvent)} data-modal="loginModal">
                                <span data-modal="loginModal">{likes}</span>
                                <i className="fi fi-sr-heart" data-modal="loginModal" />
                            </div>
                            <div id="separator"></div>
                            <div id="comment" onClick={() => navigate('/spost/'+uid+'?add=comment')}>
                                <span>{commentCount}</span>
                                <i className="fi fi-sr-comment-alt" />
                            </div>
                            <div id="separator"></div>
                            <div id="share" className={"share_"+uid+" "+shareClass} onClick={() => navigate('/spost/'+uid)}>
                                <span>{shares}</span>
                                <i className="fi fi-sr-share" />
                            </div>
                        </div>
                    </div>
                </>
            )
        }else if (filter_certified !== "on"){
            return (
                <>
                    <div className={"spost_mAppMVeB1 "+theme}>
                        <div onClick={() => navigate('/spost/'+uid)} style={{cursor: "pointer"}}>
                            <div className="profilePart_spostMAppMVB1" style={{flex: 1, flexDirection: "row", alignItems: "flex-start", zIndex: 1, marginTop: 0}}>
                                <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                                    <img alt={"@"+usertag.replace('.', '_').toLowerCase()} title={username} src={avatar} className="avatar_pPSMaMvB1"
                                        onError={(e) => {
                                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                                e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp'
                                            }
                                        }}
                                    style={loadingAvatar ? {display: "none"}: {}} onLoad={() => setLoadingAvatar(false)} referrerPolicy="no-referrer" />
                                </div>
                                <div className={"user "+theme} style={{width: "auto", height: "auto"}}>
                                    <span id="username">{username}</span>
                                </div>
                                <span id="usertag" style={{display: "flex"}}>@{usertag.replace('.', '_').toLowerCase()} {badges.certified}{badges.staff}</span>
                                {
                                    nsfw === true ?
                                    <div className="nsfwTag_comPcVB1">
                                        <i className="fi fi-rr-age-restriction-eighteen"></i>
                                        <span>NSFW</span>
                                    </div>
                                    : ''
                                }
                            </div>
                            <div className="datePart_spostMAppMVB1" style={{ display: "block" }}>
                                <span>{date_format}</span>
                            </div>
                            <div className="spostContent_spostMAppMVB1" style={{ marginTop: 0, marginLeft: 50 }}>
                                <span className="content">{HtmlToReact.Parser().parse(content)}</span>
                            </div>
                            {mediaHTML}
                        </div>
                        <div className={"countInteractions_spostMAppMVB1 "+theme}>
                            <div id="like" className={"like_"+uid+" "+likeClass} onClick={(e) => action(e, uid, uuid, likeEvent)} data-modal="loginModal">
                                <span data-modal="loginModal">{likes}</span>
                                <i className="fi fi-sr-heart" data-modal="loginModal" />
                            </div>
                            <div id="separator"></div>
                            <div id="comment" onClick={() => navigate('/spost/'+uid+'?add=comment')}>
                                <span>{commentCount}</span>
                                <i className="fi fi-sr-comment-alt" />
                            </div>
                            <div id="separator"></div>
                            <div id="share" className={"share_"+uid+" "+shareClass} onClick={() => navigate('/spost/'+uid)}>
                                <span>{shares}</span>
                                <i className="fi fi-sr-share" />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
}

export const PostSub = ({ data: {uid, sender, content, content_clear, media, tags, date, likes, shares}, theme, logged }) => {
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(document.location.search);

    // Set data
    const [infos, setInfos] = useState({
        username: '',
        usertag: '',
        avatar: '',
        certified: '',
        staff: ''
    })
    const [commentCount, setCommentCount] = useState(0);
    const [mediaHTML, setMedia] = useState(<></>);
    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>,
    });
    const [myInfos, setMyInfos] = useState([]);
    // Set like & share actions
    const [likeEvent, setLikeEvent] = useState('like');
    const [likeClass, setLikeClass] = useState('unlike');
    const [shareClass, setShareClass] = useState("unshare");
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);
    const [loading, setLoading] = useState(true);

    // Set NSFW
    var nsfw = false;
    if(tags !== null) {
        var arrayTags = JSON.parse(tags);
        for(let i = 0; i < arrayTags.length; i++){
            if(nsfwTerms.includes(arrayTags[i].toLowerCase())) { nsfw = true; };
        }
    }

    // Set date format
    const sendDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const match_date = new Date(date);
    match_date.setHours(0, 0, 0, 0);

    const diff = Math.floor((today - match_date) / (1000 * 60 * 60 * 24));
    let diffDays = parseInt(diff);
        
    var date_format = "";
    // Get time since
    var langYear = "y";
    var langDay = "d";
    if(i18n.resolvedLanguage === "fr"){
        langYear = "a";
        langDay = "j"
    }

    if(i18n.resolvedLanguage === "fr"){
        switch (diffDays) {
            default:
                date_format = "il y a " + timeSince(sendDate, langYear, langDay);
        } 
    }else {
        switch (diffDays) {
            default:
                date_format = timeSince(sendDate, langYear, langDay) + " ago";
        }
    }

    // Set likes and shares count
    // -- For likes
    if (likes >= 1000 && likes < 10000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'K';
    } else if (likes >= 10000 && likes < 100000) {
        likes = likes.toString().substring(0, 2)+','+likes.toString().substring(2, 3)+'K';
    } else if (likes >= 100000 && likes < 1000000) {
        likes = likes.toString().substring(0, 3) + 'K';
    } else if (likes >= 1000000 && likes < 10000000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'M';
    } else if (likes >= 10000000 && likes < 100000000) {
        likes = likes.toString().substring(0, 2)+','+likes.toString().substring(2, 3)+'M';
    } else if (likes >= 100000000 && likes < 1000000000) {
        likes = likes.toString().substring(0, 3) + 'M';
    } else if (likes >= 1000000000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'B';
    }
    // -- For shares
    if (shares >= 1000 && shares < 10000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'K';
    } else if (shares >= 10000 && shares < 100000) {
        shares = shares.toString().substring(0, 2)+','+shares.toString().substring(2, 3)+'K';
    } else if (shares >= 100000 && shares < 1000000) {
        shares = shares.toString().substring(0, 3) + 'K';
    } else if (shares >= 1000000 && shares < 10000000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'M';
    } else if (shares >= 10000000 && shares < 100000000) {
        shares = shares.toString().substring(0, 2)+','+shares.toString().substring(2, 3)+'M';
    } else if (shares >= 100000000 && shares < 1000000000) {
        shares = shares.toString().substring(0, 3) + 'M';
    } else if (shares >= 1000000000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'B';
    }

    // Know if [user] already liked or shared [post]
    const verifyLikeShare = (user, type) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/${user}/actions/${uid}/verify/${type}`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data.count);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    /* -----------
     * BUILD MEDIA
     * -----------
     */
    const showMedia = () => {
        // -- If there's a link to a media
        const fileRgx = /\.[0-9a-z]+?=[?#]|\.(?:[\w]+)($|\?)/mi;
        const patterns = /https?:\/\/?[\da-z\.-]+\.[a-z\.]{2,6}[\w\S]*\/?/mis;
        let m1 = fileRgx.exec(content_clear);
        let m2 = patterns.exec(content_clear);

        if ((m1 = fileRgx.exec(content_clear)) !== null) {
            m1.forEach((match) => {
                if(match !== '' && match !== '?'){
                    let fileExt = match.replace('?', '');
                    if (fileExt === '.png' || fileExt === '.jpg' || fileExt === '.jpeg' || fileExt === '.gif' || fileExt === '.bmp' || fileExt === '.tif' || fileExt === '.tiff' || fileExt === '.webp' || fileExt === '.mp4' || fileExt === '.mov' || fileExt === '.avi' || fileExt === '.wmf' || fileExt === '.flv' || fileExt === '.webm' || fileExt === '.mkv' || fileExt === '.ogg' || fileExt === '.vob' || fileExt === '.3gp' || fileExt === '.aa' || fileExt === '.aax' || fileExt === '.aac' || fileExt === '.aiff' || fileExt === '.alac' || fileExt === '.m4a' || fileExt === '.m4b' || fileExt === '.m4p' || fileExt === '.mp3' || fileExt === '.mpc' || fileExt === '.wav') {
                        if ((m2 = patterns.exec(content_clear)) !== null) {
                            m2.forEach((matchImage) => {
                                let spostImage = matchImage;
                                
                                if (spostImage.includes('.mp4') || spostImage.includes('.mov') || spostImage.includes('.avi') || spostImage.includes('.wmf') || spostImage.includes('.flv') || spostImage.includes('.webm') || spostImage.includes('.mkv') || spostImage.includes('.ogg') || spostImage.includes('.vob')) {
                                    setMedia(
                                        <div className="mediaSpost" style={{ cursor: "default", marginLeft: 45 }}>
                                            <Video videoRef={uid} videoSrc={spostImage} params={{ stopPropagation: true, nsfw: nsfw }} />
                                        </div>
                                    );
                                }else if (spostImage.includes('.png') || spostImage.includes('.jpg') || spostImage.includes('.jpeg') || spostImage.includes('.gif') || spostImage.includes('.bmp') || spostImage.includes('.tif') || spostImage.includes('.tiff') || spostImage.includes('.webp')) {
                                    setMedia(
                                        <div className="mediaSpost" {...(nsfw === true ? { style: { cursor: 'default', marginLeft: 45 } }: {marginLeft: 45} )}>
                                            <img src={arrayMedia} alt={arrayMedia} title={arrayMedia} {...(nsfw === true ? { style: { filter: 'blur(16px)', pointerEvents: "none" } }: {} )}
                                                onError={({ currentTarget }) => {
                                                    if(theme === "darkTheme"){
                                                        currentTarget.src = errorMediaDark;
                                                    }else{
                                                        currentTarget.src = errorMediaLight;
                                                    }
                                                }}
                                                referrerPolicy="no-referrer"
                                               
                                            />
                                        </div>
                                    );
                                }else if(spostImage.includes('.3gp') || spostImage.includes('.aa') || spostImage.includes('.aax') || spostImage.includes('.aac') || spostImage.includes('.aiff') || spostImage.includes('.alac') || spostImage.includes('.m4a') || spostImage.includes('.m4b') || spostImage.includes('.m4p') || spostImage.includes('.mpc') || spostImage.includes('.wav') || spostImage.includes('.mp3')) {
                                    setMedia(
                                        <Audio audioRef={uid} audioSrc={spostImage} params={{ stopPropagation: true, marginLeft: 45 }} />
                                    )
                                }
                            });
                        }
                    }
                }
            });
        }

        // -- If there is a media (no link)
        if(JSON.parse(media).length !== 0) {
            var arrayMedia = JSON.parse(media).toString();
            const params = new URL(arrayMedia).searchParams;

            if(acceptedVideo.includes(params.get('ext'))) {
                setMedia(
                    <div className="mediaSpost" style={{ marginLeft: 45 }}>
                        <Video videoRef={uid} videoSrc={arrayMedia} params={{ stopPropagation: true, nsfw: nsfw }} />
                    </div>
                );
            }else if(acceptedImage.includes(params.get('ext'))) {
                setMedia(
                    <div className="mediaSpost" {...(nsfw === true ? { style: { cursor: 'default', marginLeft: 45 } }: { style: {marginLeft: 45} } )}>
                        <img src={arrayMedia} alt={arrayMedia} title={arrayMedia} {...(nsfw === true ? { style: { filter: 'blur(16px)', pointerEvents: "none" } }: {} )}
                            onError={({ currentTarget }) => {
                                if(theme === "darkTheme"){
                                    currentTarget.src = errorMediaDark;
                                }else{
                                    currentTarget.src = errorMediaLight;
                                }
                            }}
                            referrerPolicy="no-referrer"
                           
                        />
                    </div>
                )
            }else if(acceptedAudio.includes(params.get('ext'))) {
                setMedia(
                    <Audio audioRef={uid} audioSrc={arrayMedia} params={{ stopPropagation: true, marginLeft: 45 }} />
                )
            }
        }
    }

    /* ----------------
     * ACTIONS FUNCTION
     * ----------------
     */
    const action = (e, uid, user, type) => {
        if (type === 'like') {
            if(logged === 1){
                const likeCount = document.querySelector('.like_'+uid+' span');
                let likes = likeCount.textContent;
                likes++;

                likeCount.innerHTML = likes;
                $('.like_'+uid).addClass('liked');
                $('.like_'+uid).removeClass('unlike');
                // Set dislike for click event
                setLikeEvent('dislike');

                /* --------------
                * LIKE ANIMATION
                * --------------
                */
                $.rnd = (m,n) => {
                    m = parseInt(m);
                    n = parseInt(n);
                    return Math.floor( Math.random() * (n - m + 1) ) + m;
                }
                $.each($('.like_'+uid), () => {
                    var confetticount = 8;
                    for(var i = 0; i <= confetticount; i++) {
                        $('.like_'+uid).append('<span className="particle c' + $.rnd(1,2) + '" style="position:absolute;margin-top:' + $.rnd(0,15) + 'px;margin-left:' + $.rnd(0,25) + 'px;width:' + $.rnd(4,6) + 'px; height:' + $.rnd(3,4) + 'px;animation-delay: ' + ($.rnd(0,30)/10) + 's;"></span>');
                    }
                });
                
                // Add like
                axios.post(
                    `https://api.snot.fr/v${API_VERSION}/${myInfos.uid}/actions/${uid}/add/${type}`,
                    { post_user: user },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        }
                    }
                ).then(response => {
                    $('.like_'+uid+' i').removeClass("fi-sr-heart");
                    $('.like_'+uid+' i').addClass("fi-ss-social-network");
                    $('.like_'+uid+' i').css({
                        transform: "scale(130%)",
                        marginTop: "-5px",
                        color: "#2E88BB",
                    });

                    setTimeout(() => {
                        $('.like_'+uid+' i').css({
                            transform: "scale(100%)",
                            marginTop: "0px",
                        });
                    }, 250);
                    setTimeout(() => {
                        $('.like_'+uid+' i').css({ color: "rgb(212, 87, 87)" });
                        $('.like_'+uid+' i').removeClass("fi-ss-social-network");
                        $('.like_'+uid+' i').addClass("fi-sr-heart");
                    }, 1000);
                    setTimeout(() => {
                        $.each($('.like_'+uid+' span'), () => {
                            $('.like_'+uid+' span').remove('.particle.c1');
                            $('.like_'+uid+' span').remove('.particle.c2');
                        });
                    }, 1750);
                })
            }else{
                openModal(e);
            }
        }else if (type === 'dislike') {
            if(logged === 1){
                const likeCount = document.querySelector('.like_'+uid+' span');
                let likes = likeCount.textContent;
                likes--;

                likeCount.innerHTML = likes;
                $('.like_'+uid).addClass('unlike');
                $('.like_'+uid).removeClass('liked');
                // Like icon
                $('.like_'+uid+' i').removeAttr('style');
                // Set like for click event
                setLikeEvent('like');

                // Remove like
                axios.delete(
                    `https://api.snot.fr/v${API_VERSION}/${myInfos.uid}/actions/${uid}/remove/like`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        },
                        data: {post_user: user}
                    }
                )
            }
        }
    }

    /* ----------
     * SET BADGES
     * ----------
     */
    useEffect(() => {
        Home.retreiveCreatorInfos(sender).then(res => {
            if(res.infos !== "This user doesn't exist."){
                setInfos({...res});

                // Set badges
                if (res.certified === "Yes" && res.staff === "Yes") {
                    setBadges({ certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
                }else{
                    if (res.staff === "Yes") {
                        setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
                    }
                    if (res.certified === "Yes") {
                        setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>});
                    }
                }

                // Get comment count
                Home.getCountComment(uid).then((count) => setCommentCount(count));
                // Set loading
                setLoading(false);
            }
        });
        // For Media
        showMedia();

        // Retreive my user infos
        if(logged === 1){
            retreiveInfos().then(res => {
                setMyInfos(res);
                // Check if [user] already liked or shared
                // -- Like
                verifyLikeShare(res.uid, 'like').then(r => {
                    if(r >= 1){
                        setLikeClass("liked");
                        setLikeEvent('dislike');
                    }
                });
                // -- Share
                verifyLikeShare(res.uid, 'share').then(r => {
                    if(r >= 1){ setShareClass("shared") };      
                })
            })
        }

        // Permit to click on a link
        $(".spostContent_spostMAppMVB1 .content a").on('click', function(e) {
            e.stopPropagation();
        });
    }, [])

    if(loading === true){
        return (
            <>
                <PostLoading />
                <PostLoading />
                <PostLoadingWithMedia />
            </>
        )
    }else {
        if(infos.certified === "Yes" && searchParams.get('filters') === "certified") {
            return (
                <>
                    <div className={"spost_mAppMVeB1 "+theme}>
                        <div onClick={() => navigate('/spost/'+uid)} style={{cursor: "pointer"}}>
                            <div className="profilePart_spostMAppMVB1" style={{flex: 1, flexDirection: "row", alignItems: "flex-start", zIndex: 1, marginTop: 0}}>
                                <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                                    <img alt={"@"+infos.usertag.replace('.', '_').toLowerCase()} title={infos.username} src={infos.avatar} className="avatar_pPSMaMvB1"
                                        onError={(e) => {
                                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                                e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp'
                                            }
                                        }}
                                    style={loadingAvatar ? {display: "none"}: {}} onLoad={() => setLoadingAvatar(false)} referrerPolicy="no-referrer" />
                                </div>
                                <div className={"user "+theme} style={{width: "auto", height: "auto"}}>
                                    <span id="username">{infos.username}</span>
                                </div>
                                <span id="usertag" style={{display: "flex"}}>@{infos.usertag.replace('.', '_').toLowerCase()} {badges.certified}{badges.staff}</span>
                                {
                                    nsfw === true ?
                                    <div className="nsfwTag_comPcVB1">
                                        <i className="fi fi-rr-age-restriction-eighteen"></i>
                                        <span>NSFW</span>
                                    </div>
                                    : ''
                                }
                            </div>
                            <div className="datePart_spostMAppMVB1" style={{ display: "block" }}>
                                <span>{date_format}</span>
                            </div>
                            <div className="spostContent_spostMAppMVB1" style={{ marginTop: 0, marginLeft: 50 }}>
                                <span className="content">{HtmlToReact.Parser().parse(content)}</span>
                            </div>
                            {mediaHTML}
                        </div>
                        <div className={"countInteractions_spostMAppMVB1 "+theme}>
                            <div id="like" className={"like_"+uid+" "+likeClass} onClick={(e) => action(e, uid, sender, likeEvent)} data-modal="loginModal">
                                <span data-modal="loginModal">{likes}</span>
                                <i className="fi fi-sr-heart" data-modal="loginModal" />
                            </div>
                            <div id="separator"></div>
                            <div id="comment" onClick={() => navigate('/spost/'+uid+'?add=comment')}>
                                <span>{commentCount}</span>
                                <i className="fi fi-sr-comment-alt" />
                            </div>
                            <div id="separator"></div>
                            <div id="share" className={"share_"+uid+" "+shareClass} onClick={() => navigate('/spost/'+uid)}>
                                <span>{shares}</span>
                                <i className="fi fi-sr-share" />
                            </div>
                        </div>
                    </div>
                </>
            )
        }else if (searchParams.get('filters') !== "certified"){
            return (
                <>
                    <div className={"spost_mAppMVeB1 "+theme}>
                        <div onClick={() => navigate('/spost/'+uid)} style={{cursor: "pointer"}}>
                            <div className="profilePart_spostMAppMVB1" style={{flex: 1, flexDirection: "row", alignItems: "flex-start", zIndex: 1, marginTop: 0}}>
                                <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                                    <img alt={"@"+infos.usertag.replace('.', '_').toLowerCase()} title={infos.username} src={infos.avatar} className="avatar_pPSMaMvB1"
                                        onError={(e) => {
                                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                                e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp'
                                            }
                                        }}
                                    style={loadingAvatar ? {display: "none"}: {}} onLoad={() => setLoadingAvatar(false)} referrerPolicy="no-referrer" />
                                </div>
                                <div className={"user "+theme} style={{width: "auto", height: "auto"}}>
                                    <span id="username">{infos.username}</span>
                                </div>
                                <span id="usertag" style={{display: "flex"}}>@{infos.usertag.replace('.', '_').toLowerCase()} {badges.certified}{badges.staff}</span>
                                {
                                    nsfw === true ?
                                    <div className="nsfwTag_comPcVB1">
                                        <i className="fi fi-rr-age-restriction-eighteen"></i>
                                        <span>NSFW</span>
                                    </div>
                                    : ''
                                }
                            </div>
                            <div className="datePart_spostMAppMVB1" style={{ display: "block" }}>
                                <span>{date_format}</span>
                            </div>
                            <div className="spostContent_spostMAppMVB1" style={{ marginTop: 0, marginLeft: 50 }}>
                                <span className="content">{HtmlToReact.Parser().parse(content)}</span>
                            </div>
                            {mediaHTML}
                        </div>
                        <div className={"countInteractions_spostMAppMVB1 "+theme}>
                            <div id="like" className={"like_"+uid+" "+likeClass} onClick={(e) => action(e, uid, sender, likeEvent)} data-modal="loginModal">
                                <span data-modal="loginModal">{likes}</span>
                                <i className="fi fi-sr-heart" data-modal="loginModal" />
                            </div>
                            <div id="separator"></div>
                            <div id="comment" onClick={() => navigate('/spost/'+uid+'?add=comment')}>
                                <span>{commentCount}</span>
                                <i className="fi fi-sr-comment-alt" />
                            </div>
                            <div id="separator"></div>
                            <div id="share" className={"share_"+uid+" "+shareClass} onClick={() => navigate('/spost/'+uid)}>
                                <span>{shares}</span>
                                <i className="fi fi-sr-share" />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
}

export const PostLiked = ({ data: {uid, user, sender, content, content_clear, media, tags, date, likes, shares}, theme, logged }) => {
    const navigate = useNavigate();

    // Set data
    const [infos, setInfos] = useState({
        username: '',
        usertag: '',
        avatar: '',
        certified: '',
        staff: ''
    })
    const [commentCount, setCommentCount] = useState(0);
    const [mediaHTML, setMedia] = useState(<></>);
    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>,
    });
    const [myInfos, setMyInfos] = useState([]);
    // Set like & share actions
    const [likeClass, setLikeClass] = useState('liked');
    const [likeEvent, setLikeEvent] = useState('dislike');
    const [shareClass, setShareClass] = useState("unshare");
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);
    const [loading, setLoading] = useState(true);

    // Set NSFW
    var nsfw = false;
    if(tags !== null) {
        var arrayTags = JSON.parse(tags);
        for(let i = 0; i < arrayTags.length; i++){
            if(nsfwTerms.includes(arrayTags[i].toLowerCase())) { nsfw = true; };
        }
    }

    // Set date format
    const sendDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const match_date = new Date(date);
    match_date.setHours(0, 0, 0, 0);

    const diff = Math.floor((today - match_date) / (1000 * 60 * 60 * 24));
    let diffDays = parseInt(diff);
        
    var date_format = "";
    // Get time since
    var langYear = "y";
    var langDay = "d";
    if(i18n.resolvedLanguage === "fr"){
        langYear = "a";
        langDay = "j"
    }

    if(i18n.resolvedLanguage === "fr"){
        switch (diffDays) {
            default:
                date_format = "il y a " + timeSince(sendDate, langYear, langDay);
        } 
    }else {
        switch (diffDays) {
            default:
                date_format = timeSince(sendDate, langYear, langDay) + " ago";
        }
    }

    // Set likes and shares count
    // -- For likes
    if (likes >= 1000 && likes < 10000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'K';
    } else if (likes >= 10000 && likes < 100000) {
        likes = likes.toString().substring(0, 2)+','+likes.toString().substring(2, 3)+'K';
    } else if (likes >= 100000 && likes < 1000000) {
        likes = likes.toString().substring(0, 3) + 'K';
    } else if (likes >= 1000000 && likes < 10000000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'M';
    } else if (likes >= 10000000 && likes < 100000000) {
        likes = likes.toString().substring(0, 2)+','+likes.toString().substring(2, 3)+'M';
    } else if (likes >= 100000000 && likes < 1000000000) {
        likes = likes.toString().substring(0, 3) + 'M';
    } else if (likes >= 1000000000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'B';
    }
    // -- For shares
    if (shares >= 1000 && shares < 10000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'K';
    } else if (shares >= 10000 && shares < 100000) {
        shares = shares.toString().substring(0, 2)+','+shares.toString().substring(2, 3)+'K';
    } else if (shares >= 100000 && shares < 1000000) {
        shares = shares.toString().substring(0, 3) + 'K';
    } else if (shares >= 1000000 && shares < 10000000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'M';
    } else if (shares >= 10000000 && shares < 100000000) {
        shares = shares.toString().substring(0, 2)+','+shares.toString().substring(2, 3)+'M';
    } else if (shares >= 100000000 && shares < 1000000000) {
        shares = shares.toString().substring(0, 3) + 'M';
    } else if (shares >= 1000000000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'B';
    }

    // Know if [user] already liked or shared [post]
    const verifyLikeShare = (user, type) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/${user}/actions/${uid}/verify/${type}`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data.count);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    /* -----------
     * BUILD MEDIA
     * -----------
     */
    const showMedia = () => {
        // -- If there's a link to a media
        const fileRgx = /\.[0-9a-z]+?=[?#]|\.(?:[\w]+)($|\?)/mi;
        const patterns = /https?:\/\/?[\da-z\.-]+\.[a-z\.]{2,6}[\w\S]*\/?/mis;
        let m1 = fileRgx.exec(content_clear);
        let m2 = patterns.exec(content_clear);

        if ((m1 = fileRgx.exec(content_clear)) !== null) {
            m1.forEach((match) => {
                if(match !== '' && match !== '?'){
                    let fileExt = match.replace('?', '');
                    if (fileExt === '.png' || fileExt === '.jpg' || fileExt === '.jpeg' || fileExt === '.gif' || fileExt === '.bmp' || fileExt === '.tif' || fileExt === '.tiff' || fileExt === '.webp' || fileExt === '.mp4' || fileExt === '.mov' || fileExt === '.avi' || fileExt === '.wmf' || fileExt === '.flv' || fileExt === '.webm' || fileExt === '.mkv' || fileExt === '.ogg' || fileExt === '.vob' || fileExt === '.3gp' || fileExt === '.aa' || fileExt === '.aax' || fileExt === '.aac' || fileExt === '.aiff' || fileExt === '.alac' || fileExt === '.m4a' || fileExt === '.m4b' || fileExt === '.m4p' || fileExt === '.mp3' || fileExt === '.mpc' || fileExt === '.wav') {
                        if ((m2 = patterns.exec(content_clear)) !== null) {
                            m2.forEach((matchImage) => {
                                let spostImage = matchImage;
                                
                                if (spostImage.includes('.mp4') || spostImage.includes('.mov') || spostImage.includes('.avi') || spostImage.includes('.wmf') || spostImage.includes('.flv') || spostImage.includes('.webm') || spostImage.includes('.mkv') || spostImage.includes('.ogg') || spostImage.includes('.vob')) {
                                    setMedia(
                                        <div className="mediaSpost" style={{ cursor: "default", marginLeft: 45 }}>
                                            <Video videoRef={uid} videoSrc={spostImage} params={{ stopPropagation: true, nsfw: nsfw }} />
                                        </div>
                                    );
                                }else if (spostImage.includes('.png') || spostImage.includes('.jpg') || spostImage.includes('.jpeg') || spostImage.includes('.gif') || spostImage.includes('.bmp') || spostImage.includes('.tif') || spostImage.includes('.tiff') || spostImage.includes('.webp')) {
                                    setMedia(
                                        <div className="mediaSpost" {...(nsfw === true ? { style: { cursor: 'default', marginLeft: 45 } }: {marginLeft: 45} )}>
                                            <img src={arrayMedia} alt={arrayMedia} title={arrayMedia} {...(nsfw === true ? { style: { filter: 'blur(16px)', pointerEvents: "none" } }: {} )}
                                                onError={({ currentTarget }) => {
                                                    if(theme === "darkTheme"){
                                                        currentTarget.src = errorMediaDark;
                                                    }else{
                                                        currentTarget.src = errorMediaLight;
                                                    }
                                                }}
                                                referrerPolicy="no-referrer"
                                               
                                            />
                                        </div>
                                    );
                                }else if(spostImage.includes('.3gp') || spostImage.includes('.aa') || spostImage.includes('.aax') || spostImage.includes('.aac') || spostImage.includes('.aiff') || spostImage.includes('.alac') || spostImage.includes('.m4a') || spostImage.includes('.m4b') || spostImage.includes('.m4p') || spostImage.includes('.mpc') || spostImage.includes('.wav') || spostImage.includes('.mp3')) {
                                    setMedia(
                                        <Audio audioRef={uid} audioSrc={spostImage} params={{ stopPropagation: true, marginLeft: 45 }} />
                                    )
                                }
                            });
                        }
                    }
                }
            });
        }

        // -- If there is a media (no link)
        if(JSON.parse(media).length !== 0) {
            var arrayMedia = JSON.parse(media).toString();
            const params = new URL(arrayMedia).searchParams;

            if(acceptedVideo.includes(params.get('ext'))) {
                setMedia(
                    <div className="mediaSpost" style={{ marginLeft: 45 }}>
                        <Video videoRef={uid} videoSrc={arrayMedia} params={{ stopPropagation: true, nsfw: nsfw }} />
                    </div>
                );
            }else if(acceptedImage.includes(params.get('ext'))) {
                setMedia(
                    <div className="mediaSpost" {...(nsfw === true ? { style: { cursor: 'default', marginLeft: 45 } }: { style: {marginLeft: 45} } )}>
                        <img src={arrayMedia} alt={arrayMedia} title={arrayMedia} {...(nsfw === true ? { style: { filter: 'blur(16px)', pointerEvents: "none" } }: {} )}
                            onError={({ currentTarget }) => {
                                if(theme === "darkTheme"){
                                    currentTarget.src = errorMediaDark;
                                }else{
                                    currentTarget.src = errorMediaLight;
                                }
                            }}
                            referrerPolicy="no-referrer"
                           
                        />
                    </div>
                )
            }else if(acceptedAudio.includes(params.get('ext'))) {
                setMedia(
                    <Audio audioRef={uid} audioSrc={arrayMedia} params={{ stopPropagation: true, marginLeft: 45 }} />
                )
            }
        }
    }

    /* ----------------
     * ACTIONS FUNCTION
     * ----------------
     */
    const action = (e, uid, user, type) => {
        if (logged === 1) {
            if(type === 'like'){
                const likeCount = document.querySelector('.like_'+uid+' span');
                let likes = likeCount.textContent;
                likes++;

                likeCount.innerHTML = likes;
                $('.like_'+uid).addClass('liked');
                $('.like_'+uid).removeClass('unlike');
                // Set dislike for click event
                setLikeEvent('dislike');

                /* --------------
                * LIKE ANIMATION
                * --------------
                */
                $.rnd = (m,n) => {
                    m = parseInt(m);
                    n = parseInt(n);
                    return Math.floor( Math.random() * (n - m + 1) ) + m;
                }
                $.each($('.like_'+uid), () => {
                    var confetticount = 8;
                    for(var i = 0; i <= confetticount; i++) {
                        $('.like_'+uid).append('<span className="particle c' + $.rnd(1,2) + '" style="position:absolute;margin-top:' + $.rnd(0,15) + 'px;margin-left:' + $.rnd(0,25) + 'px;width:' + $.rnd(4,6) + 'px; height:' + $.rnd(3,4) + 'px;animation-delay: ' + ($.rnd(0,30)/10) + 's;"></span>');
                    }
                });
                
                // Add like
                axios.post(
                    `https://api.snot.fr/v${API_VERSION}/${myInfos.uid}/actions/${uid}/add/${type}`,
                    { post_user: user },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        }
                    }
                ).then(response => {
                    $('.like_'+uid+' i').removeClass("fi-sr-heart");
                    $('.like_'+uid+' i').addClass("fi-ss-social-network");
                    $('.like_'+uid+' i').css({
                        transform: "scale(130%)",
                        marginTop: "-5px",
                        color: "#2E88BB",
                    });

                    setTimeout(() => {
                        $('.like_'+uid+' i').css({
                            transform: "scale(100%)",
                            marginTop: "0px",
                        });
                    }, 250);
                    setTimeout(() => {
                        $('.like_'+uid+' i').css({ color: "rgb(212, 87, 87)" });
                        $('.like_'+uid+' i').removeClass("fi-ss-social-network");
                        $('.like_'+uid+' i').addClass("fi-sr-heart");
                    }, 1000);
                    setTimeout(() => {
                        $.each($('.like_'+uid+' span'), () => {
                            $('.like_'+uid+' span').remove('.particle.c1');
                            $('.like_'+uid+' span').remove('.particle.c2');
                        });
                    }, 1750);
                })
            }else if (type === 'dislike'){
                const likeCount = document.querySelector('.like_'+uid+' span');
                let likes = likeCount.textContent;
                likes--;

                likeCount.innerHTML = likes;
                $('.like_'+uid).addClass('unlike');
                $('.like_'+uid).removeClass('liked');
                // Like icon
                $('.like_'+uid+' i').removeAttr('style');
                // Set like for click event
                setLikeEvent('like');

                // Remove like
                axios.delete(
                    `https://api.snot.fr/v${API_VERSION}/${myInfos.uid}/actions/${uid}/remove/like`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        },
                        data: {post_user: user}
                    }
                )
            }
        }else {
            openModal(e);
        }
    }

    /* ----------
     * SET BADGES
     * ----------
     */
    useEffect(() => {
        Home.retreiveCreatorInfos(sender).then(res => {
            if(res.infos !== "This user doesn't exist."){
                setInfos({...res});

                // Set badges
                if (res.certified === "Yes" && res.staff === "Yes") {
                    setBadges({ certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
                }else{
                    if (res.staff === "Yes") {
                        setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
                    }
                    if (res.certified === "Yes") {
                        setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>});
                    }
                }

                // Get comment count
                Home.getCountComment(uid).then((count) => setCommentCount(count));
                // Set loading
                setLoading(false);
            }
        });
        // For Media
        showMedia();

        // Retreive my user infos
        if(logged === 1){
            retreiveInfos().then(res => {
                setMyInfos(res);
                // -- Check if [user] already shared
                verifyLikeShare(res.uid, 'share').then(r => {
                    if(r >= 1){ setShareClass("shared") };      
                });
                // ...
                if(user !== res.uid){
                    // -- Check if [user] already liked
                    verifyLikeShare(res.uid, 'like').then(r => {
                        if(r >= 1){
                            setLikeClass("liked");
                            setLikeEvent('dislike');
                        }else {
                            setLikeClass('unlike');
                            setLikeEvent('like');
                        }      
                    });
                }
            })
        }else{
            setLikeClass('unlike')
        }

        // Permit to click on a link
        $(".spostContent_spostMAppMVB1 .content a").on('click', function(e) {
            e.stopPropagation();
        });
    }, [])

    if(loading === true){
        return (
            <>
                <PostLoading />
                <PostLoading />
                <PostLoadingWithMedia />
            </>
        )
    }else {
        return (
            <>
                <div className={"spost_mAppMVeB1 "+theme}>
                    <div onClick={() => navigate('/spost/'+uid)} style={{cursor: "pointer"}}>
                        <div className="profilePart_spostMAppMVB1" style={{flex: 1, flexDirection: "row", alignItems: "flex-start", zIndex: 1, marginTop: 0}}>
                            <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                                <img alt={"@"+infos.usertag.replace('.', '_').toLowerCase()} title={infos.username} src={infos.avatar} className="avatar_pPSMaMvB1"
                                    onError={(e) => {
                                        if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                            e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp'
                                        }
                                    }}
                                style={loadingAvatar ? {display: "none"}: {}} onLoad={() => setLoadingAvatar(false)} referrerPolicy="no-referrer" />
                            </div>
                            <div className={"user "+theme} style={{width: "auto", height: "auto"}}>
                                <span id="username">{infos.username}</span>
                            </div>
                            <span id="usertag" style={{display: "flex"}}>@{infos.usertag.replace('.', '_').toLowerCase()} {badges.certified}{badges.staff}</span>
                            {
                                nsfw === true ?
                                <div className="nsfwTag_comPcVB1">
                                    <i className="fi fi-rr-age-restriction-eighteen"></i>
                                    <span>NSFW</span>
                                </div>
                                : ''
                            }
                        </div>
                        <div className="datePart_spostMAppMVB1" style={{ display: "block" }}>
                            <span>{date_format}</span>
                        </div>
                        <div className="spostContent_spostMAppMVB1" style={{ marginTop: 0, marginLeft: 50 }}>
                            <span className="content">{HtmlToReact.Parser().parse(content)}</span>
                        </div>
                        {mediaHTML}
                    </div>
                    <div className={"countInteractions_spostMAppMVB1 "+theme}>
                        <div id="like" className={"like_"+uid+" "+likeClass} onClick={(e) => action(e, uid, sender, likeEvent)} data-modal="loginModal">
                            <span data-modal="loginModal">{likes}</span>
                            <i className="fi fi-sr-heart" data-modal="loginModal" />
                        </div>
                        <div id="separator"></div>
                        <div id="comment" onClick={() => navigate('/spost/'+uid+'?add=comment')}>
                            <span>{commentCount}</span>
                            <i className="fi fi-sr-comment-alt" />
                        </div>
                        <div id="separator"></div>
                        <div id="share" className={"share_"+uid+" "+shareClass} onClick={() => navigate('/spost/'+uid)}>
                            <span>{shares}</span>
                            <i className="fi fi-sr-share" />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export const Reposts = ({ data: {uid, sender, repost_user, content, content_clear, media, tags, date, repost_date, likes, shares}, theme, logged }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    // Set data
    const [infos, setInfos] = useState({
        username: '',
        usertag: '',
        avatar: '',
        certified: '',
        staff: ''
    })
    const [commentCount, setCommentCount] = useState(0);
    const [mediaHTML, setMedia] = useState(<></>);
    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>
    });
    const [repostBadges, setRepostBadges] = useState({
        certified: <></>,
        staff: <></>
    });
    const [myInfos, setMyInfos] = useState([]);
    const [repostsInfos, setRepostInfos] = useState({
        username: '',
        usertag: '',
        avatar: '',
        certified: '',
        staff: ''
    });
    // Set like & share actions
    const [likeEvent, setLikeEvent] = useState('dislike');
    const [likeClass, setLikeClass] = useState('unlike');
    const [shareClass, setShareClass] = useState("unshare");
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);
    const [loading, setLoading] = useState(true);

    // Set NSFW
    var nsfw = false;
    if(tags !== null) {
        var arrayTags = JSON.parse(tags);
        for(let i = 0; i < arrayTags.length; i++){
            if(nsfwTerms.includes(arrayTags[i].toLowerCase())) { nsfw = true; };
        }
    }

    // Set date format
    const sendDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const match_date = new Date(date);
    match_date.setHours(0, 0, 0, 0);

    const diff = Math.floor((today - match_date) / (1000 * 60 * 60 * 24));
    let diffDays = parseInt(diff);
        
    var date_format = "";
    // Get time since
    var langYear = "y";
    var langDay = "d";
    if(i18n.resolvedLanguage === "fr"){
        langYear = "a";
        langDay = "j"
    }

    if(i18n.resolvedLanguage === "fr"){
        switch (diffDays) {
            default:
                date_format = "il y a " + timeSince(sendDate, langYear, langDay);
        } 
    }else {
        switch (diffDays) {
            default:
                date_format = timeSince(sendDate, langYear, langDay) + " ago";
        }
    }

    // Set repost date format
    const repostDate = new Date(repost_date);
    const repost_match_date = new Date(repost_date);
    repost_match_date.setHours(0, 0, 0, 0);

    const repostDiff = Math.floor((today - repost_match_date) / (1000 * 60 * 60 * 24));
    let repostDiffDays = parseInt(repostDiff);
        
    var repost_date_format = "";
    if(i18n.resolvedLanguage === "fr"){
        switch (repostDiffDays) {
            case 0:
                repost_date_format = "aujourd'hui à " + repostDate.toLocaleString('fr-FR', { hour: 'numeric' });
                break;
            case -1:
                repost_date_format = "hier à " + repostDate.toLocaleString('fr-FR', { hour: 'numeric' });
                break;
            default:
                repost_date_format = "le " + repostDate.toLocaleString('fr-FR', { day: 'numeric', month: 'numeric', year: '2-digit' }) + " à " + repostDate.toLocaleString('fr-FR', { hour: 'numeric' });
        }
    }else {
        switch (repostDiffDays) {
            case 0:
                repost_date_format = "today at " + repostDate.toLocaleString('en-UK', { hour: 'numeric', hour12: true });
                break;
            case -1:
                repost_date_format = "yesterday at " + repostDate.toLocaleString('en-UK', { hour: 'numeric', hour12: true });
                break;
            default:
                repost_date_format = "the " + repostDate.toLocaleString('en-UK', { day: 'numeric', month: 'numeric', year: '2-digit' }) + " à " + repostDate.toLocaleString('en-UK', { hour: 'numeric' });
        }
    }

    // Set likes and shares count
    // -- For likes
    if (likes >= 1000 && likes < 10000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'K';
    } else if (likes >= 10000 && likes < 100000) {
        likes = likes.toString().substring(0, 2)+','+likes.toString().substring(2, 3)+'K';
    } else if (likes >= 100000 && likes < 1000000) {
        likes = likes.toString().substring(0, 3) + 'K';
    } else if (likes >= 1000000 && likes < 10000000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'M';
    } else if (likes >= 10000000 && likes < 100000000) {
        likes = likes.toString().substring(0, 2)+','+likes.toString().substring(2, 3)+'M';
    } else if (likes >= 100000000 && likes < 1000000000) {
        likes = likes.toString().substring(0, 3) + 'M';
    } else if (likes >= 1000000000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'B';
    }
    // -- For shares
    if (shares >= 1000 && shares < 10000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'K';
    } else if (shares >= 10000 && shares < 100000) {
        shares = shares.toString().substring(0, 2)+','+shares.toString().substring(2, 3)+'K';
    } else if (shares >= 100000 && shares < 1000000) {
        shares = shares.toString().substring(0, 3) + 'K';
    } else if (shares >= 1000000 && shares < 10000000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'M';
    } else if (shares >= 10000000 && shares < 100000000) {
        shares = shares.toString().substring(0, 2)+','+shares.toString().substring(2, 3)+'M';
    } else if (shares >= 100000000 && shares < 1000000000) {
        shares = shares.toString().substring(0, 3) + 'M';
    } else if (shares >= 1000000000) {
        shares = shares.toString().substring(0, 1)+','+shares.toString().substring(1, 2)+'B';
    }

    // Know if [user] already liked or shared [post]
    const verifyLikeShare = (user, type) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/${user}/actions/${uid}/verify/${type}`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data.count);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    /* -----------
     * BUILD MEDIA
     * -----------
     */
    const showMedia = () => {
        // -- If there's a link to a media
        const fileRgx = /\.[0-9a-z]+?=[?#]|\.(?:[\w]+)($|\?)/mi;
        const patterns = /https?:\/\/?[\da-z\.-]+\.[a-z\.]{2,6}[\w\S]*\/?/mis;
        let m1 = fileRgx.exec(content_clear);
        let m2 = patterns.exec(content_clear);

        if ((m1 = fileRgx.exec(content_clear)) !== null) {
            m1.forEach((match) => {
                if(match !== '' && match !== '?'){
                    let fileExt = match.replace('?', '');
                    if (fileExt === '.png' || fileExt === '.jpg' || fileExt === '.jpeg' || fileExt === '.gif' || fileExt === '.bmp' || fileExt === '.tif' || fileExt === '.tiff' || fileExt === '.webp' || fileExt === '.mp4' || fileExt === '.mov' || fileExt === '.avi' || fileExt === '.wmf' || fileExt === '.flv' || fileExt === '.webm' || fileExt === '.mkv' || fileExt === '.ogg' || fileExt === '.vob' || fileExt === '.3gp' || fileExt === '.aa' || fileExt === '.aax' || fileExt === '.aac' || fileExt === '.aiff' || fileExt === '.alac' || fileExt === '.m4a' || fileExt === '.m4b' || fileExt === '.m4p' || fileExt === '.mp3' || fileExt === '.mpc' || fileExt === '.wav') {
                        if ((m2 = patterns.exec(content_clear)) !== null) {
                            m2.forEach((matchImage) => {
                                let spostImage = matchImage;
                                
                                if (spostImage.includes('.mp4') || spostImage.includes('.mov') || spostImage.includes('.avi') || spostImage.includes('.wmf') || spostImage.includes('.flv') || spostImage.includes('.webm') || spostImage.includes('.mkv') || spostImage.includes('.ogg') || spostImage.includes('.vob')) {
                                    setMedia(
                                        <div className="mediaSpost" style={{ cursor: "default", marginLeft: 45 }}>
                                            <Video videoRef={uid} videoSrc={spostImage} params={{ stopPropagation: true, nsfw: nsfw }} />
                                        </div>
                                    );
                                }else if (spostImage.includes('.png') || spostImage.includes('.jpg') || spostImage.includes('.jpeg') || spostImage.includes('.gif') || spostImage.includes('.bmp') || spostImage.includes('.tif') || spostImage.includes('.tiff') || spostImage.includes('.webp')) {
                                    setMedia(
                                        <div className="mediaSpost" {...(nsfw === true ? { style: { cursor: 'default', marginLeft: 45 } }: {marginLeft: 45} )}>
                                            <img src={arrayMedia} alt={arrayMedia} title={arrayMedia} {...(nsfw === true ? { style: { filter: 'blur(16px)', pointerEvents: "none" } }: {} )}
                                                onError={({ currentTarget }) => {
                                                    if(theme === "darkTheme"){
                                                        currentTarget.src = errorMediaDark;
                                                    }else{
                                                        currentTarget.src = errorMediaLight;
                                                    }
                                                }}
                                                referrerPolicy="no-referrer"
                                               
                                            />
                                        </div>
                                    );
                                }else if(spostImage.includes('.3gp') || spostImage.includes('.aa') || spostImage.includes('.aax') || spostImage.includes('.aac') || spostImage.includes('.aiff') || spostImage.includes('.alac') || spostImage.includes('.m4a') || spostImage.includes('.m4b') || spostImage.includes('.m4p') || spostImage.includes('.mpc') || spostImage.includes('.wav') || spostImage.includes('.mp3')) {
                                    setMedia(
                                        <Audio audioRef={uid} audioSrc={spostImage} params={{ stopPropagation: true, marginLeft: 45 }} />
                                    )
                                }
                            });
                        }
                    }
                }
            });
        }

        // -- If there is a media (no link)
        if(JSON.parse(media).length !== 0) {
            var arrayMedia = JSON.parse(media).toString();
            const params = new URL(arrayMedia).searchParams;

            if(acceptedVideo.includes(params.get('ext'))) {
                setMedia(
                    <div className="mediaSpost" style={{ marginLeft: 45 }}>
                        <Video videoRef={uid} videoSrc={arrayMedia} params={{ stopPropagation: true, nsfw: nsfw }} />
                    </div>
                );
            }else if(acceptedImage.includes(params.get('ext'))) {
                setMedia(
                    <div className="mediaSpost" {...(nsfw === true ? { style: { cursor: 'default', marginLeft: 45 } }: { style: {marginLeft: 45} } )}>
                        <img src={arrayMedia} alt={arrayMedia} title={arrayMedia} {...(nsfw === true ? { style: { filter: 'blur(16px)', pointerEvents: "none" } }: {} )}
                            onError={({ currentTarget }) => {
                                if(theme === "darkTheme"){
                                    currentTarget.src = errorMediaDark;
                                }else{
                                    currentTarget.src = errorMediaLight;
                                }
                            }}
                            referrerPolicy="no-referrer"
                        />
                    </div>
                )
            }else if(acceptedAudio.includes(params.get('ext'))) {
                setMedia(
                    <Audio audioRef={uid} audioSrc={arrayMedia} params={{ stopPropagation: true, marginLeft: 45 }} />
                )
            }
        }
    }

    /* ----------------
     * ACTIONS FUNCTION
     * ----------------
     */
    const action = (e, uid, user, type) => {
        if (type === 'like') {
            if(logged === 1){
                const likeCount = document.querySelector('.like_'+uid+' span');
                let likes = likeCount.textContent;
                likes++;

                likeCount.innerHTML = likes;
                $('.like_'+uid).addClass('liked');
                $('.like_'+uid).removeClass('unlike');
                // Set dislike for click event
                setLikeEvent('dislike');

                /* --------------
                * LIKE ANIMATION
                * --------------
                */
                $.rnd = (m,n) => {
                    m = parseInt(m);
                    n = parseInt(n);
                    return Math.floor( Math.random() * (n - m + 1) ) + m;
                }
                $.each($('.like_'+uid), () => {
                    var confetticount = 8;
                    for(var i = 0; i <= confetticount; i++) {
                        $('.like_'+uid).append('<span className="particle c' + $.rnd(1,2) + '" style="position:absolute;margin-top:' + $.rnd(0,15) + 'px;margin-left:' + $.rnd(0,25) + 'px;width:' + $.rnd(4,6) + 'px; height:' + $.rnd(3,4) + 'px;animation-delay: ' + ($.rnd(0,30)/10) + 's;"></span>');
                    }
                });
                
                // Add like
                axios.post(
                    `https://api.snot.fr/v${API_VERSION}/${myInfos.uid}/actions/${uid}/add/${type}`,
                    { post_user: user },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        }
                    }
                ).then(response => {
                    $('.like_'+uid+' i').removeClass("fi-sr-heart");
                    $('.like_'+uid+' i').addClass("fi-ss-social-network");
                    $('.like_'+uid+' i').css({
                        transform: "scale(130%)",
                        marginTop: "-5px",
                        color: "#2E88BB",
                    });

                    setTimeout(() => {
                        $('.like_'+uid+' i').css({
                            transform: "scale(100%)",
                            marginTop: "0px",
                        });
                    }, 250);
                    setTimeout(() => {
                        $('.like_'+uid+' i').css({ color: "rgb(212, 87, 87)" });
                        $('.like_'+uid+' i').removeClass("fi-ss-social-network");
                        $('.like_'+uid+' i').addClass("fi-sr-heart");
                    }, 1000);
                    setTimeout(() => {
                        $.each($('.like_'+uid+' span'), () => {
                            $('.like_'+uid+' span').remove('.particle.c1');
                            $('.like_'+uid+' span').remove('.particle.c2');
                        });
                    }, 1750);
                })
            }else{
                openModal(e);
            }
        }else if (type === 'dislike') {
            if(logged === 1){
                const likeCount = document.querySelector('.like_'+uid+' span');
                let likes = likeCount.textContent;
                likes--;

                likeCount.innerHTML = likes;
                $('.like_'+uid).addClass('unlike');
                $('.like_'+uid).removeClass('liked');
                // Like icon
                $('.like_'+uid+' i').removeAttr('style');
                // Set like for click event
                setLikeEvent('like');

                // Remove like
                axios.delete(
                    `https://api.snot.fr/v${API_VERSION}/${myInfos.uid}/actions/${uid}/remove/like`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        },
                        data: {post_user: user}
                    }
                )
            }else{
                openModal(e);
            }
        }
    }

    /* ----------
     * SET BADGES
     * ----------
     */
    useEffect(() => {
        Home.retreiveCreatorInfos(sender).then(res => {
            if(res.infos !== "This user doesn't exist."){
                setInfos({...res});

                // Set badges
                if (res.certified === "Yes" && res.staff === "Yes") {
                    setBadges({ certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
                }else{
                    if (res.staff === "Yes") {
                        setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
                    }
                    if (res.certified === "Yes") {
                        setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>});
                    }
                }

                // Get comment count
                Home.getCountComment(uid).then((count) => setCommentCount(count));
                // Retreive repost [user] infos
                Home.retreiveCreatorInfos(repost_user).then(resRepost => {
                    if(resRepost.infos !== "This user doesn't exist."){
                        setRepostInfos({...resRepost});

                        // Set badges
                        if (resRepost.certified === "Yes") {
                            var colorBadge = "#000";
                            if(theme === "darkTheme"){
                                colorBadge = "#fff"
                            }
                            setRepostBadges({ ...repostBadges, certified: <i className="fi fi-sr-badge-check" style={{ color: colorBadge }}></i>});
                        }
                        if (resRepost.staff === "Yes") {
                            setRepostBadges({ ...repostBadges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
                        }
                    }
                });
                // Set loading
                setLoading(false);
            }
        });
        // For Media
        showMedia();

        // Retreive my user infos
        if(logged === 1){
            retreiveInfos().then(res => {
                setMyInfos(res);
                // Check if [user] already liked or shared
                // -- Liked
                verifyLikeShare(res.uid, 'like').then(r => {
                    if(r >= 1){ setLikeClass("liked") };      
                })
                // -- Shared
                verifyLikeShare(res.uid, 'share').then(r => {
                    if(r >= 1){ setShareClass("shared") };      
                })
            })
        }

        // Permit to click on a link
        $(".spostContent_spostMAppMVB1 .content a").on('click', function(e) {
            e.stopPropagation();
        });
    }, [])

    if(loading === true){
        return (
            <>
                <PostLoading />
                <PostLoading />
                <PostLoadingWithMedia />
            </>
        )
    }else {
        return (
            <>
                <div className={"spost_mAppMVeB1 "+theme}>
                    <div onClick={() => navigate('/spost/'+uid)} style={{cursor: "pointer"}}>
                    <div className={"repostPart_spostMAppMVB1 "+theme}>
                            <div id="attach"></div>
                            <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme } style={{ width: 30, height: 30 }}>
                                <img alt={"@"+repostsInfos.usertag.replace('.', '_').toLowerCase()} title={repostsInfos.username} src={repostsInfos.avatar} className="avatar_pPSMaMvB1 repost"
                                    onError={(e) => {
                                        if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                            e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp'
                                        }
                                    }}
                                style={loadingAvatar ? {display: "none"}: {}} onLoad={() => setLoadingAvatar(false)} referrerPolicy="no-referrer" />
                            </div>
                            <div id="profile">
                                <span id="usertag" style={{display: "flex"}}>@{repostsInfos.usertag.replace('.', '_').toLowerCase()} {repostBadges.certified}{repostBadges.staff}</span>
                                <span id="content">{t('home.repost.content')} <span id="date">{repost_date_format}</span></span>
                            </div>
                        </div>
                        <div className="profilePart_spostMAppMVB1" style={{flex: 1, flexDirection: "row", alignItems: "flex-start", zIndex: 1, marginTop: 0}}>
                            <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                                <img alt={"@"+infos.usertag.replace('.', '_').toLowerCase()} title={infos.username} src={infos.avatar} className="avatar_pPSMaMvB1"
                                    onError={(e) => {
                                        if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                            e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp'
                                        }
                                    }}
                                style={loadingAvatar ? {display: "none"}: {}} onLoad={() => setLoadingAvatar(false)} referrerPolicy="no-referrer" />
                            </div>
                            <div className={"user "+theme} style={{width: "auto", height: "auto"}}>
                                <span id="username">{infos.username}</span>
                            </div>
                            <span id="usertag" style={{display: "flex"}}>@{infos.usertag.replace('.', '_').toLowerCase()} {badges.certified}{badges.staff}</span>
                            {
                                nsfw === true ?
                                <div className="nsfwTag_comPcVB1">
                                    <i className="fi fi-rr-age-restriction-eighteen"></i>
                                    <span>NSFW</span>
                                </div>
                                : ''
                            }
                        </div>
                        <div className="datePart_spostMAppMVB1" style={{ display: "block" }}>
                            <span>{date_format}</span>
                        </div>
                        <div className="spostContent_spostMAppMVB1" style={{ marginTop: 0, marginLeft: 50 }}>
                            <span className="content">{HtmlToReact.Parser().parse(content)}</span>
                        </div>
                        {mediaHTML}
                    </div>
                    <div className={"countInteractions_spostMAppMVB1 "+theme}>
                        <div id="like" className={"like_"+uid+" "+likeClass} onClick={(e) => action(e, uid, sender, likeEvent)} data-modal="loginModal">
                            <span data-modal="loginModal">{likes}</span>
                            <i className="fi fi-sr-heart" data-modal="loginModal" />
                        </div>
                        <div id="separator"></div>
                        <div id="comment" onClick={() => navigate('/spost/'+uid+'?add=comment')}>
                            <span>{commentCount}</span>
                            <i className="fi fi-sr-comment-alt" />
                        </div>
                        <div id="separator"></div>
                        <div id="share" className={"share_"+uid+" "+shareClass} onClick={() => navigate('/spost/'+uid)}>
                            <span>{shares}</span>
                            <i className="fi fi-sr-share" />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

// REPLIES
const Replies = ({ data: {uid, user, content, textonly, parent, postUser, postUid, media, nsfw, date, likes}, theme, logged, select }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    // Set data
    const [infos, setInfos] = useState({
        username: '',
        usertag: '',
        avatar: '',
        uid: '',
        date_account: '',
        aboutme: ''
    });
    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>,
    });
    const [mediaHTML, setMedia] = useState(<></>);
    const [myInfos, setMyInfos] = useState([]);
    const [ruid, setRUID] = useState('');
    // Set like & share actions
    const [likeEvent, setLikeEvent] = useState('rlike');
    const [likeClass, setLikeClass] = useState('unlike');
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);
    const [loading, setLoading] = useState(true);
    // Set blur
    const [blur, setBlur] = useState(false);

    // Set date format
    const sendDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const match_date = new Date(date);
    match_date.setHours(0, 0, 0, 0);

    const diff = Math.floor((today - match_date) / (1000 * 60 * 60 * 24));
    let diffDays = parseInt(diff);
        
    var date_format = "";
    if(i18n.resolvedLanguage === "fr"){
        switch (diffDays) {
            case 0:
                date_format = "Aujourd'hui à " + sendDate.toLocaleString('fr-FR', { hour: 'numeric' });
                break;
            case -1:
                date_format = "Hier à " + sendDate.toLocaleString('fr-FR', { hour: 'numeric' });
                break;
            default:
                date_format = "Le " + sendDate.toLocaleString('fr-FR', { day: 'numeric', month: 'numeric', year: '2-digit' });
        }
    }else {
        switch (diffDays) {
            case 0:
                date_format = "Today at " + sendDate.toLocaleString('en-UK', { hour: 'numeric', hour12: true });
                break;
            case -1:
                date_format = "Yesterday at " + sendDate.toLocaleString('en-UK', { hour: 'numeric', hour12: true });
                break;
            default:
                date_format = "The " + sendDate.toLocaleString('en-UK', { day: 'numeric', month: 'numeric', year: '2-digit' });
        }
    }

    // Set likes and shares count
    // -- For likes
    if (likes >= 1000 && likes < 10000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'K';
    } else if (likes >= 10000 && likes < 100000) {
        likes = likes.toString().substring(0, 2)+','+likes.toString().substring(2, 3)+'K';
    } else if (likes >= 100000 && likes < 1000000) {
        likes = likes.toString().substring(0, 3) + 'K';
    } else if (likes >= 1000000 && likes < 10000000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'M';
    } else if (likes >= 10000000 && likes < 100000000) {
        likes = likes.toString().substring(0, 2)+','+likes.toString().substring(2, 3)+'M';
    } else if (likes >= 100000000 && likes < 1000000000) {
        likes = likes.toString().substring(0, 3) + 'M';
    } else if (likes >= 1000000000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'B';
    }

    // GENERATE UID FOR COMMENT
    const generatedRUID = () => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/replies/generate/uid`
            }).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    // Know if [user] already liked [comment]
    const verifyLike = (userID) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/${userID}/actions/${uid}/verify/like_c`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data.count);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    /* -----------
     * BUILD MEDIA
     * -----------
     */
    const showMedia = () => {
        // -- If there's a link to a media
        const fileRgx = /\.[0-9a-z]+?=[?#]|\.(?:[\w]+)($|\?)/mi;
        const patterns = /https?:\/\/?[\da-z\.-]+\.[a-z\.]{2,6}[\w\S]*\/?/mis;
        let m1 = fileRgx.exec(textonly);
        let m2 = patterns.exec(textonly);

        if ((m1 = fileRgx.exec(textonly)) !== null) {
            m1.forEach((match) => {
                if(match !== '' && match !== '?'){
                    let fileExt = match.replace('?', '');
                    if (fileExt === '.png' || fileExt === '.jpg' || fileExt === '.jpeg' || fileExt === '.gif' || fileExt === '.bmp' || fileExt === '.tif' || fileExt === '.tiff' || fileExt === '.webp' || fileExt === '.mp4' || fileExt === '.mov' || fileExt === '.avi' || fileExt === '.wmf' || fileExt === '.flv' || fileExt === '.webm' || fileExt === '.mkv' || fileExt === '.ogg' || fileExt === '.vob' || fileExt === '.3gp' || fileExt === '.aa' || fileExt === '.aax' || fileExt === '.aac' || fileExt === '.aiff' || fileExt === '.alac' || fileExt === '.m4a' || fileExt === '.m4b' || fileExt === '.m4p' || fileExt === '.mp3' || fileExt === '.mpc' || fileExt === '.wav') {
                        if ((m2 = patterns.exec(textonly)) !== null) {
                            m2.forEach((matchImage) => {
                                let spostImage = matchImage;
                                
                                if (spostImage.includes('.mp4') || spostImage.includes('.mov') || spostImage.includes('.avi') || spostImage.includes('.wmf') || spostImage.includes('flv') || spostImage.includes('.webm') || spostImage.includes('.mkv') || spostImage.includes('.ogg') || spostImage.includes('.vob')) {
                                    setMedia(
                                        <div className="mediaSpost" style={{cursor: "default" }}>
                                            <Video videoRef={uid} videoSrc={spostImage} params={{ stopPropagation: true }} />
                                        </div>
                                    );
                                }else if (spostImage.includes('.png') || spostImage.includes('.jpg') || spostImage.includes('.jpeg') || spostImage.includes('.gif') || spostImage.includes('.bmp') || spostImage.includes('.tif') || spostImage.includes('.tiff') || spostImage.includes('.webp')) {
                                    setMedia(
                                        <div className="mediaSpost" onClick={openModal}>
                                            <img src={spostImage} alt={spostImage} title={spostImage} {...blur}
                                                onError={({ currentTarget }) => {
                                                    if(theme === "darkTheme"){
                                                        currentTarget.src = errorMediaDark;
                                                    }else{
                                                        currentTarget.src = errorMediaLight;
                                                    }
                                                }}
                                                referrerPolicy="no-referrer"
                                            />
                                        </div>
                                    );
                                }else if(spostImage.includes('.3gp') || spostImage.includes('.aa') || spostImage.includes('.aax') || spostImage.includes('.aac') || spostImage.includes('.aiff') || spostImage.includes('.alac') || spostImage.includes('.m4a') || spostImage.includes('.m4b') || spostImage.includes('.m4p') || spostImage.includes('.mpc') || spostImage.includes('.wav') || spostImage.includes('.mp3')) {
                                    setMedia(
                                        <Audio audioRef={uid} audioSrc={spostImage} params={{ stopPropagation: true, marginLeft: 0 }} />
                                    )
                                }
                            });
                        }
                    }
                }
            });
        }

        // -- If there is a media (no link)
        if(JSON.parse(media).length !== 0) {
            var arrayMedia = JSON.parse(media).toString();
            const params = new URL(arrayMedia).searchParams;

            if(acceptedVideo.includes(params.get('ext'))) {
                setMedia(
                    <div className="mediaSpost">
                        <Video videoRef={uid} videoSrc={arrayMedia} params={{ stopPropagation: true, nsfw: nsfw }} />
                    </div>
                );
            }else if(acceptedImage.includes(params.get('ext'))) {
                setMedia(
                    <div className="mediaSpost" {...(nsfw === true ? { style: { cursor: 'default' } }: {} )}>
                        <img src={arrayMedia} alt={arrayMedia} title={arrayMedia} {...(nsfw === true ? { style: { filter: 'blur(16px)', pointerEvents: "none" } }: {} )}
                            onError={({ currentTarget }) => {
                                if(theme === "darkTheme"){
                                    currentTarget.src = errorMediaDark;
                                }else{
                                    currentTarget.src = errorMediaLight;
                                }
                            }}
                            referrerPolicy="no-referrer"
                        />
                    </div>
                )
            }else if(acceptedAudio.includes(params.get('ext'))) {
                setMedia(
                    <Audio audioRef={uid} audioSrc={arrayMedia} params={{ stopPropagation: true, marginLeft: 0 }} />
                )
            }
        }
    }

    /* ----------------
     * ACTIONS FUNCTION
     * ----------------
     */
    const action = (e, uid, user, type) => {
        if (type === 'rlike') {
            if(logged === 1){
                const likeCount = document.querySelector('.rlike_'+uid+' span');
                let likes = likeCount.textContent;
                likes++;

                likeCount.innerHTML = likes;
                $('.rlike_'+uid).addClass('liked');
                $('.rlike_'+uid).removeClass('unlike');
                // Set dislike for click event
                setLikeEvent('rdislike');

                /* --------------
                * LIKE ANIMATION
                * --------------
                */
                $.rnd = (m,n) => {
                    m = parseInt(m);
                    n = parseInt(n);
                    return Math.floor( Math.random() * (n - m + 1) ) + m;
                }
                $.each($('.rlike_'+uid), () => {
                    var confetticount = 8;
                    for(var i = 0; i <= confetticount; i++) {
                        $('.rlike_'+uid).append('<span className="particle c' + $.rnd(1,2) + '" style="position:absolute;margin-top:' + $.rnd(0,15) + 'px;margin-left:' + $.rnd(0,25) + 'px;width:' + $.rnd(4,6) + 'px; height:' + $.rnd(3,4) + 'px;animation-delay: ' + ($.rnd(0,30)/10) + 's;"></span>');
                    }
                });
                
                // Add like
                axios.post(
                    `https://api.snot.fr/v${API_VERSION}/${myInfos.uid}/actions/${uid}/add/like_r`,
                    { post_user: user },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        }
                    }
                ).then(response => {
                    $('.rlike_'+uid+' i').removeClass("fi-sr-heart");
                    $('.rlike_'+uid+' i').addClass("fi-ss-social-network");
                    $('.rlike_'+uid+' i').css({
                        transform: "scale(130%)",
                        marginTop: "-5px",
                        color: "#2E88BB",
                    });

                    setTimeout(() => {
                        $('.rlike_'+uid+' i').css({
                            transform: "scale(100%)",
                            marginTop: "0px",
                        });
                    }, 250);
                    setTimeout(() => {
                        $('.rlike_'+uid+' i').css({ color: "rgb(212, 87, 87)" });
                        $('.rlike_'+uid+' i').removeClass("fi-ss-social-network");
                        $('.rlike_'+uid+' i').addClass("fi-sr-heart");
                    }, 1000);
                    setTimeout(() => {
                        $.each($('.rlike_'+uid+' span'), () => {
                            $('.rlike_'+uid+' span').remove('.particle.c1');
                            $('.rlike_'+uid+' span').remove('.particle.c2');
                        });
                    }, 1750);
                })
            }else{
                openModal(e);
            }
        }else if (type === 'rdislike') {
            if(logged === 1){
                const likeCount = document.querySelector('.rlike_'+uid+' span');
                let likes = likeCount.textContent;
                likes--;

                likeCount.innerHTML = likes;
                $('.rlike_'+uid).addClass('unlike');
                $('.rlike_'+uid).removeClass('liked');
                // Like icon
                $('.rlike_'+uid+' i').removeAttr('style');
                // Set like for click event
                setLikeEvent('rlike');

                // Remove like
                axios.delete(
                    `https://api.snot.fr/v${API_VERSION}/${myInfos.uid}/actions/${uid}/remove/like_r`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        },
                        data: {post_user: user}
                    }
                )
            }else{
                openModal(e);
            }
        }
    }

    useEffect(() => {
        // Retreive [user] infos
        Home.retreiveCreatorInfos(user).then((res) => {
            if(res !== "This user doesn't exist.") {
                setInfos({...res});

                // Set badges
                if (res.certified === "Yes" && res.staff === "Yes") {
                    setBadges({ certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
                }else{
                    if (res.staff === "Yes") {
                        setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
                    }
                    if (res.certified === "Yes") {
                        setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>});
                    }
                }
            }
            // Set loading
            setLoading(false);
        })

        // GENERATE UID FOR REPLY
        generatedRUID().then(res => setRUID(res.uid))

        // RETREIVE MY INFOS
        if(logged === 1){
            retreiveInfos().then(res => {
                setMyInfos(res)
                verifyLike(res.uid).then(resLike => {
                    if(resLike >= 1){
                        setLikeEvent('rdislike');
                        setLikeClass('liked');
                    }
                })
            })
        }
    }, [])

    useEffect(() => {
        // NSFW
        if(nsfw === "Yes"){
            setBlur(true);
        }
        // For Media
        showMedia();
    }, [nsfw, loading])

    if(loading === false){
        return (
            <>
                <div id="replyContainer" className={"reply_"+uid+" rContainer_"+parent+" "+theme} {...((location.pathname.includes('/reply/') && location.search.replace('?parent=', '') === parent) ? { style: { display: 'block' } }: { style: { display: 'none' } })}>
                    <div className={"reply_sMAMVeB1 "+theme}>
                        <div className="profilePart_spostMAppMVB1 reply" id="profilePart" style={{flex: 1, flexDirection: "row", alignItems: "flex-start", zIndex: 1, marginTop: -25}}>
                            <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme } style={{ width: 40, height: 40 }} onClick={() => navigate('/@'+infos.usertag.replace('.', '_').toLowerCase())}>
                                <img alt={"@"+infos.usertag.replace('.', '_').toLowerCase()} title={infos.username} src={infos.avatar} className={"avatar_pPSMaMvB1 ref_"+uid}
                                    onError={(e) => {
                                        if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                            e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp'
                                        }
                                    }}
                                style={loadingAvatar ? {display: "none"}: {}} onLoad={() => setLoadingAvatar(false)} referrerPolicy="no-referrer" />
                            </div>
                            <span id="username" {...(postUser === user ? { className: "creator" }: {})} style={{ marginLeft: 10 }} onClick={() => navigate('/@'+infos.usertag.replace('.', '_').toLowerCase())}>{infos.username}</span>
                            <span id="usertag" onClick={() => navigate('/@'+infos.usertag.replace('.', '_').toLowerCase())}>@{infos.usertag.replace('.', '_').toLowerCase()} {badges.certified}{badges.staff}{select === true ? <i className="fi fi-ss-star"></i>: <></>}</span>
                            {
                                blur === true ?
                                <div className="nsfwTag_comPcVB1">
                                    <i className="fi fi-rr-age-restriction-eighteen"></i>
                                    <span>NSFW</span>
                                </div>
                                : ''
                            }
                        </div>
                        <div className="datePart_spostMAppMVB1" style={{ display: "block", marginTop: -25 }}>{date_format}</div>
                        <div className={"spostContent_spostMAppMVB1 "+theme} style={{marginTop:30,marginLeft:50}}>
                            <span className="content">{HtmlToReact.Parser().parse(content)}</span>
                            {mediaHTML}
                        </div>
                    </div>
                    <div className={"countInteractions_comMAMVeB1 reply "+theme}>
                        <div id="like" className={"rlike_"+uid+" "+likeClass} onClick={(e) => action(e, uid, user, likeEvent)} data-modal="loginModal">
                            <span data-modal="loginModal">{likes}</span>
                            <i className="fi fi-sr-heart" data-modal="loginModal" />
                        </div>
                        <div id="separator"></div>
                        <div id="comment" data-modal={"addReply_"+uid} onClick={(e) => openModal(e)}>
                            <span data-modal={"addReply_"+uid}>{t('home.general.reply')}</span>
                            <i className="fi fi-sr-comment-alt" data-modal={"addReply_"+uid} />
                        </div>
                        <div id="separator"></div>
                        <div id="share" className={"shareReply_"+uid} onClick={() => {
                            var spost = location.pathname;
                            if(location.pathname.includes('/reply/')){
                                spost = location.pathname.split('/reply')[0]
                            }else if(location.pathname.includes('/comment/')){
                                spost = location.pathname.split('/comment')[0]
                            }

                            copy("https://snot.fr"+spost+"/reply/"+uid+"?parent="+parent);
                            // UI
                            $(".shareReply_"+uid+" span").text(t('general.copied'));
                            setTimeout(() => {
                                if($(".shareReply_"+uid)){
                                    $(".shareReply_"+uid+' span').text(t('comment.share'))
                                }
                            }, 2000)
                        }}>
                            <span>{t('comment.share')}</span>
                            <i className="fi fi-sr-share" />
                        </div>
                    </div>
                    <div className={"moreInteractions_sMAMVeB1 replies "+theme} data-container={"moreBMR_"+uid} onClick={(e) => openMenu(e)}>
                        <div id="more" data-container={"moreBMR_"+uid}>
                            <i className="fi fi-sr-menu-dots-vertical" data-container={"moreBMR_"+uid}></i>
                        </div>
                    </div>
                </div>
                {/* ADD REPLY MODAL */
                    (loading === false && logged === 1 && myInfos.length !== 0) ?
                    <ReplyToPopUp user={myInfos} post={postUid} comment={{uid: parent, user: infos.usertag}} reply={{default: true, uid: uid, user: infos.usertag}} uid={ruid} theme={theme} />
                    : <></>
                }
                {/* EDIT MODAL */
                    (loading === false && logged === 1 && myInfos.length !== 0) ?
                    <EditReplyPopUp user={myInfos} post={{uid: uid, content: content, textonly: textonly, media: media, nsfw: nsfw}} theme={theme} />
                    : <></>
                }
                {/* MORE (REPLY) MENU */}
                <aside id={"moreBMR_"+uid} className={"menu "+theme} aria-hidden="true" aria-modal="false" style={{display:"none"}}>
                    <div className="menu-wrapper js-menu-stop">
                        <div className="actionsPart_spostMMVB1">
                            {
                                logged === 1 && myInfos.length !== 0 ?
                                    user !== myInfos.uid ?
                                        <>
                                        <EditReplyButton uid={myInfos.uid} reply={uid} />
                                        <CopyReplyButton uid={uid} string={textonly} />
                                        <ReportReplyButton myUID={myInfos.uid} uid={uid} />
                                        <DeleteReplyButton uid={myInfos.uid} reply={uid} parent={parent} />
                                        </>
                                    :
                                    <>
                                    <EditReplyButton uid="session" reply={uid} />
                                    <CopyReplyButton uid={uid} string={textonly} />
                                    <DeleteReplyButton uid="session" reply={uid} parent={parent} realUID={myInfos.uid} />
                                    </>
                                :
                                <CopyReplyButton uid={uid} string={textonly} />
                            }
                        </div>
                    </div>
                </aside>
            </>
        )
    }
}

// COMMENTS
export const Comments = ({ data: {uid, user, content, textonly, parent, postUser, media, nsfw, date, likes}, theme, logged, select, reqReplyUid }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    // Set data
    const [infos, setInfos] = useState({
        username: '',
        usertag: '',
        avatar: '',
        uid: '',
        date_account: '',
        aboutme: ''
    });
    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>,
    });
    const [repliesCount, setRepliesCount] = useState(0);
    const [mediaHTML, setMedia] = useState(<></>);
    const [myInfos, setMyInfos] = useState([]);
    const [metadata, setMetaData] = useState(<></>);
    const [replies, setReplies] = useState([]);
    const [reqReply, setRequestReply] = useState([]);
    const [ruid, setRUID] = useState('');
    // Set like & share actions
    const [likeEvent, setLikeEvent] = useState('clike');
    const [likeClass, setLikeClass] = useState('unlike');
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);
    const [loading, setLoading] = useState(true);
    // Set blur
    const [blur, setBlur] = useState(false);

    // Set date format
    const sendDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const match_date = new Date(date);
    match_date.setHours(0, 0, 0, 0);

    const diff = Math.floor((today - match_date) / (1000 * 60 * 60 * 24));
    let diffDays = parseInt(diff);
        
    var date_format = "";
    // Get time since
    var langYear = "y";
    var langDay = "d";
    if(i18n.resolvedLanguage === "fr"){
        langYear = "a";
        langDay = "j"
    }

    if(i18n.resolvedLanguage === "fr"){
        switch (diffDays) {
            default:
                date_format = "il y a " + timeSince(sendDate, langYear, langDay);
        } 
    }else {
        switch (diffDays) {
            default:
                date_format = timeSince(sendDate, langYear, langDay) + " ago";
        }
    }

    // Set likes and shares count
    // -- For likes
    if (likes >= 1000 && likes < 10000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'K';
    } else if (likes >= 10000 && likes < 100000) {
        likes = likes.toString().substring(0, 2)+','+likes.toString().substring(2, 3)+'K';
    } else if (likes >= 100000 && likes < 1000000) {
        likes = likes.toString().substring(0, 3) + 'K';
    } else if (likes >= 1000000 && likes < 10000000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'M';
    } else if (likes >= 10000000 && likes < 100000000) {
        likes = likes.toString().substring(0, 2)+','+likes.toString().substring(2, 3)+'M';
    } else if (likes >= 100000000 && likes < 1000000000) {
        likes = likes.toString().substring(0, 3) + 'M';
    } else if (likes >= 1000000000) {
        likes = likes.toString().substring(0, 1)+','+likes.toString().substring(1, 2)+'B';
    }

    // GENERATE UID FOR COMMENT
    const generatedRUID = () => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/replies/generate/uid`
            }).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    /* -------
     * REPLIES
     * -------
     */
    // -- GET REPLIES
    const getReplies = async () => {
        if(reqReplyUid !== undefined){
            return new Promise((resolve, reject) => {
                axios.get(
                    `https://api.snot.fr/v${API_VERSION}/${uid}/replies/except/${reqReplyUid}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                ).then(res => {
                    resolve(res.data);
                }).catch((error) => {
                    reject(error);
                })
            })
        }else{
            return new Promise((resolve, reject) => {
                axios.get(
                    `https://api.snot.fr/v${API_VERSION}/${parent}/${uid}/reply`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                ).then(res => {
                    resolve(res.data);
                }).catch((error) => {
                    reject(error);
                })
            })
        }
    }

    // GET ONE REPLY
    const getReply = async () => {
        return new Promise((resolve, reject) => {
            axios.get(
                `https://api.snot.fr/v${API_VERSION}/one/${location.search.replace('?parent=', '')}/reply/${reqReplyUid}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            ).then(res => {
                resolve(res.data);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    // -- BUILD THEM
    const AllRepliesExceptUid = () => {
        if(replies.fetch !== null && replies.fetch !== undefined){
            return(
                <>
                    {replies.fetch.map((response) => {
                        return (
                            <Replies
                                data={{
                                    uid: response.uid,
                                    user: response.user,
                                    content: response.content,
                                    textonly: response.textonly,
                                    media: response.media,
                                    nsfw: response.nsfw,
                                    parent: response.parent,
                                    postUser: postUser,
                                    postUid: parent,
                                    date: response.reply_date,
                                    likes: response.likesCount,
                                }}
                                theme={theme}
                                logged={logged}
                            />
                        )
                    })}
                </>
            );
        }
    }

    // -- BUILD REQUESTED REPLY
    const RequestReply = () => {
        if(reqReply.fetch !== null && reqReply.fetch !== undefined){
            return(
                <>
                    {reqReply.fetch.map((response) => {
                        return (
                            <Replies
                                data={{
                                    uid: response.uid,
                                    user: response.user,
                                    content: response.content,
                                    textonly: response.textonly,
                                    media: response.media,
                                    nsfw: response.nsfw,
                                    parent: response.parent,
                                    postUser: postUser,
                                    postUid: parent,
                                    date: response.reply_date,
                                    likes: response.likesCount,
                                }}
                                theme={theme}
                                logged={logged}
                                select={true}
                            />
                        )
                    })}
                </>
            );
        }
    }

    // Show/Hide reply-ies -- FUNCTION
    const seeReply = (uid) => {
        var reply = document.querySelectorAll('#replyContainer.rContainer_'+uid);
        var text = document.querySelector('.linkReply_'+uid+' .replylink');
        var icon = document.querySelector('.linkReply_'+uid+' i');

        for (var x = 0; x < reply.length; x++) {
            if (reply[x].style.display == "none") {
                reply[x].style.display = 'block';

                text.textContent = "Cacher";
                icon.className = "fi fi-sr-angle-small-up";
            }else {
                reply[x].style.display = 'none';

                text.textContent = "Voir";
                icon.className = "fi fi-sr-angle-small-down";
            }
        }
    }

    // Know if [user] already liked [comment]
    const verifyLike = (userID) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/${userID}/actions/${uid}/verify/like_c`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data.count);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    /* -----------
     * BUILD MEDIA
     * -----------
     */
    const showMedia = () => {
        // -- If there's a link to a media
        const fileRgx = /\.[0-9a-z]+?=[?#]|\.(?:[\w]+)($|\?)/mi;
        const patterns = /https?:\/\/?[\da-z\.-]+\.[a-z\.]{2,6}[\w\S]*\/?/mis;
        let m1 = fileRgx.exec(textonly);
        let m2 = patterns.exec(textonly);

        if ((m1 = fileRgx.exec(textonly)) !== null) {
            m1.forEach((match) => {
                if(match !== '' && match !== '?'){
                    let fileExt = match.replace('?', '');
                    if (fileExt === '.png' || fileExt === '.jpg' || fileExt === '.jpeg' || fileExt === '.gif' || fileExt === '.bmp' || fileExt === '.tif' || fileExt === '.tiff' || fileExt === '.webp' || fileExt === '.mp4' || fileExt === '.mov' || fileExt === '.avi' || fileExt === '.wmf' || fileExt === '.flv' || fileExt === '.webm' || fileExt === '.mkv' || fileExt === '.ogg' || fileExt === '.vob' || fileExt === '.3gp' || fileExt === '.aa' || fileExt === '.aax' || fileExt === '.aac' || fileExt === '.aiff' || fileExt === '.alac' || fileExt === '.m4a' || fileExt === '.m4b' || fileExt === '.m4p' || fileExt === '.mp3' || fileExt === '.mpc' || fileExt === '.wav') {
                        if ((m2 = patterns.exec(textonly)) !== null) {
                            m2.forEach((matchImage) => {
                                let spostImage = matchImage;
                                
                                if (spostImage.includes('.mp4') || spostImage.includes('.mov') || spostImage.includes('.avi') || spostImage.includes('.wmf') || spostImage.includes('flv') || spostImage.includes('.webm') || spostImage.includes('.mkv') || spostImage.includes('.ogg') || spostImage.includes('.vob')) {
                                    setMedia(
                                        <div className="mediaSpost" style={{cursor: "default" }}>
                                            <Video videoRef={uid} videoSrc={spostImage} params={{ stopPropagation: true }} />
                                        </div>
                                    );
                                }else if (spostImage.includes('.png') || spostImage.includes('.jpg') || spostImage.includes('.jpeg') || spostImage.includes('.gif') || spostImage.includes('.bmp') || spostImage.includes('.tif') || spostImage.includes('.tiff') || spostImage.includes('.webp')) {
                                    setMedia(
                                        <div className="mediaSpost" onClick={openModal}>
                                            <img src={spostImage} alt={spostImage} title={spostImage} {...blur}
                                                onError={({ currentTarget }) => {
                                                    if(theme === "darkTheme"){
                                                        currentTarget.src = errorMediaDark;
                                                    }else{
                                                        currentTarget.src = errorMediaLight;
                                                    }
                                                }}
                                                referrerPolicy="no-referrer"
                                            />
                                        </div>
                                    );
                                }else if(spostImage.includes('.3gp') || spostImage.includes('.aa') || spostImage.includes('.aax') || spostImage.includes('.aac') || spostImage.includes('.aiff') || spostImage.includes('.alac') || spostImage.includes('.m4a') || spostImage.includes('.m4b') || spostImage.includes('.m4p') || spostImage.includes('.mpc') || spostImage.includes('.wav') || spostImage.includes('.mp3')) {
                                    setMedia(
                                        <Audio audioRef={uid} audioSrc={spostImage} params={{ stopPropagation: true, marginLeft: 0 }} />
                                    )
                                }
                            });
                        }
                    }
                }
            });
        }

        // -- If there is a media (no link)
        if(JSON.parse(media).length !== 0) {
            var arrayMedia = JSON.parse(media).toString();
            const params = new URL(arrayMedia).searchParams;

            if(acceptedVideo.includes(params.get('ext'))) {
                setMedia(
                    <div className="mediaSpost">
                        <Video videoRef={uid} videoSrc={arrayMedia} params={{ stopPropagation: true, nsfw: nsfw }} />
                    </div>
                );
            }else if(acceptedImage.includes(params.get('ext'))) {
                setMedia(
                    <div className="mediaSpost" {...(nsfw === true ? { style: { cursor: 'default' } }: {} )}>
                        <img src={arrayMedia} alt={arrayMedia} title={arrayMedia} {...(nsfw === true ? { style: { filter: 'blur(16px)', pointerEvents: "none" } }: {} )}
                            onError={({ currentTarget }) => {
                                if(theme === "darkTheme"){
                                    currentTarget.src = errorMediaDark;
                                }else{
                                    currentTarget.src = errorMediaLight;
                                }
                            }}
                            referrerPolicy="no-referrer"
                        />
                    </div>
                )
            }else if(acceptedAudio.includes(params.get('ext'))) {
                setMedia(
                    <Audio audioRef={uid} audioSrc={arrayMedia} params={{ stopPropagation: true, marginLeft: 0 }} />
                )
            }
        }
    }

    /* ----------------
     * BUILD LINK EMBED
     * ----------------
     */
    // Limit AJAX RATE
    var ajaxReqs = 0;
    var ajaxQueue = [];
    var ajaxActive = 0;
    var ajaxMaxConc = 3;
    function addAjax(obj) {
        ajaxReqs++;
        var oldSuccess = obj.success;
        var oldError = obj.error;
        var callback = function() {
            ajaxReqs--;
            if (ajaxActive === ajaxMaxConc) {
                $.ajax(ajaxQueue.shift());
            } else {
                ajaxActive--;
            }
        }
        obj.success = function(resp, xhr, status) {
            callback();
            if (oldSuccess) oldSuccess(resp, xhr, status);
        };
        obj.error = function(xhr, status, error) {
            callback();
            if (oldError) oldError(xhr, status, error);
        };
        if (ajaxActive === ajaxMaxConc) {
            ajaxQueue.push(obj);
        } else {
            ajaxActive++;
            $.ajax(obj);
        }
    }

    const linkEmbed = () => {
        const pattern = /https?:\/\/?[\da-z\.-]+\.[a-z\.]{2,6}[\w\S]*\/?/mis;
        let m1 = pattern.exec(textonly);
        if ((m1 = pattern.exec(textonly)) !== null) {
            m1.forEach((match) => {
                // ...
                var url = '';
                var title = '';
                var description = '';
                var safeURL = '';
                var imageData = <></>;

                addAjax({
                    'url': match,
                    'success': (res) => {
                        res = $.parseHTML(res);
                        $.each(res, function(i, el){
                            if(el.nodeName.toString().toLowerCase() === 'meta' && $(el).attr("property") !== null && typeof $(el).attr("property") !== "undefined"){
                                // -- Safe URL or not
                                if($(el).attr("property") === "og:url"){
                                    if ($(el).attr("content").includes("https://") && $(el).attr('content').includes('snot.fr')) safeURL = "safe";
                                }
                                // -- Image / Logo
                                if($(el).attr("property") === "og:image") {
                                    imageData = <img src={$(el).attr("content")} className="websiteImg" onClick={(e) => {openModal(e);changeMedia($(el).attr('content'), 'image');}} data-modal="seeMedia" referrerPolicy="no-referrer" />;
                                }

                                if($(el).attr("property") === "og:title") title = $(el).attr("content");
                                if($(el).attr("property") === "og:url") url = $(el).attr("content");
                                if($(el).attr("property") === "og:description") description = $(el).attr("content");
                            }
                        });
                    },
                    'complete': () => {
                        if($('#warningRedirection')){
                            $('#warningRedirection #btnWarnGo_wLB1').off('click');
                            $('#warningRedirection #btnWarnGo_wLB1').on('click', () => window.open(url));
                            // UI
                            $('#warningRedirection .warningLink_redirectWB1 .strong').text(url);
                        }

                        if(title !== '' && url !== ''){
                            setMetaData(<>
                                <div id="siteData_aCAvAUnB1" className={theme}>
                                    {imageData}
                                    <p className="title" onClick={(e) => {
                                        if(safeURL === ""){
                                            openModal(e)
                                        }else{
                                            window.open(url)
                                        }
                                    }} data-modal="warningRedirection">{title}</p>
                                    <p className="description">{description}</p>
                                </div>
                            </>)
                        }
                    },
                });
            });
        }
    }

    /* ----------------
     * ACTIONS FUNCTION
     * ----------------
     */
    const action = (e, uid, user, type) => {
        if (type === 'clike') {
            if(logged === 1){
                const likeCount = document.querySelector('.clike_'+uid+' span');
                let likes = likeCount.textContent;
                likes++;

                likeCount.innerHTML = likes;
                $('.clike_'+uid).addClass('liked');
                $('.clike_'+uid).removeClass('unlike');
                // Set dislike for click event
                setLikeEvent('cdislike');

                /* --------------
                * LIKE ANIMATION
                * --------------
                */
                $.rnd = (m,n) => {
                    m = parseInt(m);
                    n = parseInt(n);
                    return Math.floor( Math.random() * (n - m + 1) ) + m;
                }
                $.each($('.clike_'+uid), () => {
                    var confetticount = 8;
                    for(var i = 0; i <= confetticount; i++) {
                        $('.clike_'+uid).append('<span className="particle c' + $.rnd(1,2) + '" style="position:absolute;margin-top:' + $.rnd(0,15) + 'px;margin-left:' + $.rnd(0,25) + 'px;width:' + $.rnd(4,6) + 'px; height:' + $.rnd(3,4) + 'px;animation-delay: ' + ($.rnd(0,30)/10) + 's;"></span>');
                    }
                });
                
                // Add like
                axios.post(
                    `https://api.snot.fr/v${API_VERSION}/${myInfos.uid}/actions/${uid}/add/like_c`,
                    { post_user: user },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        }
                    }
                ).then(response => {
                    $('.clike_'+uid+' i').removeClass("fi-sr-heart");
                    $('.clike_'+uid+' i').addClass("fi-ss-social-network");
                    $('.clike_'+uid+' i').css({
                        transform: "scale(130%)",
                        marginTop: "-5px",
                        color: "#2E88BB",
                    });

                    setTimeout(() => {
                        $('.clike_'+uid+' i').css({
                            transform: "scale(100%)",
                            marginTop: "0px",
                        });
                    }, 250);
                    setTimeout(() => {
                        $('.clike_'+uid+' i').css({ color: "rgb(212, 87, 87)" });
                        $('.clike_'+uid+' i').removeClass("fi-ss-social-network");
                        $('.clike_'+uid+' i').addClass("fi-sr-heart");
                    }, 1000);
                    setTimeout(() => {
                        $.each($('.clike_'+uid+' span'), () => {
                            $('.clike_'+uid+' span').remove('.particle.c1');
                            $('.clike_'+uid+' span').remove('.particle.c2');
                        });
                    }, 1750);
                })
            }else{
                openModal(e);
            }
        }else if (type === 'cdislike') {
            if(logged === 1){
                const likeCount = document.querySelector('.clike_'+uid+' span');
                let likes = likeCount.textContent;
                likes--;

                likeCount.innerHTML = likes;
                $('.clike_'+uid).addClass('unlike');
                $('.clike_'+uid).removeClass('liked');
                // Like icon
                $('.clike_'+uid+' i').removeAttr('style');
                // Set like for click event
                setLikeEvent('clike');

                // Remove like
                axios.delete(
                    `https://api.snot.fr/v${API_VERSION}/${myInfos.uid}/actions/${uid}/remove/like_c`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        },
                        data: {post_user: user}
                    }
                )
            }else{
                openModal(e);
            }
        }
    }

    useEffect(() => {
        console.log(likes)

        // Retreive [user] infos
        Home.retreiveCreatorInfos(user).then((res) => {
            if(res !== "This user doesn't exist.") {
                setInfos({...res});

                // Set badges
                if (res.certified === "Yes" && res.staff === "Yes") {
                    setBadges({ certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
                }else{
                    if (res.staff === "Yes") {
                        setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
                    }
                    if (res.certified === "Yes") {
                        setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>});
                    }
                }
            }

            // Get comment count
            Home.getCountReplies(parent, uid).then((count) => setRepliesCount(count));
            // Set loading
            setLoading(false);
        })

        // RETREIVE MY INFOS
        if(logged === 1){
            retreiveInfos().then(res => {
                setMyInfos(res)
                // Verify like
                verifyLike(res.uid).then(resLike => {
                    if(resLike >= 1){
                        setLikeClass('liked');
                        setLikeEvent("cdislike");
                    }
                })
            });
        }
        // GENERATE REPLY UID
        generatedRUID().then(res => setRUID(res.uid))

        // For meta data
        linkEmbed();
        // For replies
        getReplies().then(res => setReplies(res))
        // Fetch requested reply
        if(reqReplyUid !== undefined){
            getReply().then(reply => setRequestReply(reply));
        }
    }, [logged])

    useEffect(() => {
        // NSFW
        if(nsfw === "Yes"){
            setBlur(true);
        }
        // For Media
        showMedia();
    }, [nsfw, loading])

    // EVENT LISTENERS
    useEffect(() => {
        if(loading === false){
            var shareComment = document.querySelector(".shareComment_"+uid);
            if(shareComment){
                shareComment.removeEventListener('click');
                shareComment.addEventListener('click', () => {
                    var spost = location.pathname;
                    if(location.pathname.includes('/reply/')){
                        spost = location.pathname.split('/reply')[0]
                    }else if(location.pathname.includes('/comment/')){
                        spost = location.pathname.split('/comment')[0]
                    }

                    copy("https://snot.fr"+spost+"/comment/"+uid);
                    // UI
                    $(".shareComment_"+uid+" span").text(t('general.copied'));
                    setTimeout(() => {
                        if($(".shareComment_"+uid)){
                            $(".shareComment_"+uid+' span').text(t('comment.share'))
                        }
                    }, 2000)
                })
            }
        }
    }, [loading])

    if(loading === false){
        return (
            <>
            
                <div id="comment" className={"com_"+uid} style={{ marginBottom: 20 }}>
                    <div className={"comment_sMAMVeB1 "+theme}>
                        <div className="profilePart_spostMAppMVB1" id="profilePart" style={{flex: 1, flexDirection: "row", alignItems: "flex-start", zIndex: 1, marginTop: -25}}>
                            <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme } style={{ width: 40, height: 40 }} onClick={() => navigate('/@'+infos.usertag.replace('.', '_').toLowerCase())}>
                                <img alt={"@"+infos.usertag.replace('.', '_').toLowerCase()} title={infos.username} src={infos.avatar} className={"avatar_pPSMaMvB1 ref_"+uid}
                                    onError={(e) => {
                                        if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                            e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp'
                                        }
                                    }}
                                style={loadingAvatar ? {display: "none"}: {}} onLoad={() => setLoadingAvatar(false)} referrerPolicy="no-referrer" />
                            </div>
                            <span id="username" {...(postUser === user ? { className: "creator" }: {})} style={{ marginLeft: 10 }} onClick={() => navigate('/@'+infos.usertag.replace('.', '_').toLowerCase())}>{infos.username}</span>
                            <span id="usertag" onClick={() => navigate('/@'+infos.usertag.replace('.', '_').toLowerCase())}>@{infos.usertag.replace('.', '_').toLowerCase()} {badges.certified}{badges.staff}{select === true ? <i className="fi fi-ss-star"></i>: <></>}</span>
                            {
                                blur === true ?
                                <div className="nsfwTag_comPcVB1">
                                    <i className="fi fi-rr-age-restriction-eighteen"></i>
                                    <span>NSFW</span>
                                </div>
                                : ''
                            }
                        </div>
                        <div className="datePart_spostMAppMVB1" style={{ display: "block", marginTop: -25 }}>{date_format}</div>
                    </div>
                    <div className={"spostContent_spostMAppMVB1 "+theme} style={{marginTop:50,marginLeft:50}}>
                        <span className="content">{HtmlToReact.Parser().parse(content)}</span>
                        {mediaHTML}
                        {metadata}
                    </div>
                    <div className={"countInteractions_comMAMVeB1 "+theme}>
                        <div id="like" className={"clike_"+uid+" "+likeClass} onClick={(e) => action(e, uid, user, likeEvent)} data-modal="loginModal">
                            <span data-modal="loginModal">{likes}</span>
                            <i className="fi fi-sr-heart" data-modal="loginModal" />
                        </div>
                        <div id="separator"></div>
                        <div id="comment" data-modal={"addReply_"+uid} onClick={(e) => openModal(e)}>
                            <span data-modal={"addReply_"+uid}>{repliesCount}</span>
                            <i className="fi fi-sr-comment-alt" data-modal={"addReply_"+uid} />
                        </div>
                        <div id="separator"></div>
                        <div id="share" className={"shareComment_"+uid}>
                            <span>{t('comment.share')}</span>
                            <i className="fi fi-sr-share" />
                        </div>
                    </div>
                    <div className={"moreInteractions_sMAMVeB1 comments "+theme} data-container={"moreBMC_"+uid} onClick={(e) => openMenu(e)}>
                        <div id="more" data-container={"moreBMC_"+uid}>
                            <i className="fi fi-sr-menu-dots-vertical" data-container={"moreBMC_"+uid}></i>
                        </div>
                    </div>
                    {
                        repliesCount >= 1 ?
                            (location.pathname.includes('/reply/') && location.search.replace('?parent=', '') === uid) ?
                            <div id="seeReplies" className={"linkReply_"+uid+" "+theme} onClick={() => seeReply(uid)} style={{ display: 'flex', alignItems: "center" }}>
                                <p style={{margin: 0}}><span className="replylink">{t('home.general.comment.reply.hide')}</span> <span className="count">{repliesCount}</span> {repliesCount === 1 ? t('home.general.comment.reply.reply'): t('home.general.comment.reply.replies')}</p>
                                <i className="fi fi-sr-angle-small-up" style={{ display: 'flex', marginLeft: 5 }}></i>
                            </div>
                            :
                            <div id="seeReplies" className={"linkReply_"+uid+" "+theme} onClick={() => seeReply(uid)} style={{ display: 'flex', alignItems: "center" }}>
                                <p style={{margin: 0}}><span className="replylink">{t('home.general.comment.reply.show')}</span> <span className="count">{repliesCount}</span> {repliesCount === 1 ? t('home.general.comment.reply.reply'): t('home.general.comment.reply.replies')}</p>
                                <i className="fi fi-sr-angle-small-down" style={{ display: 'flex', marginLeft: 5 }}></i>
                            </div>
                        : <></>
                    }
                </div>
                {/* ADD REPLY MODAL */
                    (loading === false && logged === 1 && myInfos.length !== 0) ?
                    <ReplyPopUp user={myInfos} post={parent} comment={{uid: uid, user: infos.usertag}} uid={ruid} theme={theme} />
                    : <></>
                }
                {/* EDIT MODAL */
                    (loading === false && logged === 1 && myInfos.length !== 0) ?
                    <EditCommentPopUp user={myInfos} post={{uid: uid, content: content, textonly: textonly, media: media, nsfw: nsfw}} theme={theme} />
                    : <></>
                }
                {/* MORE (REPLY) MENU */}
                <aside id={"moreBMC_"+uid} className={"menu "+theme} aria-hidden="true" aria-modal="false" style={{display:"none"}}>
                    <div className="menu-wrapper js-menu-stop">
                        <div className="actionsPart_spostMMVB1">
                            {
                                logged === 1 && myInfos.length !== 0 ?
                                    user !== myInfos.uid ?
                                        <>
                                        <EditComButton uid={myInfos.uid} com={uid} />
                                        <CopyComButton uid={uid} string={textonly} />
                                        <ReportComButton myUID={myInfos.uid} uid={uid} />
                                        <DeleteComButton uid={myInfos.uid} com={uid} />
                                        </>
                                    :
                                    <>
                                    <EditComButton uid="session" com={uid} />
                                    <CopyComButton uid={uid} string={textonly} />
                                    <DeleteComButton uid="session" com={uid} realUID={myInfos.uid} />
                                    </>
                                :
                                <CopyComButton uid={uid} string={textonly} />
                            }
                        </div>
                    </div>
                </aside>
                <RequestReply />
                <AllRepliesExceptUid />
            </>
        )
    }
}