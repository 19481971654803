import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import Cookies from "js-cookie";
import copy from 'copy-to-clipboard';
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import { API_VERSION } from "../../Functions";
import { retreiveInfos } from "../../Functions/Auth";

export const ShareMenu = ({user, postUser, uid, logged, theme, session}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    // Set data
    const [myInfos, setMyInfos] = useState({ usertag: '' });
    const [actionShare, setShareEvent] = useState('share');
    const [reposted, setReposted] = useState(false);
    const [remainingSeconds, setRemaining] = useState(5);

    // Know if [user] already shared [post]
    const verifyShare = (user) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/${user}/actions/${uid}/verify/share`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data.count);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    // Know if [user] already reposted [post]
    const verifyRepost = (user) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/${user}/repost/${uid}/verify`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data.count);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    /* ----------------
     * ACTIONS FUNCTION
     * ----------------
     */
    const action = (e, postUid, actionUser, type) => {
        if (logged === 1) {
            if(type === 'share' || type === "repost"){
                if(type === "share"){
                    const shareCount = document.querySelector('.share_'+uid+' span');
                    let shares = shareCount.textContent;
                    shares++;

                    shareCount.innerHTML = shares;
                    $('.share_'+uid).addClass('shared');
                    $('.share_'+uid).removeClass('unshare');
                    // Set shared for click event
                    setShareEvent('shared');
                    
                    var newType = type;
                    if(type === "repost") newType = 'share';

                    // Add share
                    axios.post(
                        `https://api.snot.fr/v${API_VERSION}/${actionUser}/actions/${postUid}/add/${newType}`,
                        { post_user: postUser },
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                            params: {
                                token: Cookies.get('loginToken'),
                                app: 'web'
                            }
                        }
                    ).then(response => {
                        // ADD REPOST
                        if(type === "repost"){
                            // ...
                            axios.post(
                                `https://api.snot.fr/v${API_VERSION}/repost/add`,
                                { user: actionUser, post: postUid },
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    params: {
                                        token: Cookies.get('loginToken'),
                                        uid: user,
                                        app: 'web'
                                    }
                                }
                            ).then(r => {
                                $('#shareBM #repost i').removeClass('fi-rr-arrows-retweet').addClass('fi-rr-check');
                                if(document.querySelector('.streakInfo_PcVB1.repost')){
                                    document.querySelector('.streakInfo_PcVB1.repost').className = "streakInfo_PcVB1 repost slide-in-bottom";
                                }
                                // For countdown
                                (function redirectionTime(remaining) {
                                    if(remaining === 0) {
                                        if(!$('.dayNumber_streakIPCVB1.cancel').hasClass('clicked')){
                                            navigate('/@'+myInfos.usertag.toLowerCase().replace('.', '_')+'/reposts');
                                        }
                                    }
                                    // ...
                                    setRemaining(remaining);
                                    // Timeout
                                    setTimeout(() => redirectionTime(remaining - 1), 1000);
                                })(5)
                            })
                        }
                    })
                }else{
                    // ADD REPOST
                    if(type === "repost"){
                        // ...
                        axios.post(
                            `https://api.snot.fr/v${API_VERSION}/repost/add`,
                            { user: actionUser, post: postUid },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                params: {
                                    token: Cookies.get('loginToken'),
                                    uid: user,
                                    app: 'web'
                                }
                            }
                        ).then(r => {
                            $('#shareBM #repost i').removeClass('fi-rr-arrows-retweet').addClass('fi-rr-check');
                            if(document.querySelector('.streakInfo_PcVB1.repost')){
                                document.querySelector('.streakInfo_PcVB1.repost').className = "streakInfo_PcVB1 repost slide-in-bottom";
                            }
                            // For countdown
                            (function redirectionTime(remaining) {
                                if(remaining === 0) {
                                    if(!$('.dayNumber_streakIPCVB1.cancel').hasClass('clicked')){
                                        navigate('/@'+myInfos.usertag.toLowerCase().replace('.', '_')+'/reposts');
                                    }
                                }
                                // ...
                                setRemaining(remaining);
                                // Timeout
                                setTimeout(() => redirectionTime(remaining - 1), 1000);
                            })(5)
                        })
                    }
                }
            }else if(type === "r_repost"){
                // REMOVE REPOST
                axios.delete(
                    `https://api.snot.fr/v${API_VERSION}/repost/remove`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            uid: user,
                            app: 'web'
                        },
                        data: { user: actionUser, post: postUid }
                    }
                ).then(r => {
                    // UI/UX
                    $('#shareBM #reposted i').removeClass('fi-rr-cross').addClass('fi-rr-arrows-retweet');
                    $('#shareBM #reposted span').text('Re-spost');
                    // CHANGE ID
                    $('#shareBM #reposted').attr('id', 'repost');
                    // EVENT CLICK
                    $('#shareBM #repost').off('click');
                    $('#shareBM #repost').on('click', (e) => action(e, uid, user, 'repost'))
                })
            }
        }
    }

    useEffect(() => {
        // Retreive my user infos
        if(logged === 1){
            retreiveInfos().then(res => {
                setMyInfos(res);
                // Check if [user] already shared
                verifyShare(res.uid).then(r => {
                    if(r >= 1) setShareEvent("shared");      
                })
                // Check if [user] already repost
                verifyRepost(res.uid).then(r => {
                    if(r >= 1) setReposted(true);
                })
            })
        }
    }, [logged])

    useEffect(() => {
        // EVENT LISTENERS
        // -- FOR INTEGRATE
        $('#integrate').off('click')
        $('#integrate').on('click', () => navigate('/integrate?url='+uid))
        // -- FOR COPY
        $('.shareMenu_'+uid).off('click')
        $('.shareMenu_'+uid).on('click', (e) => {
            copy('https://snot.fr'+location.pathname);
            // UI
            $(".shareMenu_"+uid+" span").text(t('general.copied'));
            setTimeout(() => {
                if($(".shareMenu_"+uid)){
                    $(".shareMenu_"+uid+' span').text(t('comment.share'))
                }
            }, 2000)
            // ...
            action(e, uid, user, actionShare);
        })
    }, [logged, user, actionShare])

    useEffect(() => {
        // -- FOR REPOST
        if($('#repost')){
            $('#repost').off('click');
            $('#repost').on('click', (e) => action(e, uid, user, 'repost'))
        }
        // -- FOR REPOST (REMOVE)
        if($('#reposted')){
            $('#reposted').off('click');
            $('#reposted').on('click', (e) => action(e, uid, user, 'r_repost'))
        }
    }, [logged, user, reposted, actionShare])

    return (
        <>
            {/* NOTIFICATION */}
            <div className="streakInfo_PcVB1 repost" style={{ transform: "translateY(1000px)", opacity: 0 }}>
                <div className={"streakInfo_containerMVB1 "+theme} style={{ width: 'auto' }}>
                    <p style={{ fontSize: 14, marginLeft: 20, marginRight: 10 }}>
                        Vous serez redirigé dans
                    </p>
                    <div className="dayNumber_streakIPCVB1" style={{ minWidth: "0", padding: '0 10px 0 15px', marginRight: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                        <span>{remainingSeconds}</span>
                    </div>
                    <div className="dayNumber_streakIPCVB1 cancel"
                        onClick={(e) => {
                            e.currentTarget.className = "dayNumber_streakIPCVB1 cancel clicked"
                            if(document.querySelector('.streakInfo_PcVB1.repost')){
                                document.querySelector('.streakInfo_PcVB1.repost').className = "streakInfo_PcVB1 repost slide-out-bottom";
                            }
                        }}
                        style={{ minWidth: "0", padding: '0 15px', backgroundColor: "#ff2323", cursor: "pointer", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    >
                        <span style={{ display: "flex", alignItems: "center" }}>{t('general.buttons.cancel')} <i className="fi fi-rr-cross-circle" style={{ display: "flex", marginLeft: 5 }}></i></span>
                    </div>
                </div>
            </div>
            {/* SHARE MENU */}
            <aside id="shareBM" className={"menu "+theme} aria-hidden="true" aria-modal="false" style={{display:"none"}}>
                <div className="menu-wrapper js-menu-stop">
                    <div className="actionsPart_spostMMVB1">
                        {
                            logged === 1 ?
                                session !== 'current' ?
                                    reposted === false ?
                                    <div id="repost"><span>Re-spost</span><i className="fi fi-rr-arrows-retweet"></i></div>
                                    :
                                    <div id="reposted"><span>{t('home.repost.remove')}</span><i className="fi fi-rr-cross"></i></div>
                                : ''
                            : ''
                        }
                        <div id="copy" className={"shareMenu_"+uid} data-modal="loginModal">
                            <span data-modal="loginModal">{t('home.general.share.copy')}</span>
                            <i className="fi fi-rr-copy-alt" data-modal="loginModal"></i>
                        </div>
                        <div id="integrate"><span>{t('home.general.share.integrate')}</span><i className="fi fi-rr-code-simple"></i></div>
                    </div>
                </div>
            </aside>
        </>
    )
}