import $ from 'jquery';
import * as marked from 'marked';
import { mangle } from 'marked-mangle';
import DOMPurify from 'dompurify';
import axios from 'axios';
import Cookies from 'js-cookie';
// import Functions
import { API_VERSION } from '../index';

// -- Only images, videos and audio
const acceptedImage = [
    // IMAGE
    "image/apng",
    "image/x-bmp",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/tiff",
    "image/x-icon",
    "image/avif",
    "image/svg+xml",
    "image/webp",
    "image/heif",
    "image/heif-sequence",
    "image/heic",
    "image/heic-sequence",
    "image/jp2",
    "image/jpx",
    "image/jpm",
    "image/avci",
    "image/aces",
]
const acceptedVideo = [
    "video/mj2",
    "video/mp4",
    "video/mp2t",
    "video/x-msvideo",
    "video/mpeg",
    "video/ogg",
    "video/webm",
    "video/3gpp",
    "video/3gpp2",
    "video/3gpp-tt",
    "video/AV1",
    "video/H264",
    "video/matroska",
    "video/raw",
]
const acceptedAudio = [
    'audio/3gpp',
    'audio/3gpp2',
    'audio/aac',
    'audio/ac3',
    'audio/matroska',
    'audio/mhas',
    'audio/midi-clip',
    'audio/MPA',
    'audio/mp4',
    'audio/MP4A-LATM',
    'audio/mpa-robust',
    'audio/mpeg',
    'audio/mpeg4-generic',
    'audio/ogg',
    'audio/webm',
    "audio/wav",
]

// Generate UID
function startuid() {
    let result = '';
    const characters = '123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}
function makeid(length) {
    let result = '';
    const characters = '0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

// Upload file
const uploadFile = ({file: {fileName, dataObj, fileExt}, myUID, keyPM}) => {
    var fileObject = fetch(dataObj) 
    .then(r => r.blob())
    .then(blob => {
        fileObject = new File([blob], fileName, {type: fileExt});
        // ...
        const formData = new FormData();
        formData.append("file", fileObject, fileName);
        // UPLOAD
        axios.post(`https://api.snot.fr/v${API_VERSION}/upload/chat`, formData, {
            params: {
                token: Cookies.get('loginToken'),
                uid: myUID,
                key: keyPM,
                app: 'web'
            }
        });
    })
}

// Send a message
var uuid = startuid() + makeid(10);

const sendMessage = ({user, friend, key, action, reply: {content, uid}, pusher: { presence }, theme}) => {
    // ! START OF MARKED CONFIG !
    const renderer = {
        link(href) {
            return `<a href="${href}" target="_blank" rel="noreferrer" title="${href.replaceAll('mailto:', '')}">${href.replaceAll('mailto:', '')}</a>`;
        }
    };

    marked.use({
        breaks: true,
        renderer,
    }, mangle());
    // ! END OF MARKED CONFIG !

    // Send message
    var message = DOMPurify.sanitize(marked.parseInline($('.textArea_bBAChatMVB1').html().trim()), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']});
    var textonly = DOMPurify.sanitize($('.textArea_bBAChatMVB1').text().trim(), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']});
    
    // Attach files
    var files = [];
    for (let i = 0; i < localStorage.getItem('file_length'); i++) {
        var fileList = JSON.parse(localStorage.getItem('file_'+i));
        // Replace extension
        var newExt = fileList.type;
        var newExtAPI = fileList.name.substr(fileList.name.lastIndexOf('.')+1, fileList.name.length) || fileList.name;
        var fileWithoutExt = fileList.name.replace(/\.[^\/.]+$/, "");
        var replacedExtension = fileWithoutExt+'.'+newExtAPI;

        if (acceptedImage.includes(fileList.type)) {
            if(fileList.type !== "image/svg+xml"){
                replacedExtension = fileWithoutExt+".svg";
                newExt = "image/svg+xml";
                newExtAPI = "svg";
            }else{
                replacedExtension = fileWithoutExt+".webp";
                newExt = "image/webp";
                newExtAPI = "webp";
            }
        }else if (acceptedVideo.includes(fileList.type)) {
            replacedExtension = fileWithoutExt+".webm";
            newExt = "video/webm";
            newExtAPI = "webm";
        }else if (acceptedAudio.includes(fileList.type)) {
            replacedExtension = fileWithoutExt+".aac";
            newExt = "audio/aac";
            newExtAPI = "aac";
        }
        // Push file
        files.push('https://api.snot.fr/v'+API_VERSION+'/content/chat/'+user.uid+'/'+fileWithoutExt+"?ext="+newExtAPI+"&key="+key);
        // -- Upload file
        uploadFile({ file: {fileName: replacedExtension, dataObj: fileList.preview, fileExt: newExt}, myUID: user.uid, keyPM: key});
    }

    // For reply
    var reply = "No";
    var replyContent = null;
    var replyUID = null;
    if (action === "reply") {
        reply = "Yes";
        if (content === undefined || content === "") {
            replyContent = "<i class=\"fi fi-rr-circle-microphone\"></i>";
        }else {
            replyContent = DOMPurify.sanitize(marked.parseInline(content), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']});
            replyContent = replyContent.replaceAll(" <img", "&nbsp;<img").replaceAll("\">", "\" style=\"width:15px;height:20px;\">&nbsp;");
        }
        replyUID = uid;
    }

    message = message.replaceAll(" <img", "&nbsp;<img").replaceAll("\"> ", "\">&nbsp;");

    // Color for content
    var contentColor = "707070";
    if(theme === "darkTheme") contentColor = '9A9A9A'; 

    // Message preview
    var messageContainer = "";
    if (files.length !== 0 && reply === "No") {
        var filesAttach = "";
        for(let i = 0; i < localStorage.getItem('file_length'); i++) {
            var fileList = JSON.parse(localStorage.getItem('file_'+i));

            if (acceptedImage.includes(fileList.type)) {
                filesAttach += '<div class="attachFiles_chatFMMVB1"><img src="'+fileList.preview+'" title="'+fileList.name+'" style="filter: opacity(.25);" /></div>';
            }else if (acceptedVideo.includes(fileList.type)) {
                filesAttach += '<div class="attachFiles_chatFMMVB1"><img src="https//api.snot.fr/v'+API_VERSION+'/content/video_icon?ext=png" title="'+fileList.name+'" style="filter: opacity(.25);" /></div>';
            }else if (acceptedAudio.includes(fileList.type)) {
                filesAttach += `
                <div class="attachFiles_chatFMMVB1" style="max-width:450px;cursor:default;">
                    <div class="audioContainer_audioAMB1 ${theme}">
                        <div class="play-pause-button audio"><i class="fi fi-sr-play"></i></div>
                        <div class="timeCurrentDuration_bBarB1">0:00/0:00</div>
                        <div class='timeCode_container'>
                            <div class="progressBar_bBarB1" style="cursor:default"></div>
                        </div>
                        <div class="volumeControl_bBarB1" style="width: 50px;">
                            <div class="volume-icon" style="width: 50px;height: 50px;cursor:default;display:flex;align-items: center;justify-content:center;margin-left:0;margin-top: -10px">
                                <i class="fi fi-ss-volume" style="font-size: 18px;display: flex;"></i>
                            </div>
                        </div>
                    </div>
                </div>`;
            }else if (fileList.name.includes('.txt')) {
                filesAttach += '<div class="attachFiles_chatFMMVB1"><img src="https//api.snot.fr/v'+API_VERSION+'/content/txt_icon?ext=png" title="'+fileList.name+'" style="filter: opacity(.25);"></div>';
            }else if (fileList.name.includes('.pdf')) {
                filesAttach += '<div class="attachFiles_chatFMMVB1"><img src="https//api.snot.fr/v'+API_VERSION+'/content/pdf_icon?ext=png" title="'+fileList.name+'" style="filter: opacity(.25);"></div>';
            }else {
                filesAttach += '<div class="attachFiles_chatFMMVB1"><img src="https//api.snot.fr/v'+API_VERSION+'/content/base_file?ext=png" title="'+fileList.name+'" style="filter: opacity(.25);"></div>';
            }
        }
        messageContainer = '<div class="message_pChatFMMVB1 preview uid_'+uuid+' '+theme+'"><div class="profilePart_msgPCMMVB1"><img alt="'+user.usertag.toLowerCase().replace('.', '_')+'"" title="'+user.username+'" src="'+user.avatar+'" /><span id="username">'+user.username+'</span><span id="usertag">@'+user.usertag.toLowerCase().replace('.', '_')+'</span></div><div class="datePart_msgPCMMVB1"><span id="date">Envoi en cours...</span></div><div class="contentPart_msgPCMMVB1"><span id="content" style="color: #'+contentColor+'">'+message+'</span></div><div class="attachFiles_mainContainer">'+filesAttach+'</div></div>';
    }else if (files.length === 0 && reply === "Yes") {
        messageContainer = '<div class="message_pChatFMMVB1 preview uid_'+uuid+' '+theme+'"><div class="profilePart_msgPCMMVB1"><img alt="'+user.usertag.toLowerCase().replace('.', '_')+'" title="'+user.username+'" src="'+user.avatar+'" /><span id="username">'+user.username+'</span><span id="usertag">@'+user.usertag.toLowerCase().replace('.', '_')+'</span></div><div class="datePart_msgPCMMVB1"><span id="date">Envoi en cours...</span></div><div class="contentPart_msgPCMMVB1"><div class="replyContainer_msgPCMMVB1 '+theme+'"><div class="profilePart_msgPCMMVB1"><div class="avatarReply"><img alt="'+user.usertag.toLowerCase().replace('.', '_')+'" title="'+user.username+'" src="'+user.avatar+'" /></div><div class="usernameReply"><span id="username" style="display:flex;">'+user.username+'</span></div><div class="usertagReply"><span id="usertag" style="display:flex;">@'+user.usertag.toLowerCase().replace('.', '_')+'</span></div></div><div class="contentPart_msgPCMMVB1"><div class="textReply"><span id="content">'+replyContent+'</span></div></div></div><span id="content" style="color:#'+contentColor+';">'+message+'</span></div></div>';
    }else if (files.length === 0 && (action === undefined || action === null)) {
        messageContainer = '<div class="message_pChatFMMVB1 preview uid_'+uuid+' '+theme+'"><div class="profilePart_msgPCMMVB1"><img alt="'+user.usertag.toLowerCase().replace('.', '_')+'" title="'+user.username+'" src="'+user.avatar+'" /><span id="username">'+user.username+'</span><span id="usertag">@'+user.usertag.toLowerCase().replace('.', '_')+'</span></div><div class="datePart_msgPCMMVB1"><span id="date">Envoi en cours...</span></div><div class="contentPart_msgPCMMVB1"><span id="content" style="color:#'+contentColor+'">'+message+'</span></div></div>';
    }
    
    // UI / UX
    document.querySelectorAll('#file_uploaded').forEach(filesAll => filesAll.remove());

    $("#reply_edit").css({display:"none", marginBottom: "auto"});
    $("#filesContainer").css({border: 'none'});
    $('.textArea_bBAChatMVB1').text('').css({height: 50});

    // -- APPEND MESSAGE
    $('.mainPart_friendsMMVB1').append(messageContainer);
    // -- SCROLL TO MESSAGE (AUTO SCROLL)
    document.querySelector('.mainPart_friendsMMVB1').scrollTo(0, document.body.scrollHeight);

    axios.post(
        `https://api.snot.fr/v${API_VERSION}/chat/${key}/send`,
        { sender: user.uid, receiver: friend.uid, content: message, textonly: textonly, reply: reply, rcontent: replyContent, ruid: replyUID, files: JSON.stringify(files) },
        {
            headers: {
                "Content-Type": "application/json"
            },
            params: {
                token: Cookies.get('loginToken'),
                app: "web"
            },
        }
    )
    .then(res => {
        $('.message_pChatFMMVB1.preview.uid_'+uuid).remove();
        // RESET THESE SETTINGS
        files = [];
        replyContent = null;
        replyUID = null;
        reply = "No";
        // DELETE LOCAL STORAGE VAR
        if(localStorage.getItem('file_length')){
            for(let i = 0; i < localStorage.getItem('file_length'); i++){
                if(localStorage.getItem('file_'+i)){
                    localStorage.removeItem('file_'+i);
                }
            }
            // ...
            localStorage.removeItem('file_length');
        }
        // -- SEND NOTIFICATION
        if (friend.uid !== user.uid) {
            if (presence.members.get(friend.uid) !== null) {
                axios({
                    method: 'POST',
                    url: `https://api.snot.fr/v${API_VERSION}/chat/${key}/notification/sound`,
                    params: {
                        token: Cookies.get('loginToken'),
                        app: 'web'
                    },
                    data: { user: user.uid, friend: friend.uid, uid: res.uid }
                })
            }else{
                axios.post(
                    `https://api.snot.fr/v${API_VERSION}/chat/${key}/notification`,
                    { user: user.uid, friend: friend.uid, uid: res.uid },
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: "web"
                        }
                    }
                )
            }
        }
    })
}

export {
    sendMessage
}