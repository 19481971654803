import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import Cookies from "js-cookie";
import axios from "axios";
// import Components
import { Appbar, Navbar } from '../../../Components/Common';
import { VerticalMenu } from "../../../Components/Pages/Settings";
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import { API_VERSION } from "../../../Components/Functions";
// import Auth
import { retreiveInfos } from "../../../Components/Functions/Auth";

const SettingsAppearance = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // Set theme
    const [theme, setTheme] = useState('darkTheme');
    const [selected, setSelected] = useState({
        light: '',
        dark: '',
        sync: '',
        check: <div className="themeSelected"><i className="fi fi-rr-check"></i></div>
    })
    const [infos, setInfos] = useState([]);

    // -- VERIFY [user] IS LOGGED (OR NOT)
    useEffect(() => {
        if(Cookies.get('logged') !== '1'){
            window.location.replace('/login');
        }
    }, [])

    // GLOBAL FUNCTIONS
    const save = (user, theme) => {
        var [light, dark, sync] = "";
        if(theme === "light"){ light = "selected" }
        else if(theme === "dark"){ dark = "selected" }
        else if(theme === "sync"){ sync = "selected" }
        // Set selected
        setSelected({
            ...selected,
            light: light,
            dark: dark,
            sync: sync
        });
        // Set theme
        localStorage.setItem('theme', theme)
        Cookies.set('_theme', theme+"_theme");
        // Edit in DB
        axios.put(
            `https://api.snot.fr/v${API_VERSION}/${user}/settings/edit?token=${Cookies.get('loginToken')}&app=web`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                data: { setting: "setting_theme", term: theme+"_theme" }
            }
        )
    }

    useEffect(() => {
        // Set theme + set selected
        if(localStorage.getItem("theme")){            
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }else{
                setTheme('darkTheme');
            }
        }

        // Set selected
        var [light, dark, sync] = "";
        if(Cookies.get('_theme') === "light_theme"){ light = "selected" }
        else if(Cookies.get('_theme') === "dark_theme"){ dark = "selected" }
        else if(Cookies.get('_theme') === "sync_theme") { sync = "selected" }
        // Set selected
        setSelected({
            ...selected,
            light: light,
            dark: dark,
            sync: sync
        });
    }, [localStorage.getItem('theme')])

    useEffect(() => {
        retreiveInfos().then(res => {
            setInfos(res);
        })
    }, [])

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://snot.fr/settings/appearance" />
                <meta name="robots" content="noindex,follow"/>
                <meta property="og:title" content={t('title.settings.appearance')}/>
                <meta property="og:url" content={"https://snot.fr/settings/appearance"+window.location.search}/>
                <meta name="twitter:title" content={t('title.settings.appearance')}/>
                <title>{t('title.settings.appearance')}</title>
            </Helmet>
            <Appbar />
            <Navbar />
            <VerticalMenu theme={theme} />
            <main style={{overflow:"auto",height:"100%",position: "absolute",width: "100%"}}>
                <div id="settingsMain">
                    <section className={"topPart_setAccMVB1 "+theme}>
                        <h1 id="title">{t('settings.appearance.titleStrong')}</h1>
                        <div className={"saveBtnContainer_setMain-pcVB1 noButton "+theme}>
                            <span className="text_saveBtnC-sMpcVB1">{t('settings.appearance.autosave')}</span>
                        </div>
                    </section>
                    <section className={"settingsPart_setMainMVB1 "+theme}>
                        <div id="AccountSettings" className="privacy appearance" style={{marginTop:-20}}>
                            <div className="theme_appSetMVB1" style={{borderBottom: 0}}>
                                <h1 className="title_appSetPcVB1" style={{marginBottom: 0}}>{t('settings.appearance.theme.title')}</h1>
                                <h2 className="info_appSetPcVB1">{t('settings.appearance.theme.description.simpleText')} <span style={{fontWeight: "600"}}>{t('settings.appearance.theme.description.mediumText')}</span>.</h2>
                                <div className="themeContainer_appSetMVB1">
                                    <div id="theme" className={"themeLight "+selected.light} onClick={() => save(infos.uid, 'light')}>
                                        { selected.light === "selected" ? selected.check: '' }
                                    </div>
                                    <span id="description">{t('settings.appearance.theme.light')}</span>
                                    <div id="theme" className={"themeDark "+selected.dark} onClick={() => save(infos.uid, 'dark')}>
                                        { selected.dark === "selected" ? selected.check: '' }
                                    </div>
                                    <span id="description" className="dark">{t('settings.appearance.theme.dark')}</span>
                                    <div id="theme" className={"themeSync "+selected.sync} data-tooltip={t('settings.appearance.theme.syncTip')} data-tooltip-location="top" style={{ zIndex: 0 }} onClick={() => save(infos.uid, 'sync')}>
                                        <div id="syncIcon"><i className="fi fi-rr-refresh"></i></div>
                                        { selected.sync === "selected" ? selected.check: '' }
                                    </div>
                                    <span id="description" className="sync">Sync</span>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
       </>
    )
}

export default SettingsAppearance;