import {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from 'axios';
// import i18next
import { useTranslation, Trans } from 'react-i18next';
import i18n from "../../i18n/i18n";
// import Functions
import { API_VERSION } from '../Functions';

const TrendsContainer = ({theme}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // Set data
    const [data, setData] = useState([]);
    
    // GET TRENDY THEMES
    const getTrendyThemes = async () => {
        const trends = await axios.get(
            `https://api.snot.fr/v${API_VERSION}/trends`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    theme: theme.replace('Theme', ''),
                    lang: i18n.resolvedLanguage,
                }
            }
        ).then(res => res.data.result);

        setData(trends)
    }

    /* -------------
     * TRENDS THEMES
     * -------------
     */
    // Capitalize first letter
    function ucFirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const Trends = () => {
        if(data === null || data === undefined){
            return (
                <div className="trendContainer_mDrawerMVB1">
                    <p className="text_frFPB1">
                        <span className="texton_frPFPB1">Ah...</span><br/>
                        <Trans i18nKey={'general.trends.error.description'}></Trans>
                    </p>
                </div>
            )
        }else{
            return (<>
                {data.map(trendy => {
                    // Style
                    var trendBack = "transparent";
                    if(trendy === "popular") trendBack = "linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)";
                    else if(trendy === "news") trendBack = "#4077E0";
                    else if(trendy === "advertising") trendBack = "#40E0D0"
                    // Traduction
                    var trendName = trendy;
                    if(i18n.resolvedLanguage === "fr"){
                        if(trendy === "popular") trendName = "populaire";
                        else if(trendy === "news") trendName = "actualités";
                        else if(trendy === "advertising") trendName = "publicité";
                    }

                    return (
                        <div className="trendContainer_mDrawerMVB1" style={{ background: trendBack }} onClick={() => navigate('/tags?t='+trendy)}>
                            <p className="titleContainer_tCMVB1">#{ucFirst(trendName)}</p>
                        </div>
                    )
                })}
            </>)
        }
    }

    useEffect(() =>{
        // Fetch trendy themes
        getTrendyThemes();
    }, [])

    return (
        <>
        <main id="trendMainContainer">
            <section className={"trendMenu_pcverSB1 "+theme}>
                <h1>{t('general.trends.title')}</h1>
                <div className={"separator_fBmVeB1 "+theme}></div>
                <div className={"trends_fBmVeB1 "+theme}>
                    <Trends />
                </div>
                <footer className={"legalMentions_tMpVB1 "+theme}>
                    <p>
                        <a href={i18n.resolvedLanguage === "fr" ? "https://about.snot.fr/fr/tos": "https://about.snot.fr/en/tos"} hrefLang={i18n.resolvedLanguage} title={t('footer.tos')}>{t('footer.tos')}</a><br/>
                        <a href={i18n.resolvedLanguage === "fr" ? "https://about.snot.fr/fr/privacy": "https://about.snot.fr/en/privacy"} hrefLang={i18n.resolvedLanguage} title={t('footer.privacy')}>{t('footer.privacy')}</a><br/>
                        <a href={i18n.resolvedLanguage === "fr" ? "https://about.snot.fr/fr/content": "https://about.snot.fr/en/content"} hrefLang={i18n.resolvedLanguage} title={t('footer.content')}>{t('footer.content')}</a><br/>
                        <a href={i18n.resolvedLanguage === "fr" ? "https://about.snot.fr/fr/branding": "https://about.snot.fr/en/branding"} hrefLang={i18n.resolvedLanguage} title={t('footer.branding')}>{t('footer.branding')}</a><br/><br/>
                        SNOT © { new Date().getFullYear() }.
                    </p>
                </footer>
            </section>
        </main>
        </>
    )
}

export default TrendsContainer;