// import CSS
import '../../../css/color-picker/styles.css';
// import Modules
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import DOMPurify from 'dompurify';
import ColorPicker, {any_to_hex} from '@yaireo/color-picker'
import position from '@yaireo/position';
import Cookies from 'js-cookie';
import * as marked from 'marked';
import { mangle } from 'marked-mangle';
// import i18next
import { useTranslation } from "react-i18next";
import i18n from '../../../i18n/i18n';
// import Components
import ImageCropper from '../../Common/ImageCrop';
// import Functions
import { API_VERSION } from '../../Functions';

const EditProfile = ({user, about, theme}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set data
    const [form, setForm] = useState({
        username: user.username,
        about: about.aboutme,
        about_text: about.aboutme_text,
        links: {
            link_1: user.links.link_1,
            link_2: user.links.link_2,
            link_3: user.links.link_3
        },
        birthdate: user.birthdate
    })
    // Set Birthdate & Links (remaining)
    const [defaultBirthdate, setDefaultBirthdate] = useState(null);
    const [remainingLinks, setRemainingLinks] = useState('0/3');
    // Set profile picture crop
    const [imageToCrop, setImageToCrop] = useState(undefined);
    const [croppedImage, setCroppedImage] = useState(undefined);
    // Set Errors
    const [errorLinks, setErrorLinks] = useState('');

    // Validate URL function
    const isValidUrl = (urlString) => {
        var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
        '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
        return !!urlPattern.test(urlString);
    }

    /* LINKS FUNCTION */
    const link = (type, link) => {
        var linkSelected = document.getElementById(link).value.trim();
        var linkTwo = document.getElementById('linkTwo');
        var linkThree = document.getElementById('linkThree');
        var linkRemove = document.querySelector('#'+link+'~.deleteLink_fPEPMVB1');
            
        if (type === "remove") {
            if (link === "link_one" && linkTwo.style.display !== "none" && document.getElementById("link_two").value === "") {
                document.getElementById("link_one").value = "";
                linkRemove.style.display = "none";
                linkTwo.style.display = "none";
                setRemainingLinks('0/3')
            }else if (link === "link_one" && linkTwo.style.display !== "none"  && linkThree.style.display !== "none" && document.getElementById("link_two").value !== "" && document.getElementById("link_third").value === "") {
                document.getElementById("link_one").value = document.getElementById("link_two").value;
                linkRemove.style.display = "block";
                document.getElementById("link_two").value = "";
                document.querySelector('#link_two~.deleteLink_fPEPMVB1').style.display = "none";
                linkThree.style.display = "none";
                setRemainingLinks('1/3')
            }else if (link === "link_one" && linkThree.style.display !== "none" && document.getElementById("link_third").value !== "") {
                document.getElementById("link_one").value = document.getElementById("link_two").value;
                document.getElementById("link_two").value = document.getElementById("link_third").value;
                linkRemove.style.display = "block";
                document.getElementById("link_third").value = "";
                document.querySelector('#link_third~.deleteLink_fPEPMVB1').style.display = "none";
                setRemainingLinks('2/3')
            }else if (link === "link_two" && linkTwo.style.display !== "none" && linkThree.style.display !== "none" && document.getElementById("link_two").value !== "" && document.getElementById("link_third").value === "") {
                document.getElementById("link_two").value = "";
                linkRemove.style.display = "none";
                linkThree.style.display = "none";
                setRemainingLinks('1/3')
            }else if (link === "link_two" && linkThree.style.display !== "none" && document.getElementById("link_third").value !== "") {
                document.getElementById("link_two").value = document.getElementById("link_third").value;
                linkRemove.style.display = "block";
                document.getElementById("link_third").value = "";
                document.querySelector('#link_third~.deleteLink_fPEPMVB1').style.display = "none";
                setRemainingLinks('2/3');
            }else if (link === "link_third") {
                document.getElementById("link_third").value = "";
                linkRemove.style.display = "none";
                setRemainingLinks('2/3');
            }
        }else if (type === "add" && linkSelected !== "") {
            if(!isValidUrl(linkSelected)){
                setErrorLinks('Your link is invalid.');
                if(i18n.resolvedLanguage === "fr"){
                    setErrorLinks('Le lien est invalide.');
                }
            }else{
                if(!(/^((http|https|ftp):\/\/)/.test(linkSelected))){
                    setErrorLinks('');
                    linkRemove.style.display = "block";
                    if (link === "link_one") {
                        setRemainingLinks('1/3')
                        document.getElementById('linkTwo').style.display = "block";
                    }else if (link === "link_two") {
                        setRemainingLinks('2/3')
                        document.getElementById('linkThree').style.display = "block";
                    }else if (link === "link_third") {
                        setRemainingLinks('3/3')
                        document.getElementById('linkThree').style.display = "block";
                    }
                }else{
                    let m;
                    if ((m = /^((http|https|ftp):\/\/)/.exec(linkSelected)) !== null) {
                        m.forEach((match, groupIndex) => {
                            setErrorLinks('Please, remove the "'+match.toUpperCase()+'://".');
                            if(i18n.resolvedLanguage === "fr"){
                                setErrorLinks('Veuillez retirer le "'+match.toUpperCase()+'://".');
                            }
                        });
                    }
                }
            }
        }
    }
    /* END OF LINKS FUNCTION */

    // SAVE PROFILE FUNCTION
    const saveProfile = () => {
        var errorDiv = document.querySelector('#mBLogged.error');
            errorDiv.style.top = "140px";
            errorDiv.style.zIndex = "9999";
        var errorText = document.querySelector('#textN_nMAPPb1 p');
        var success = document.getElementById("success");

        if(form.username === null || form.username === ""){
            errorText.textContent = t('profile.edit.error.enterUsername');
            // UI
            if (errorDiv.style.display === "none") errorDiv.style.display = "block";
            setTimeout(() => {
                if (errorDiv.style.display === "block") errorDiv.style.display = "none";
            }, 5000);
        }else if(form.username.length > 50){
            errorText.textContent = t('profile.edit.error.maximumLengthUsername');
            // UI
            if (errorDiv.style.display === "none") errorDiv.style.display = "block";
            setTimeout(() => {
                if (errorDiv.style.display === "block") errorDiv.style.display = "none";
            }, 5000);
        }else{
            /* ----------
             * FOR MARKED
             * ----------
             */
            // -- Override function
            const renderer = {
                link(href) {
                    return `<a href="${href}" target="_blank" rel="noreferrer" title=${href.replaceAll('mailto:', '')}>${href.replaceAll('mailto:', '')}</a>`;
                }
            };
            // -- Set options
            marked.use({
                breaks: true,
                renderer
            }, mangle());

            // Hide notification (error)
            if (errorDiv.style.display === "block") errorDiv.style.display = "none";

            // Verify username is not = to actual username
            if(form.username !== user.username){
                axios.put(
                    `https://api.snot.fr/v${API_VERSION}/${user.uid}/edit/username`,
                    { string: form.username },
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        }
                    }
                ).then(res => {
                    if(res.data.error !== null || res.data.error !== undefined){
                        errorText.textContent = t(res.data.error);
                        // UI
                        if (errorDiv.style.display === "none") errorDiv.style.display = "block";
                        setTimeout(() => {
                            if (errorDiv.style.display === "block") errorDiv.style.display = "none";
                        }, 5000);
                    }else {
                        success.style.display = "block";
                        success.style.right = "180px";
                    }
                })
            }

            // All (except username, profile picture and banner)
            // -- About config
            var aboutMarkdown = DOMPurify.sanitize(marked.parseInline(form.about), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']});
            if(form.about === null || form.about === "" || form.about === undefined) aboutMarkdown = "No bio yet.";
            else if(form.about === about.aboutme) aboutMarkdown = about.aboutme;
            // -- Birthdate config
            var newBirthdate = form.birthdate;
            if(form.birthdate === user.birthdate) newBirthdate = null;

            axios.put(
                `https://api.snot.fr/v${API_VERSION}/${user.uid}/edit/all`,
                { about: { markdown: aboutMarkdown, textonly: DOMPurify.sanitize(form.about_text, {USE_PROFILES: {html:false, svg: false, mathMl: false}}) }, links: { link_1: form.links.link_1, link_2: form.links.link_2, link_3: form.links.link_3 }, birthdate: newBirthdate },
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        app: 'web'
                    }
                }
            ).then(res => {
                // Success info
                success.style.display = "block";
                success.style.right = "180px";
                // Reload
                if(window.location.search.includes('?open=edit')){
                    window.location.replace(window.location.pathname);
                }else{
                    window.location.reload();
                }
            })
        }
    }

    // CHANGE PROFILE PICTURE
    // -- Remove
    const removeProfile = (type) => {
        if (type === "picture") {
            var preview = document.getElementById('newAvatar');
            preview.src = "https://api.snot.fr/v"+API_VERSION+"/content/icon_profile?ext=webp";
            // UI
            document.getElementById('newAvatarCrop').style.display = "none";
            preview.style.display = "block";
            // Change click event
            $('.submitBtn_eIMVB1.profilePicture').off('click').on('click', (e) => {
                axios.put(`https://api.snot.fr/v${API_VERSION}/${user.uid}/edit/avatar`,
                    {string: 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp'},
                    {
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: user.uid,
                        app: 'web'
                    }
                }).then(res => window.location.reload())
            })
        }else {
            $('.myColor').css({'--color': "#18181C"});
            document.querySelector('.myColor').value = "#18181C";
        }
    }

    // -- Add
    const fileTypes = [
        "image/apng",
        "image/x-bmp",
        "image/bmp",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/tiff",
        "image/x-icon",
        "image/avif",
        "image/svg+xml",
        "image/webp",
        "image/heif",
        "image/heif-sequence",
        "image/heic",
        "image/heic-sequence",
        "image/jp2",
        "image/jpx",
        "image/jpm",
        "image/avci",
        "image/aces",
        "image/gif",
    ];

    function validFileType(file) { return fileTypes.includes(file.type);}

    // ...
    useEffect(() => {
        // COLOR PICKER
        const VALUE_CHANGE_SAMPLING_DURATION = 1000;
        setTimeout(init, 200)

        function init(){
            const colorInputs = document.querySelectorAll('.myColor')

            colorInputs.forEach(colorInput => {
                const observerCallback = (entries) => {
                    !cPicker.DOM.scope.classList.contains('hidden') &&
                    position({ target:cPicker.DOM.scope, ref:colorInput, placement:colorInput.dataset.placement || 'center above', offset:[20] });
                }
                const resizeObserver = new ResizeObserver(observerCallback)
                const intersectionObserver = new IntersectionObserver(observerCallback, {root:document, threshold:1});

                // instanciate the color-picker
                const cPicker = new ColorPicker({
                    color: colorInput.value,
                    defaultFormat: 'hex',
                    swatches: colorInput.dataset.swatches === 'false'
                        ? false
                        : JSON.parse(colorInput.dataset.swatches),
                    swatchesLocalStorage: true,
                    onClickOutside(e){
                        let showPicker = false,
                            isTargetColorInput = e.target === colorInput;
                        const pickerElem = cPicker.DOM.scope;

                        if( isTargetColorInput ) showPicker = true
                        if( e.key === 'Escape' )  showPicker = false
                        // remove the color-picker from the DOM
                        if( showPicker ) showColorPicker(pickerElem)
                        else hideColorPicker(pickerElem);

                        isTargetColorInput && observerCallback()
                    },
                    onInput(c){
                        colorInput.value = c;
                        colorInput.style.setProperty('--color', c)
                    },
                })

                cPicker.DOM.scope.setAttribute('positioned', true)

                resizeObserver.observe(document.body)
                intersectionObserver.observe(cPicker.DOM.scope)
                observerCallback()

                colorInput._colorPicker = cPicker

                setInterval(() => {
                    const currentValue = any_to_hex(colorInput.value),
                            pickerValue = any_to_hex(cPicker.CSSColor)

                    if(pickerValue !== currentValue) {
                        cPicker.setColor(currentValue)
                    }
                }, VALUE_CHANGE_SAMPLING_DURATION);
            })

            function showColorPicker(pickerElem) {
                if( !document.body.contains(pickerElem) )
                document.body.appendChild(pickerElem);
            }

            function hideColorPicker(pickerElem) {
                pickerElem.remove();
            }
        }

        // EVENT LISTENER
        // -- GENERAL
        $('.banner_ePMVB1').off("click").on('click', () => {
            document.getElementById('containerProfileImgBanner').style.display = "flex";
            document.querySelector('.editImage_ePMVB1.banner').style.display = "block";
            document.querySelector('.modal-wrapper').style.overflow = "hidden";
        });
        $('.profilePicture_ePMVB1').off("click").on('click', () => $('#avatar').trigger('click'));
        // -- FOR LINKS
        // -- -- ON CLICK
        $("#linkOne .deleteLink_fPEPMVB1").off('click').on("click", (e) => link('remove', 'link_one'));
        $("#linkTwo .deleteLink_fPEPMVB1").off('click').on("click", (e) => link('remove', 'link_two'));
        $("#linkThree .deleteLink_fPEPMVB1").off('click').on("click", (e) => link('remove', 'link_third'));
        // -- -- ON KEY DOWN
        $("#link_one").off('keydown').on("keydown", (e) => {
            if(e.key === "Enter") link('add', 'link_one');
            if (DOMPurify.sanitize(e.target.value.trim(), { USE_PROFILES: {html: false, svg: false, mathMl: false} }) === "") link('remove', 'link_one');
        });
        $("#link_two").off('keydown').on("keydown", (e) => {
            if(e.key === "Enter") link('add', 'link_two');
            if (DOMPurify.sanitize(e.target.value.trim(), { USE_PROFILES: {html: false, svg: false, mathMl: false} }) === "") link('remove', 'link_two');
        });
        $("#link_third").off('keydown').on("keydown", (e) => {
            if(e.key === "Enter") link('add', 'link_third');
            if (DOMPurify.sanitize(e.target.value.trim(), { USE_PROFILES: {html: false, svg: false, mathMl: false} }) === "") link('remove', 'link_third');
        });
    }, [])
    
    useEffect(() => {
        if(user.birthdate !== '' || user.birthdate !== null){
            const bd = new Date(user.birthdate);
            setDefaultBirthdate(bd.getFullYear() + "-" + ("0" + (bd.getMonth() + 1)).slice(-2) + "-" + ("0" + bd.getDate()).slice(-2));
        }
        // Remaining links
        if(!user.links.link_3){
            setRemainingLinks('2/3');
            if(!user.links.link_2){
                setRemainingLinks('1/3');
                if(!user.links.link_1){
                    setRemainingLinks('0/3');
                }
            }
        }else{
            setRemainingLinks('3/3');
        }
    }, [user])

    // FOR FORM
    useEffect(() => {
        // -- SAVE BUTTON
        $('.actions_ePappMVeB1 #save').off('click').on('click', () => saveProfile())
    }, [form])

    // FOR AVATAR
    useEffect(() => {
        if(croppedImage !== undefined){
            $('.submitBtn_eIMVB1.profilePicture').off('click').on('click', () => {
                var file_object = new File([croppedImage], user.uid+"-avatar.webp", {type: "image/webp"});
                const formData = new FormData();
                formData.append("image", file_object, user.uid+"-avatar.webp");
                formData.append("type", 'modify_avatar');
                // UPLOAD
                axios.post(`https://api.snot.fr/v${API_VERSION}/upload/avatars`, formData, {
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: user.uid,
                        app: 'web'
                    }
                }).then(res => {
                    if(res.data.message === "SUCCESS_AVATAR_EDIT"){
                        window.location.reload()
                    }
                })
            });
        }
    }, [croppedImage])

    return (
        <>
        <aside id="editProfile" className={"modal "+theme} aria-hidden="true" aria-modal="false" style={{ display: 'none' }}>
            <div className="modal-wrapper js-modal-stop" style={{padding:0,paddingBottom:10,width:650,height:650}}>
                <div className={"actions_ePappMVeB1 "+theme}>
                    <button className="js-modal-close" style={{display: "flex",justifyContent:"center",alignItems:"center",padding:10}}>
                        <i className="fi fi-rr-cross"></i>
                    </button>
                    <span id="success" style={{display: "none"}}>{t('profile.edit.save.status')}</span>
                    <div id="save" tabIndex={2}>
                        <span>{t('profile.edit.save.button')}</span>
                    </div>
                </div>
                {/* Profile */}
                <div className={"profile_ePMVeB1 "+theme}>
                    <div className="banner_ePMVB1" tabIndex={3} {...(user.banner && !user.banner.includes('https://') ? { style: { backgroundColor: user.banner }}: '')}>
                        {
                            user.banner && user.banner.includes('https://') ?
                            <><img alt={'@'+user.usertag.toLowerCase().replace('.', '_')+'\'s banner'} title={'@'+user.usertag.toLowerCase().replace('.', '_')+'\'s banner'} src={user.banner} /></>
                            : ''
                        }
                        <i className="fi fi-rr-edit"></i>
                    </div>
                    <div className="profilePicture_ePMVB1" tabIndex={4}>
                        <img alt={"@"+user.usertag.toLowerCase().replace(' ', '.')} id="editAvatar" title="Profile picure" src={user.avatar} onError={(e) => {
                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                e.currentTarget.src = "https://api.snot.fr/v"+API_VERSION+"/content/icon_profile?ext=webp";
                            }
                        }} />
                        <i className="fi fi-rr-picture"></i>
                    </div>
                    <form method="post" id="formProfile">
                        <div className="username_fPePMVB1">
                            <div id="usernameContainer">
                                <label id="usernameLabel" htmlFor="username" style={{ display: 'flex', alignItems: "center" }}>{t('profile.edit.username')} <pre style={{ margin: "0 5px" }}>@{user.username.toLowerCase().replace('.', '_')}</pre> {t('profile.edit.username_pt2')}</label>
                                <input className="input_fPEPMVB1" title={t('profile.edit.username')} type="text" id="username" name="username" defaultValue={user.username} maxLength={50} tabIndex={5}
                                    onChange={({currentTarget}) => {
                                        setForm({
                                            ...form, 
                                            username: DOMPurify.sanitize(currentTarget.value.trim(), {USE_PROFILES: {html: false, svg: false, mathMl: false}})
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="aboutme_fPePMVB1" id="containerEP">
                            <label id="aboutmeLabel" htmlFor="aboutme">{t('profile.edit.about')}</label>
                            <textarea className="input_fPEPMVB1" title={t('profile.edit.about')} type="text" id="aboutme" name="aboutme" tabIndex={6} defaultValue={about.aboutme_text}
                                onChange={({currentTarget}) => {
                                    setForm({
                                        ...form, 
                                        about: DOMPurify.sanitize(currentTarget.value.trim(), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']}),
                                        about_text: DOMPurify.sanitize(currentTarget.value.trim(), {USE_PROFILES: {html: false, svg: false, mathMl: false}})
                                    })
                                }}
                            ></textarea>
                        </div>
                        <div className="links_fPePMVB1">
                            <label id="linksLabel" htmlFor="link_one">{t('profile.edit.link')} <span {...(theme !== "darkTheme" ? {style: {color: '#707070',fontSize: 14,marginLeft: 5}}: {style: {color: "#9A9A9A",fontSize: 14,marginLeft: 5}})}>{remainingLinks}</span></label>
                            <p style={{ color: '#db4e4e', margin: '0 0 10px 0', fontSize: 14 }}>{errorLinks}</p>
                            <div id="linkOne">
                                <input className="input_fPEPMVB1" title="Link 1" type="text" id="link_one" name="link_one" tabIndex={7} defaultValue={user.links.link_1} onBlur={() => link('add', 'link_one')} onChange={({currentTarget}) => setForm({...form, links: { ...form.links, link_1: DOMPurify.sanitize(currentTarget.value.trim(), {USE_PROFILES: {html: false, svg: false, mathMl: false}}) }})} />
                                <div className="deleteLink_fPEPMVB1" {...(!user.links.link_1 ? {style:  {display: "none"}} : {style: {display: "block"}})}><i className="fi fi-rr-trash"></i></div>
                            </div>
                            <div id="linkTwo" {...(!user.links.link_1 ? {style:  {display: "none"}} : {style: {display: "block"}})}>
                                <input className="input_fPEPMVB1" title="Link 2" type="text" id="link_two" name="link_two" tabIndex={8} defaultValue={user.links.link_2} onBlur={() => link('add', 'link_two')} onChange={({currentTarget}) => setForm({...form, links: { ...form.links, link_2: DOMPurify.sanitize(currentTarget.value.trim(), {USE_PROFILES: {html: false, svg: false, mathMl: false}}) }})} />
                                <div className="deleteLink_fPEPMVB1" {...(!user.links.link_2 ? {style:  {display: "none"}} : {style: {display: "block"}})}><i className="fi fi-rr-trash"></i></div>
                            </div>
                            <div id="linkThree" {...(!user.links.link_2 ? {style:  {display: "none"}} : {style: {display: "block"}})}>
                                <input className="input_fPEPMVB1" title="Link 3" type="text" id="link_third" name="link_third" tabIndex={9} defaultValue={user.links.link_3} onBlur={() => link('add', 'link_third')} onChange={({currentTarget}) => setForm({...form, links: { ...form.links, link_3: DOMPurify.sanitize(currentTarget.value.trim(), {USE_PROFILES: {html: false, svg: false, mathMl: false}}) }})} />
                                <div className="deleteLink_fPEPMVB1" {...(!user.links.link_3 ? {style: {display: "none"}} : {style: {display: "block"}})}><i className="fi fi-rr-trash"></i></div>
                            </div>
                        </div>
                        <div className="birthdate_fPePMVB1" id="containerEP" style={{paddingBottom: 10}}>
                            <label id="birthdateLabel" htmlFor="birthdate">{t('profile.edit.birthdate')}</label>
                            <input className="input_fPEPMVB1" title={t('profile.edit.birthdate')} type="date" id="birthdate" name="birthdate" defaultValue={defaultBirthdate} tabIndex={10}  onChange={({currentTarget}) => {
                                if(currentTarget.valueAsDate !== null){
                                    setForm({...form, birthdate: currentTarget.valueAsDate.toISOString().slice(0, 19).replace('T', ' ')})
                                }
                            }} />
                        </div>
                    </form>
                </div>
            </div>
        </aside>
        <div id="containerProfileImgBanner" style={{display:"none"}}>
            <div className={"editImage_ePMVB1 "+theme}>
                <form method="post" id="formAvatar" encType="multipart/form-data">
                    <div className="removeBtn_eIMVB1" onClick={() => removeProfile('picture')}>Retirer</div>
                    <div className="newAvatar_eIMVB1">
                        <img id="newAvatar" alt={'@'+user.usertag.toLowerCase().replace('.', '_')+'\'s avatar'} title='Your new profile picture' style={{ display: 'none' }} />
                        <div id="newAvatarCrop" style={{ justifyContent: "center", width: "100%", height: 435, backgroundColor: "#101010" }}>
                            {
                                imageToCrop !== undefined ?
                                <ImageCropper
                                    imageToCrop={imageToCrop}
                                    userUid={user.uid}
                                    onImageCropped={(croppedImage) => setCroppedImage(croppedImage)}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                    <div className="removeBtn_eIMVB1 cancel" onClick={() => {
                        // Profile picture
                        var background = document.querySelector('.editImage_ePMVB1');
                        background.style.display = "none";
                        document.getElementById('newAvatar').style.display = "none";
                        // Banner
                        const previewDiv = document.querySelector('.editImage_ePMVB1.banner');
                        previewDiv.style.display = "none";
                        // Scroll
                        document.querySelector('.modal-wrapper').style.overflow = "auto";
                    }}>Annuler</div>
                    <div className="submitBtn_eIMVB1 profilePicture">Modifier</div>
                    <input type="file" name="avatar" id="avatar" accept="image/*" style={{display: "none"}} onChange={(e) => {
                        const preview = document.getElementById('newAvatar');
                        const previewDiv = document.querySelector('.editImage_ePMVB1');
                        const uploadCrop = document.getElementById('newAvatarCrop');

                        while (preview.firstChild) {
                            preview.removeChild(preview.firstChild);
                        }
            
                        $('#containerProfileImgBanner').css('display', 'flex');
                        previewDiv.style.display = "block";
            
                        const curFiles = e.target.files;
                        if (curFiles.length === 0) {
                            preview.style.display = "block";
                            preview.src = "https://api.snot.fr/v"+API_VERSION+"/content/icon_profile?ext=webp";
                        }else {
                            const list = document.createElement('ol');
                            preview.appendChild(list);
            
                            for (const file of curFiles) {
                                const listItem = document.createElement('li');
                                if (validFileType(file)) {
                                    setImageToCrop(URL.createObjectURL(file));
                                    uploadCrop.style.display = 'flex';
                                }else {
                                    uploadCrop.style.display = 'none';
                                    preview.style.display = "block";
                                    preview.src = "https://api.snot.fr/v"+API_VERSION+"/content/icon_profile?ext=webp";
                                }
            
                                list.appendChild(listItem);
                            }
                        }
                    }} />
                </form>
            </div>
            <div className={"editImage_ePMVB1 banner "+theme}>
                <div className="removeBtn_eIMVB1" onClick={() => removeProfile('banner')}>Retirer</div>
                <div className="chooseBannerColor_eIMVB1">
                    {
                        user.banner === null ?
                            theme === "darkTheme" ?
                            <input className="myColor" inputMode="none" defaultValue="#18181C" style={{"--color": "#18181C"}} data-swatches="false" />
                            :
                            <input className="myColor" inputMode="none" defaultValue="#DADADA" style={{"--color": "#DADADA"}} data-swatches="false" />
                        :
                            user.banner.includes("#") ?
                                <input className="myColor" inputMode="none" defaultValue={user.banner} style={{"--color": user.banner}} data-swatches="false" />
                            : ''
                    }
                </div>
                <div className="removeBtn_eIMVB1 cancel" onClick={() => {
                    // Profile picture
                    var background = document.querySelector('.editImage_ePMVB1');
                    background.style.display = "none";
                    // Banner
                    const previewDiv = document.querySelector('.editImage_ePMVB1.banner');
                    previewDiv.style.display = "none";
                    // Scroll
                    document.querySelector('.modal-wrapper').style.overflow = "auto";
                }}>Annuler</div>
                <div className="submitBtn_eIMVB1" onClick={() => {
                    axios.put(`https://api.snot.fr/v${API_VERSION}/${user.uid}/edit/banner`,
                        {string: document.querySelector('.myColor').value},
                        {
                        params: {
                            token: Cookies.get('loginToken'),
                            uid: user.uid,
                            app: 'web'
                        }
                    }).then(res => window.location.reload())
                }}>Modifier</div>
            </div>
        </div>
        </>
    )
}

export {
    EditProfile,
};