import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";
import Cookies from "js-cookie";
// import i18next
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n/i18n";
// import Components
import LoginModal from '../Modals/Common/LogIn';
// import Functions
import { retreiveInfos } from "../Functions/Auth";
import { API_VERSION } from "../Functions";
import { openMenu } from "../Functions/Menu";
// import Images
import confettiImg from '../../img/feature/confetti.svg';
import sparklesImg from '../../img/feature/sparkles.svg';

const Navbar = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    // Set HeaderView Function -- IMPORTANT
    const HeaderView = () => location.pathname;

    // Set pathname
    const [pathname, setPathname] = useState("");
    // Set data
    const [logged, setLogged] = useState(0);
    const [loggedClass, setLoggedClass] = useState('not-logged');
    const [loading, setLoading] = useState(true);
    const [notSeen, setNotSeen] = useState(0);
    const [profileContent, setProfileContent] = useState(<div id="profile" className={(HeaderView() === "/profile" || HeaderView().includes('/@')) && "active" }><i className={(HeaderView() === "/profile" || HeaderView().includes('/@')) ? "fi fi-sr-circle-user": "fi fi-rr-circle-user"}></i></div>)
    // Set features data
    const [backgroundSettings, setBgSettings] = useState("#26262C");
    const [update, setUpdate] = useState(false);
    // Set Theme Data
    const [theme, setTheme] = useState('darkTheme');
    const [borderColor, setBorderColor] = useState("#fff");
    // Set links
    const [friendsLink, setFriendsLink] = useState("/login"+pathname);
    const [chatLink, setChatLink] = useState("/login"+pathname);
    const [profileLink, setProfileLink] = useState("/profile");
    const [addLink, setAddLink] = useState("/login"+pathname);

    // -- Set logged
    useEffect(() => {
        if(Cookies.get('logged') === '1'){
            setLogged(1);
            setLoggedClass('logged');
        }
        setLoading(false);
    }, [])

    /* ------------------------------
     * CHECK IF MESSAGES ARE NOT SEEN
     * ------------------------------
     */
    const getNotSeenChat = (user) => {
        axios({
            method: 'GET',
            url: `https://api.snot.fr/v${API_VERSION}/chat/all/not-seen`,
            params: {
                token: Cookies.get('loginToken'),
                uid: user,
                app: 'web'
            }
        }).then(res => {
            setNotSeen(res.data.count);
        })
    }

    // ...
    useEffect(() => {
        if(location.pathname !== "/"){
            setPathname("?rel="+location.pathname);
        }

        if(loading === false) {
            if (logged === 1) {
                retreiveInfos().then((infos) => {
                    // Not seen messages count
                    getNotSeenChat(infos.uid);
                    // Set links
                    setFriendsLink("/friends");
                    setChatLink("/friends/chat");
                    setProfileLink("/@"+infos.usertag.toLowerCase().replace('.', '_'));
                    if(location.pathname !== "/"){
                        setAddLink("/add?rel="+location.pathname);
                    }else{
                        setAddLink("/add");
                    }

                    // Set icon and text for profile button
                    setProfileContent(<div id="profile" className={(HeaderView() === "/profile" || HeaderView().includes('/@')) && "active"}>
                        <img className="avatar_pIconMvEB1" alt="avatar" title="avatar" src={infos.avatar} {...(HeaderView().includes("/@") || HeaderView().includes("/profile") ? { style: { border: '2px solid '+borderColor} }: { style: { border: '2px solid '+borderColor} })} referrerpolicy="no-referrer" style={{marginBottom: 5}} onError={(e) => {
                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                e.currentTarget.src = 'https://api.snot.fr/v1/content/icon_profile?ext=webp';
                            }
                        }} />
                    </div>)
                })

                // Settings menu
                function changeMenuX(pos) {
                    if(pos.matches) {
                        $('#settingsMenu .menu-wrapper').css('bottom', 80);
                    }else {
                        $('#settingsMenu .menu-wrapper').removeAttr('style');
                    }
                }
                var size = window.matchMedia("(max-height: 750px)");
                // Attach listener function on state changes
                size.addEventListener("change", () => changeMenuX(size));
            }else{
                // Set links
                setFriendsLink("/login"+pathname);
                setChatLink("/login"+pathname);
                setAddLink("/login"+pathname);
                setProfileLink("/profile");
                
                // Set icon and text for profile button
                setProfileContent(<div id="profile" className={(HeaderView() === "/profile" || HeaderView().includes('/@')) && "active"} onMouseEnter={() => document.querySelector('#profile i').className = "fi fi-sr-circle-user"} onMouseLeave={() => {
                    if(document.getElementById('profile').className !== "active") {
                        document.querySelector('#profile i').className = "fi fi-rr-circle-user"
                    }
                }}><i className={HeaderView().includes("/@") || HeaderView().includes("/profile") ? "fi fi-sr-circle-user": "fi fi-rr-circle-user"}></i></div>)
            }
        }

        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
                setBorderColor('#000');
                setBgSettings('#DDD');
            }else{
                setTheme('darkTheme');
                setBorderColor('#fff');
                setBgSettings('#26262C');
            }
        }

        // For updates
        if(logged === 1){
            axios.get(
                `https://api.snot.fr/v${API_VERSION}/new/update`,
                {
                    headers:{
                        "Content-Type": "application/json",
                    },
                    params: { token: Cookies.get('loginToken') },
                }
            ).then((res) => {
                if(!res.data.error){
                    setUpdate(true);
                    // ...
                    $('#settings-navBar').css('opacity', 0);
                    $('#donate-navBar').css('opacity', 0);
                }
            })
        }
    }, [loading, logged, localStorage.getItem('theme')])

    // FOR NEW FEATURES (in navbar)
    const nextStepFeature = () => {
        $('.newFeature__overlay').addClass('slide-out-top');
        $('.newFeature_containerPCvB1').removeClass('slide-in-bck-bl').addClass('slide-out-bck-bl');
        $('.newFeature__overlay #settings').removeClass('slide-in-bck-bl').addClass('slide-out-bck-bl');
        $('.newFeature__overlay #donate').removeClass('slide-in-bck-bl').addClass('slide-out-bck-bl');
        // Change text
        $('#settings-navBar').addClass('show-fade');
        $('#donate-navBar').addClass('show-fade');
        // Register to database
        axios.put(
            `https://api.snot.fr/v${API_VERSION}/update/view/update`,
            {
                headers:{
                    "Content-Type": "application/json",
                    'Access-Control-Allow-Origin': '*'
                },
                data: { user: Cookies.get('loginToken') }
            }
        ).then((res) => {
            if(!res.data.error && res.data.success){
                setUpdate(false);
            }
        })
    }

    const [chrono, setChrono]= useState(null);
    const highlightDonate = () => {
        $('.newFeature__overlay #donateHighlight').css({borderRadius: 10, border: '3px solid #F2ECCB'});
        if (chrono != null){
            clearTimeout(chrono);
            setChrono(setTimeout(() => $('.newFeature__overlay #donateHighlight').css({border: 0}), 1500));
        }else{
            setChrono(setTimeout(() => $('.newFeature__overlay #donateHighlight').css({border: 0}), 1500));
        }
    }

    return (
        <>
        {
            logged === 1 ?
                update ?
                    <aside className="modal newFeature__overlay">
                        <div className={"newFeature_containerPCvB1 slide-in-bck-bl "+theme}>
                            <img src={sparklesImg} alt="Sparkles" title="SNOT - New feature!" className="sparklesIllustration" />
                            <img src={confettiImg} alt="Confetti" title="SNOT - New feature!" className="confettiIllustration" />
                            <h1 id="newFeature-title">IL Y A DU NOUVEAU !</h1>
                            <p id="newFeature-subtitle">
                                Vous pouvez désormais accéder à vos <Link to="/settings" title="Aller aux paramètres">paramètres</Link> depuis ce bouton (pratique hein) !
                                <br/><br/>
                                Nous avons également ajouter <span onClick={highlightDonate} style={{ cursor: "pointer" }}>ce bouton</span> qui permet de nous faire un don !
                            </p>
                            <div className="followGuide">
                                <p className="isNext" onClick={nextStepFeature}>D'accord <i className="fi fi-rr-arrow-right" style={{ marginLeft: 5 }}></i></p>
                            </div>
                        </div>
                        <div
                            id="settings"
                            className="slide-in-bck-bl"
                            style={{ width: 50,
                                height: 50,
                                position: "absolute",
                                bottom: 100,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 5,
                                background: backgroundSettings,
                                left: 25
                            }}
                        >
                            <div style={{ fontSize: 40 }}><i className="fi fi-rs-settings" style={{display: "flex"}}></i></div>
                        </div>
                        <div
                            id="donate"
                            className="slide-in-bck-bl"
                            style={{
                                display: "flex",
                                position: "absolute",
                                bottom: 25,
                                left: 25
                            }}
                        >
                            <form>
                                <img alt="Donate with PayPal !" id="donateHighlight" src="https://pics.paypal.com/00/s/NDQyZGQyNDYtM2JmNi00NGQ3LWE4MTItNWE3NzAyZmNmODMy/file.PNG" border={0} width={50} height={50} style={{cursor: "default"}} />
                            </form>
                        </div>
                    </aside>
                : ''
            : ''
        }
        <nav className={"navBar_pcverSB1 "+theme+" "+loggedClass}>
            <div className="actions_bNBmVeB1">
                <Link to="/">
                    <div
                        id="home"
                        className={(HeaderView() === "/" || HeaderView() === "/popular" || HeaderView() === "/subs") ? "active": "" }
                        onMouseEnter={() => {document.querySelector('#home i').className = "fi fi-sr-home"}}
                        onMouseLeave={() => {
                            if(document.getElementById('home').className !== "active") {
                                document.querySelector('#home i').className = "fi fi-rr-home"
                            }
                        }}
                    >
                        <i className={(HeaderView() === "/" || HeaderView() === "/popular" || HeaderView() === "/subs") ? "fi fi-sr-home": "fi fi-rr-home" }></i>
                        <p>{t('general.navbar.home')}</p>
                    </div>
                </Link>
                <Link to={friendsLink}>
                    <div
                        id="friends"
                        className={(HeaderView().includes("/friends") && !HeaderView().includes('/chat')) && "active" }
                        onMouseEnter={() => {document.querySelector('#friends i').className = "fi fi-sr-users"}}
                        onMouseLeave={() => {
                            if(document.getElementById('friends').className !== "active") {
                                document.querySelector('#friends i').className = "fi fi-rr-users"
                            }
                        }}
                    >
                        <i className={(HeaderView().includes("/friends") && !HeaderView().includes('/chat')) ? "fi fi-sr-users": "fi fi-rr-users" }></i>
                        <p>{t('general.navbar.friends')}</p>
                    </div>
                </Link>
                <Link to={chatLink}>
                    <div 
                        id="messages"
                        className={HeaderView().includes("/chat") && "active" }
                        onMouseEnter={() => {document.querySelector('#messages i').className = "fi fi-sr-comment-alt"}}
                        onMouseLeave={() => {
                            if(document.getElementById('messages').className !== "active") {
                                document.querySelector('#messages i').className = "fi fi-rr-comment-alt"
                            }
                        }}
                    >
                        <i className={HeaderView().includes("/chat") ? "fi fi-sr-comment-alt": "fi fi-rr-comment-alt" }></i>
                        <p>Chat</p>
                        {
                            logged === 1 ?
                                notSeen === 0 ?
                                <div id="notifIndice" className="newChat-notifIndice" style={{display: "none", margin: "-5px 8px 0 0", backgroundColor: "#ff2323" }}></div>
                                :
                                <div id="notifIndice" className="newChat-notifIndice" style={{display: "flex", margin: "-5px 8px 0 0", backgroundColor: "#ff2323" }}></div>
                            : ''
                        }
                    </div>
                </Link>
                <Link to={profileLink}>
                    {profileContent}
                </Link>
                <Link to={addLink}>
                    <div
                        id="add"
                        className={HeaderView() === "/add" && "active"}
                        onMouseEnter={() => {document.querySelector('#add i').className = "fi fi-sr-add"}}
                        onMouseLeave={() => {
                            if(document.getElementById('add').className !== "active") {
                                document.querySelector('#add i').className = "fi fi-rr-add"
                            }
                        }}
                    >
                        <i className={HeaderView() === "/add" ? "fi fi-sr-add": "fi fi-rr-add" }></i>
                        <p>{t('general.navbar.post')}</p>
                    </div>
                </Link>
                {
                    logged === 1 ?
                        <div id="settings-navBar" data-container="settingsMenu" onClick={(e) => openMenu(e)}>
                            <div
                                id="settings"
                                className={HeaderView().includes("/settings") && "active"}
                                onMouseEnter={() => {document.querySelector('#settings-navBar #settings i').className = "fi fi-ss-settings"}}
                                onMouseLeave={() => {
                                    if(document.querySelector('#settings-navBar #settings').className !== "active") {
                                        document.querySelector('#settings-navBar #settings i').className = "fi fi-rs-settings"
                                    }
                                }}
                                data-container="settingsMenu"
                            >
                                <i className={HeaderView().includes("/settings") ? "fi fi-ss-settings": "fi fi-rs-settings" } data-container="settingsMenu"></i>
                            </div>
                        </div>
                    : ''
                }
            </div>
            <div id="donate-navBar">
                <form action="https://www.paypal.com/donate" method="post" target="_top" {...((logged === 1 || (logged === 0 && window.location.pathname.includes("/integrate"))) ? { style: { bottom: 30, position: "fixed" }}: { style: { bottom: 110, position: "fixed" } })} data-tooltip={i18n.resolvedLanguage === "fr" ? "Donation": "Donate"} data-tooltip-location="top" data-tooltip-theme={theme}>
                    <input type="hidden" name="hosted_button_id" value="FYMQEUYP3QNMU" />
                    <input type="image" src="https://pics.paypal.com/00/s/NDQyZGQyNDYtM2JmNi00NGQ3LWE4MTItNWE3NzAyZmNmODMy/file.PNG" style={{ border: 0 }} name="submit" title="Donate to SNOT with PayPal" alt="Donate with PayPal button" />
                </form>
            </div>
        </nav>
        {
            logged === 0 ?
            <LoginModal />
            :
            <aside id="settingsMenu" className={"menu "+theme} aria-hidden="true" aria-modal="false" style={{display:"none"}}>
                <div className="menu-wrapper js-menu-stop" style={{width: "auto",maxWidth: "none", borderRadius: 10 }}>
                    <div className="settings_menuMVB1">
                        <Link to="/settings" id="goToSettings">
                            <span>{t('settings.menu.choice.1')}</span>
                        </Link>
                        <Link to={profileLink+'?open=edit'} id="editProfileNav">
                            <span>{t('settings.menu.choice.2')}</span>
                        </Link>
                    </div>
                </div>
            </aside>
        }
        </>
    );
}

export default Navbar;