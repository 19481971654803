import axios from "axios";
import Cookies from "js-cookie";
// API VERSION
import API_VERSION from "./ApiVersion";

const retreiveCreatorInfos = (user) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `https://api.snot.fr/v${API_VERSION}/user/spost/${user}`,
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            resolve(response.data.infos);
        }).catch((error) => {
            reject(error);
        })
    })
}

// Count Comments
const getCountComment = (uid) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `https://api.snot.fr/v${API_VERSION}/posts/${uid}/comments`,
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            resolve(response.data.count);
        }).catch((error) => {
            reject(error);
        })
    })
}

// Count Replies
const getCountReplies = (parent, comment) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `https://api.snot.fr/v${API_VERSION}/${parent}/comments/${comment}/replies`,
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            resolve(response.data.count);
        }).catch((error) => {
            reject(error);
        })
    })
}

export {
    retreiveCreatorInfos,
    getCountComment,
    getCountReplies,
}