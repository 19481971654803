import axios from "axios";
import Cookies from "js-cookie";
// API VERSION
import API_VERSION from "./ApiVersion";

var token = 'not_logged';
if(Cookies.get('loginToken')) token = Cookies.get('loginToken');

// Retreive all settings (no notifications) for [user]
const retreiveSettings = (user, setting) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `https://api.snot.fr/v${API_VERSION}/${user}/settings`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                token: token,
                setting: setting,
                app: 'web'
            }
        }).then((response) => {
            resolve(response.data.settings);
        }).catch((error) => {
            console.log('Oops! You can\'t retreive the settings for this user : %c'+error.response.statusText+' '+error.response.status, 'color: red;');
        })
    })
}

// Retreive notifications settings for [user]
const retreiveNotifSettings = (user) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `https://api.snot.fr/v${API_VERSION}/${user}/settings/notifications`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                token: token,
                app: 'web'
            }
        }).then((response) => {
            resolve(response.data.settings);
        }).catch((error) => {
            reject(error);
        })
    })
}

// Retreive notifications settings for [user]
const retreive2FASettings = (user) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `https://api.snot.fr/v${API_VERSION}/${user}/settings/2fa`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                token: token,
                app: 'web'
            }
        }).then((response) => {
            resolve(response.data.settings);
        }).catch((error) => {
            reject(error);
        })
    })
}

export {
    retreiveSettings,
    retreiveNotifSettings,
    retreive2FASettings
}