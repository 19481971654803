import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import HtmlToReact from 'html-to-react';
import $ from 'jquery';
import copy from 'copy-to-clipboard';
import axios from 'axios';
import Cookies from 'js-cookie';
// import i18next
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n/i18n";
// import Components
import Video from '../Common/Media/VideoPlayer';
import Audio from '../Common/Media/AudioPlayer';
// import Functions
import { API_VERSION } from '../Functions';
import { openMenu } from '../Functions/Menu';
import { openModal } from '../Functions/Modal';
import * as Chat from '../Functions/Chat';
import { retreiveInfos } from '../Functions/Auth';
// import Images
import errorMediaDark from '../../img/error/media_dark.png';
import errorMediaLight from '../../img/error/media_light.png';
import { retreiveUserInfos } from '../Functions/Profile';

// -- Only images, videos and audio
const acceptedImage = [
    "apng",
    "bmp",
    "gif",
    "jpg",
    "jpeg",
    "pjpeg",
    "png",
    "tiff",
    "tif",
    "ico",
    "avif",
    "svg",
    "webp",
    "heif",
    "heifs",
    "heic",
    "heics",
    "jp2",
    "j2k",
    "jpx",
    "jpm",
    "avci",
    "aces",
]
const acceptedVideo = [
    "webm",
    "mj2",
    "mjp2",
    "mp4",
    "m4v",
    "ts",
    "avi",
    "mpeg",
    "ogv",
    "3gp",
    "3g2",
    "mov",
    "mkv",
    "mk3d",
    "flv",
    "wmf",
    "vob",
]
const acceptedAudio = [
    '3gp',
    '3g2',
    'aac',
    'ac3',
    'aa',
    'aax',
    "aiff",
    "alac",
    'mka',
    'midi',
    'mid',
    'm4a',
    "m4b",
    "m4p",
    "mpc",
    'mp3',
    'opus',
    'oga',
    'wav',
    'weba'
]

export const ChatMsg = ({ uid, sender: { senderID, username, usertag, avatar }, html, message, files, date, reply: { rcontent, ruid }, modified, badges: { certified, staff }, pusher, filter, theme }) => {
    const { t } = useTranslation();
    const { key } = useParams();
    // Set data
    const [myData, setMyData] = useState([]);
    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>,
    });
    const [mediaHTML, setMedia] = useState([]);
    const [filteredContent, setFilteredContent] = useState(html);
    const [menu, setMenu] = useState({
        edit: <></>,
        delete: <></>
    })
    // Reply
    const [replyUser, setReplyUser] = useState([]);
    const [filteredReplyContent, setFilteredReplyContent] = useState(rcontent);
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);

    // Set date format
    const sendDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const match_date = new Date(date);
    match_date.setHours(0, 0, 0, 0);

    const diff = Math.floor((today - match_date) / (1000 * 60 * 60 * 24));
    let diffDays = parseInt(diff);
        
    var date_format = "";
    if(i18n.resolvedLanguage === "fr"){
        switch (diffDays) {
            default:
                date_format = "Le " + sendDate.toLocaleString('fr-FR', { day: 'numeric', month: 'numeric', year: '2-digit' });
        }
    }else {
        switch (diffDays) {
            default:
                date_format = "The " + sendDate.toLocaleString('en-UK', { day: 'numeric', month: 'numeric', year: '2-digit' });
        }
    }

    // GET USER'S DATA
    const getUserData = (uid) => {
        return new Promise((resolve, reject) => {
            axios.get(
                `https://api.snot.fr/v${API_VERSION}/chat/${key}/user/${uid}?token=${Cookies.get('loginToken')}&app=web`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    }
                }
            )
            .then((res) => resolve(res.data))
            .catch((err) => reject(err))
        })
    }

    // GET ONE MESSAGE
    const getOneMessage = (uid, userUID) => {
        return new Promise((resolve, reject) => {
            axios.get(
                `https://api.snot.fr/v${API_VERSION}/chat/${key}/get-one/${uid}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: userUID,
                        app: 'web'
                    }
                }
            )
            .then((res) => resolve(res.data.chat))
            .catch((err) => reject(err))
        })
    }

    /* -----------
     * BUILD MEDIA
     * -----------
     */
    const showMedia = () => {
        // -- If there's a link to a media
        const fileRgx = /\.[0-9a-z]+?=[?#]|\.(?:[\w]+)($|\?)/mi;
        const patterns = /https?:\/\/?[\da-z\.-]+\.[a-z\.]{2,6}[\w\S]*\/?/mis;
        let m1 = fileRgx.exec(message);
        let m2 = patterns.exec(message);

        if ((m1 = fileRgx.exec(message)) !== null) {
            m1.forEach((match) => {
                if(match !== '' && match !== '?'){
                    let fileExt = match.replace('?', '');
                    if (fileExt === '.png' || fileExt === '.jpg' || fileExt === '.jpeg' || fileExt === '.gif' || fileExt === '.bmp' || fileExt === '.tif' || fileExt === '.tiff' || fileExt === '.webp' || fileExt === '.mp4' || fileExt === '.mov' || fileExt === '.avi' || fileExt === '.wmf' || fileExt === '.flv' || fileExt === '.webm' || fileExt === '.mkv' || fileExt === '.ogg' || fileExt === '.vob' || fileExt === '.3gp' || fileExt === '.aa' || fileExt === '.aax' || fileExt === '.aac' || fileExt === '.aiff' || fileExt === '.alac' || fileExt === '.m4a' || fileExt === '.m4b' || fileExt === '.m4p' || fileExt === '.mp3' || fileExt === '.mpc' || fileExt === '.wav') {
                        if ((m2 = patterns.exec(message)) !== null) {
                            m2.forEach((matchImage) => {
                                let spostImage = matchImage;
                                
                                if (spostImage.includes('.mp4') || spostImage.includes('.mov') || spostImage.includes('.avi') || spostImage.includes('.wmf') || spostImage.includes('.flv') || spostImage.includes('.webm') || spostImage.includes('.mkv') || spostImage.includes('.ogg') || spostImage.includes('.vob')) {
                                    setMedia(
                                        <div class="attachFiles_chatFMMVB1">
                                            <Video videoRef={uid} videoSrc={spostImage} params={{ stopPropagation: true, nsfw: false }} />
                                        </div>
                                    );
                                }else if (spostImage.includes('.png') || spostImage.includes('.jpg') || spostImage.includes('.jpeg') || spostImage.includes('.gif') || spostImage.includes('.bmp') || spostImage.includes('.tif') || spostImage.includes('.tiff') || spostImage.includes('.webp')) {
                                    setMedia(
                                        <div class="attachFiles_chatFMMVB1">
                                            <img src={arrayMedia} alt={arrayMedia} title={arrayMedia}
                                                onError={({ currentTarget }) => {
                                                    if(theme === "darkTheme"){
                                                        currentTarget.src = errorMediaDark;
                                                    }else{
                                                        currentTarget.src = errorMediaLight;
                                                    }
                                                }}
                                                referrerPolicy="no-referrer"
                                            />
                                        </div>
                                    );
                                }else if(spostImage.includes('.3gp') || spostImage.includes('.aa') || spostImage.includes('.aax') || spostImage.includes('.aac') || spostImage.includes('.aiff') || spostImage.includes('.alac') || spostImage.includes('.m4a') || spostImage.includes('.m4b') || spostImage.includes('.m4p') || spostImage.includes('.mpc') || spostImage.includes('.wav') || spostImage.includes('.mp3')) {
                                    setMedia(
                                        <Audio audioRef={uid} audioSrc={spostImage} params={{ stopPropagation: true }} />
                                    )
                                }
                            });
                        }
                    }
                }
            });
        }

        // -- If there is a media (no link)
        var fileList = JSON.parse(files);
        if(fileList.length !== 0) {
            for(let i = 0; i < fileList.length; i++) {
                var arrayMedia = fileList[i].toString();
                const params = new URL(arrayMedia).searchParams;

                const elementExists = mediaHTML.some(element => {
                    if(element.props.children !== undefined){
                        return element.props.children.props.videoSrc === arrayMedia || 
                               element.props.children.props.src === arrayMedia
                    }else{
                        return element.props.audioSrc === arrayMedia
                    }
                });
        
                if (!elementExists) {
                    if(acceptedVideo.includes(params.get('ext'))) {
                        mediaHTML.push(
                            <div key={`media-${i}`} className="attachFiles_chatFMMVB1" style={{maxHeight:450,maxWidth:450}}>
                                <Video videoRef={uid} videoSrc={arrayMedia} params={{ stopPropagation: true }} />
                            </div>
                        );
                    }else if(acceptedImage.includes(params.get('ext'))) {
                        mediaHTML.push(
                            <div key={`media-${i}`} className="attachFiles_chatFMMVB1" data-modal="seeMedia" data-src={arrayMedia} onClick={(e) => {
                                openModal(e);
                                // ...
                                var image = document.querySelector('.mediaImage_sMappMVeB1');
                                    image.style.display = "flex";
                                var navigator = document.getElementById('seeNavigator');
                                    navigator.setAttribute('href', e.currentTarget.getAttribute('data-src'))

                                var img = document.getElementById('mediaImg');
                                    img.src = e.currentTarget.getAttribute('data-src');
                                    img.setAttribute('title', e.currentTarget.getAttribute('data-src'));
                                    img.setAttribute('alt', "Image");

                            }}>
                                <img src={arrayMedia} alt={arrayMedia} title={arrayMedia}
                                    onError={({ currentTarget }) => {
                                        if(theme === "darkTheme"){
                                            currentTarget.src = errorMediaDark;
                                        }else{
                                            currentTarget.src = errorMediaLight;
                                        }
                                    }}
                                    referrerPolicy="no-referrer"
                                    data-modal="seeMedia"
                                />
                            </div>
                        )
                    }else if(acceptedAudio.includes(params.get('ext'))) {
                        mediaHTML.push(
                            <Audio audioRef={uid} audioSrc={arrayMedia} params={{ stopPropagation: true, chat: true, key: `media-${i}` }} />
                        )
                    }
                }
            }
        }
    }

    /* ----------
     * SET BADGES
     * ----------
     */
    useEffect(() => {
        if (certified === "Yes") {
            var colorBadge = "#000";
            if(theme === "darkTheme"){
                colorBadge = "#fff"
            }
            setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: colorBadge }}></i>});
        }
        if (staff === "Yes") {
            setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
        }

        showMedia();

        if(filter === "Disabled") {
            axios.get(`https://api.snot.fr/v${API_VERSION}/file/filters/${i18n.resolvedLanguage === "fr" ? "fr": "en"}`, {
                responseType: 'text'
            }).then(response => {
                const filterWords = response.data.split('\n').map(word => word.trim()).filter(word => word.length > 0);

                const regex = new RegExp(`\\b(${filterWords.join('|')})\\b`, 'gi');
                const filtered = html.replace(regex, '<div id="filterInsults"></div>');
                setFilteredContent(filtered);
                if((rcontent !== null && rcontent !== '') && (ruid !== null && ruid !== '')){
                    const replyFiltered = rcontent.replace(regex, '<div id="filterInsults"></div>');
                    setFilteredReplyContent(replyFiltered);
                }
            })
        }

        /* FUNCTIONS CALL */
        // -- For 'reply'
        retreiveInfos().then(res => {
            if(res.length !== 0){
                setMyData(res);
                // REPLY CONTAINER
                if((rcontent !== null && rcontent !== '') && (ruid !== null && ruid !== '')){
                    getOneMessage(ruid, res.uid).then(resChat => {
                        setReplyUser(resChat[0]);
                    })
                }
                // EVENTS
                // -- For 'reply' in menu
                getUserData(res.uid).then(resUser => {
                    if(resUser.infos !== null){
                        if(document.querySelector('#actionsMenu_'+uid+' #reply')){
                            $('#actionsMenu_'+uid+' #reply').off('click').on('click', () => {
                                Chat.replyChat({ user: res, friend: resUser.infos[0], key: key, reply: { uid: uid, content: message.replace(["\r", "\n"], '\n'), to: usertag }, pusher: pusher, theme: theme });
                            });
                        }
                        if(document.querySelector('.msg_'+uid+' #answer_button')){
                            $('.msg_'+uid+' #answer_button').off('click').on('click', () => {
                                Chat.replyChat({ user: res, friend: resUser.infos[0], key: key, reply: { uid: uid, content: message.replace(["\r", "\n"], '\n'), to: usertag }, pusher: pusher, theme: theme });
                            });
                        }
                    }
                });
            }
        });
        // -- For 'copy' in menu
        if(document.querySelector('#actionsMenu_'+uid+' #copy')) {
            $('#actionsMenu_'+uid+' #copy').off('click').on('click', (e) => {
                openModal(e);
                copy(message);
            });
        }
    }, [theme])

    // APPEND EDIT & DELETE ACTION MENU
    useEffect(() => {
        if(myData.length !== 0){
            if(myData.uid === senderID) {
                setMenu({
                    edit: <div id="edit" className='js-menu-close-other'><span>{t('chat.menu.edit')}</span><i className="fi fi-rr-pencil"></i></div>,
                    delete: <div id="delete" className="js-menu-close-other" data-modal="deleteConfirm"><span data-modal="deleteConfirm">{t('chat.menu.delete')}</span><i className="fi fi-rr-trash" data-modal="deleteConfirm"></i></div>
                })
            }
        }
    }, [myData])

    // EVENTS LISTENERS
    useEffect(() => {
        // -- For 'delete' in menu
        if(menu.delete !== <></>){
            $('#actionsMenu_'+uid+' #delete').off('click').on('click', (e) => openModal(e));
            // Change click event (modal)
            $('#deleteMessageBtn.button_confirmMVB1').off('click').on('click', () => {
                $('#deleteConfirm').trigger('click');
                // ...
                axios.delete(
                    `https://api.snot.fr/v${API_VERSION}/chat/${key}/delete/${uid}`,
                    {
                        headers:{
                            "Content-Type": "application/json"
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            uid: myData.uid,
                            app: 'web'
                        }
                    }
                ).then(responseDel => {
                    $('.msg_'+responseDel.data.uid).remove();
                })
            })
        }
        // -- For 'edit' in menu
        if(menu.edit !== <></>){
            $('#actionsMenu_'+uid+' #edit').off('click').on('click', () => {
                Chat.editChat({ uid: uid, content: message.replace(["\r", "\n"], '\n'), userID: senderID, key: key });
            })
        }
    }, [menu])

    return (
        <>
        <div
            className={"message_pChatFMMVB1 msg_"+uid+" "+theme}
            onMouseEnter={() => $('.control_'+uid).css({ display: "flex" })}
            onMouseLeave={() => $('.control_'+uid).css({ display: "none" })}
        >
            <div className="profilePart_msgPCMMVB1">
                <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                    <img alt={"@"+usertag.replace('.', '_').toLowerCase()} title={username} src={avatar} className={"avatarChat_"+uid+" content"}
                        onError={() => {
                            if(!$('.avatarChat_'+uid).attr('src').includes('.googleusercontent.com')){
                                $('.avatarChat_'+uid).attr('src', 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp')
                            }
                        }}
                        style={loadingAvatar ? {display: "none"}: {}}
                        onLoad={() => setLoadingAvatar(false)}
                        referrerPolicy="no-referrer"
                    />
                </div>
                <div className={"user "+theme} style={{ width: "auto" }}><span id="username">{username}</span></div>
                <div className={"usertag "+theme} style={{ width: "auto" }}><span id="usertag">@{usertag.replace('.', '_').toLowerCase()} {badges.certified}{badges.staff}</span></div>
            </div>
            <div className="datePart_msgPCMMVB1">
                <span id="date">{date_format}</span>
                <div className={"controls_msgPCMMVB1 control_"+uid+" "+theme}>
                    {
                        myData.uid !== senderID &&
                        <div id="answer_button" onClick={() => $('#actionsMenu_'+uid).trigger('click')}>
                            <i className="fi fi-sr-arrow-turn-down-right"></i>
                        </div>
                    }
                    <div id="actionsMenuOpen" className={"msg_"+uid+" menuOpen"} data-container={"actionsMenu_"+uid} onClick={(e) => openMenu(e)}>
                        <i className="fi fi-rr-menu-dots" data-container={"actionsMenu_"+uid}></i>
                    </div>
                </div>
            </div>
            <div className="contentPart_msgPCMMVB1">
                {
                    ((rcontent !== null && rcontent !== '') && (ruid !== null && ruid !== '') && replyUser.length !== 0) &&
                    <div className={"replyContainer_msgPCMMVB1 "+theme} onClick={() => {
                        document.querySelector('.mainPart_friendsMMVB1').scrollTo(0, document.querySelector('.msg_'+ruid).offsetTop - 70);
                        document.querySelector('.msg_'+ruid).style.backgroundColor = theme === "darkTheme" ? "#1F1F24": "#E8E8E8";
                        setTimeout(() => {
                            document.querySelector('.msg_'+ruid).removeAttribute('style');
                        }, 500)
                    }}>
                        <div className="profilePart_msgPCMMVB1">
                            <div className="avatarReply"><img alt={"@"+replyUser.usertag.toLowerCase().replace('.', '_')} title={replyUser.username} src={replyUser.avatar} /></div>
                            <div className="usernameReply"><span id="username" style={{display:"flex"}}>{replyUser.username}</span></div>
                            <div className="usertagReply"><span id="usertag" style={{display:"flex"}}>@{replyUser.usertag.toLowerCase().replace('.', '_')}</span></div>
                        </div>
                        <div className="contentPart_msgPCMMVB1">
                            <div className="textReply"><span id="content">{HtmlToReact.Parser().parse(filteredReplyContent)}</span></div>
                        </div>
                    </div>
                }
                <span id="content">{HtmlToReact.Parser().parse(filteredContent)}</span>
            </div>
            <div className="attachFiles_mainContainer">
                {mediaHTML}
            </div>
            <div id="modifiyText">
                {
                    (modified === "Yes" && mediaHTML.length === 0) ?
                    <div class="editedContent_msgPCMMVB1">
                        <p style={{marginBottom: 0,marginTop:5,fontSize: 12,color: "#9A9A9A",textAlign: "left"}}>{t('chat.modified')}</p>
                    </div>
                    :
                    (modified === "Yes" && mediaHTML.length !== 0) &&
                    <div class="editedContent_msgPCMMVB1">
                        <p style={{marginBottom: 0,fontSize: 12,color: "#9A9A9A",textAlign: "left"}}>{t('chat.modified')}</p>
                    </div>
                }
            </div>
            {/* Messages Actions Menu */}
            <aside id={"actionsMenu_"+uid} className={"menu "+theme} aria-hidden="true" data-o-container="actionsMenuFriends" data-actions={myData.uid === senderID ? "edit,reply,copy,delete": "reply,copy"} aria-modal="false" style={{display: "none"}}>
                <div className="menu-wrapper js-menu-stop" style={{width:350, right:10}}>
                    <div className="actionsPart_actionsMenuMMVB1">
                        {menu.edit}
                        <div id="reply" className='js-menu-close-other'><span>{t('chat.menu.reply')}</span><i className="fi fi-rr-arrow-turn-down-right"></i></div>
                        <div id="copy" className={"copyText_"+uid+" js-menu-close-other"} data-modal="copyNotif"><span data-modal="copyNotif">{t('chat.menu.copy')}</span><i className="fi fi-rr-copy-alt" data-modal="copyNotif"></i></div>
                        {menu.delete}
                    </div>
                </div>
            </aside>
        </div>
        </>
    )
}