import $ from 'jquery';

const appId = process.env.CALL_API_ID;
const appSecret = process.env.CALL_API_SECRET;

class CallsApp {
    constructor(appId, basePath = 'https://rtc.live.cloudflare.com/v1') {
        this.prefixPath = `${basePath}/apps/${appId}`;
    }

    async sendRequest(url, body, method = 'POST') {
        const request = {
            method: method,
            mode: 'cors',
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${appSecret}`
            },
            body: JSON.stringify(body)
        };
        const response = await fetch(url, request);
        const result = await response.json();
        return result;
    }

    checkErrors(result, tracksCount = 0) {
        if (result.errorCode) {
            throw new Error(result.errorDescription);
        }
        for (let i = 0; i < tracksCount; i++) {
            if (result.tracks[i].errorCode) {
                throw new Error(
                    `tracks[${i}]: ${result.tracks[i].errorDescription}`
                );
            }
        }
    }

    // newSession sends the initial offer and creates a session
    async newSession(offerSDP) {
        const url = `${this.prefixPath}/sessions/new`;
        const body = {
            sessionDescription: {
                type: 'offer',
                sdp: offerSDP
            }
        };
        const result = await this.sendRequest(url, body);
        this.checkErrors(result);
        this.sessionId = result.sessionId;
        return result;
    }

    // newTracks shares local tracks or gets tracks
    async newTracks(trackObjects, offerSDP = null) {
        const url = `${this.prefixPath}/sessions/${this.sessionId}/tracks/new`;
        const body = {
            sessionDescription: {
                type: 'offer',
                sdp: offerSDP
            },
            tracks: trackObjects
        };
        if (!offerSDP) {
            delete body['sessionDescription'];
        }
        const result = await this.sendRequest(url, body);
        this.checkErrors(result, trackObjects.length);
        return result;
    }

    // sendAnswerSDP sends an answer SDP if a renegotiation is required
    async sendAnswerSDP(answer) {
        const url = `${this.prefixPath}/sessions/${this.sessionId}/renegotiate`;
        const body = {
            sessionDescription: {
                type: 'answer',
                sdp: answer
            }
        };
        const result = await this.sendRequest(url, body, 'PUT');
        this.checkErrors(result);
    }
}

window.self.pc = new RTCPeerConnection({
    iceServers: [
        {
            urls: 'stun:stun.cloudflare.com:3478'
        }
    ],
    bundlePolicy: 'max-bundle'
});
const remoteAudio = new MediaStream();

const startCall = async (user, friend) => {
    if(!$('.calls_friendsPcVB1').hasClass('slide-in-top') || $('.calls_friendsPcVB1').hasClass('slide-out-top')){
        $('.calls_friendsPcVB1').removeClass('slide-out-top').addClass('slide-in-top');
        $('.otherActions_chatPcVB1 #calls i').removeClass('fi-rr-phone-call').addClass('fi-sr-phone-call');
        $('.topPart_friendsMMVB1').animate({ marginTop: '150px' }, 250);
    }
    // ANIMATE USER
    setTimeout(() => {
        if(!$('.callingUser_'+user.uid).hasClass('scale-in-center')) $('.callingUser_'+user.uid).addClass('scale-in-center');
        $('.callingUser_'+user.uid).css('filter', 'brightness(50%)')
    }, 400)
    // CALL
    window.self.pc = new RTCPeerConnection({
        iceServers: [
            {
                urls: 'stun:stun.cloudflare.com:3478'
            }
        ],
        bundlePolicy: 'max-bundle'
    });
    
    const localStream = await navigator.mediaDevices.getUserMedia({
        audio: true
    });

    const localAudioElement = document.getElementById('local-audio');
    localAudioElement.srcObject = localStream;

    window.self.transceivers = localStream.getTracks().map(track =>
        window.self.pc.addTransceiver(track, {
            direction: 'sendonly'
        })
    );
    window.self.app = new CallsApp(appId);

    await window.self.pc.setLocalDescription(await window.self.pc.createOffer());
    const newSessionResult = await window.self.app.newSession(
        window.self.pc.localDescription.sdp
    );
    await window.self.pc.setRemoteDescription(
        new RTCSessionDescription(newSessionResult.sessionDescription)
    );

    await new Promise((resolve, reject) => {
        window.self.pc.addEventListener('iceconnectionstatechange', ev => {
            console.log(ev.target.iceConnectionState)
            if (ev.target.iceConnectionState === 'connected') {
                resolve();
            }else if(ev.target.iceConnectionState === "failed"){
                reject('failed connection')
                $('.callingUser_'+user.uid).removeClass('scale-in-center').addClass('scale-out-center');
            }else if(ev.target.iceConnectionState === "disconnected"){
                reject('closed connection')
                $('.callingUser_'+user.uid).removeClass('scale-in-center').addClass('scale-out-center');
            }
        });
    });

    let trackObjects = window.self.transceivers.map(transceiver => {
        return {
            location: 'local',
            mid: transceiver.mid,
            trackName: transceiver.sender.track.id
        };
    });

    await window.self.pc.setLocalDescription(await window.self.pc.createOffer());
    const newLocalTracksResult = await window.self.app.newTracks(
        trackObjects,
        window.self.pc.localDescription.sdp
    );
    await window.self.pc.setRemoteDescription(
        new RTCSessionDescription(newLocalTracksResult.sessionDescription)
    );

    trackObjects = trackObjects.map(trackObject => {
        return {
            location: 'remote',
            sessionId: window.self.app.sessionId,
            trackName: trackObject.trackName
        };
    });

    const remoteTracksPromise = new Promise(resolve => {
        let tracks = [];
        window.self.pc.ontrack = event => {
            tracks.push(event.track);
            if (tracks.length >= 1) {
                $('.callingUser_'+user.uid).css('filter', 'none');
                resolve(tracks);
            }
        };
    });

    const newRemoteTracksResult = await window.self.app.newTracks(trackObjects);
    if (newRemoteTracksResult.requiresImmediateRenegotiation) {
        switch (newRemoteTracksResult.sessionDescription.type) {
            case 'offer':
                await window.self.pc.setRemoteDescription(
                    new RTCSessionDescription(
                        newRemoteTracksResult.sessionDescription
                    )
                );
                await window.self.pc.setLocalDescription(await window.self.pc.createAnswer());
                await window.self.app.sendAnswerSDP(window.self.pc.localDescription.sdp);
                break;
            case 'answer':
                throw new Error('An offer SDP was expected');
        }
    }

    const remoteTracks = await remoteTracksPromise;
    const remoteAudioElement = document.createElement('audio');
    remoteAudio.addTrack(remoteTracks[0]);
    remoteAudioElement.id="remote-audio";
    remoteAudioElement.style.display = "none";
    remoteAudioElement.autoplay = true;
    remoteAudioElement.srcObject = remoteAudio;
    document.querySelector(".callingUser_"+friend.uid).append(remoteAudioElement)
}

const endCall = (user, friend) => {
    if($('.calls_friendsPcVB1').hasClass('slide-in-top')){
        $('.calls_friendsPcVB1').removeClass('slide-in-top').addClass('slide-out-top');
        $('.otherActions_chatPcVB1 #calls i').removeClass('fi-sr-phone-call').addClass('fi-rr-phone-call');
        $('.topPart_friendsMMVB1').animate({ marginTop: 0 }, 250);
        // ANIMATE USER
        if($('.callingUser_'+user.uid).hasClass('scale-in-center')) $('.callingUser_'+user.uid).removeClass('scale-in-center').removeClass('scale-out-center');
    }
    // END CALL
    window.self.pc.close();
    $('#remote-audio').remove();
}

const mute = (user, friend) => {
    if(remoteAudio.getAudioTracks()[0].enabled === false){
        $('.callingUser_'+user.uid+' #muted').css('display', 'none');
        $('.actions_callsPcVB1 #mute i').removeClass('fi-sr-microphone-slash').addClass('fi-sr-microphone').removeAttr('style');
        remoteAudio.getAudioTracks()[0].enabled = true;
    }else{
        $('.callingUser_'+user.uid+' #muted').css('display', 'flex');
        $('.actions_callsPcVB1 #mute i').removeClass('fi-sr-microphone').addClass('fi-sr-microphone-slash').css('color', "#FF6565");
        remoteAudio.getAudioTracks()[0].enabled = false;
    }
}

export {
    startCall,
    endCall,
    mute
}