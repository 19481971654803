import { useEffect, useState } from "react";
import $ from 'jquery';
import axios from "axios";
import Cookies from "js-cookie";
import copy from 'copy-to-clipboard';
import * as marked from 'marked';
import { mangle } from "marked-mangle";
import DOMPurify from "dompurify";
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import { API_VERSION } from "../../Functions";
import { openModal } from "../../Functions/Modal";

// Verify if [user] has perms
const hasPerms = (uid) => {
    return new Promise((resolve, reject) => {
        axios.get(`https://api.snot.fr/v${API_VERSION}/staff/${uid}`, {
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                token: Cookies.get('loginToken'),
                app: 'web',
                retreive: true
            }
        })
        .then((response) => {
            resolve(response.data);
        })
        .catch((error) => {
            resolve(0);
        })
    })
}

// -- DELETE BUTTON
const DeleteButton = ({uid,post,realUID}) => {
    const { t } = useTranslation();
    const [button, setButton] = useState(<></>)

    useEffect(() => {
        if(uid !== "session"){
            hasPerms(uid).then(res => {
                if(res.perms !== undefined){
                    if(res.perms === "all" || res.perms.includes('delete')){
                        setButton(<div id="delete" className="more" data-modal="confirmDelete"><span data-modal="confirmDelete">{t('general.buttons.delete')}</span><i className="fi fi-rr-trash" data-modal="confirmDelete"></i></div>);
                    }
                }
            })
        }else{
            setButton(<div id="delete" className="more" data-modal="confirmDelete"><span data-modal="confirmDelete">{t('general.buttons.delete')}</span><i className="fi fi-rr-trash" data-modal="confirmDelete"></i></div>);
        }
    }, [])

    useEffect(() => {
        // EVENT LISTENERS
        if(document.querySelector('#delete.more')){
            document.querySelector('#delete.more').removeEventListener('click');
            document.querySelector('#delete.more').addEventListener('click', (e) => {
                $('#moreBM').trigger('click');
                openModal(e);
                // UI
                $('#confirmDelete .confirm #title').text(t('home.general.delete.title.spost'));
                $('#confirmDelete .confirm #subtitle').text(t('home.general.delete.subtitle.spost'));
                // EVENT FOR DELETE CONFIRM
                var reqUid = uid;
                if(uid === "session") reqUid = realUID;
                // ...
                $('.button_confirmMVB1').off('click').on('click', () => {
                    axios.delete(`https://api.snot.fr/v${API_VERSION}/posts/${post}`, {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            uid: reqUid,
                            app: 'web'
                        }
                    }).then(response => {
                        window.location.reload();
                    })
                })
            })
        }
    }, [button])

    return button;
}

// -- EDIT BUTTON
const EditButton = ({uid}) => {
    const { t } = useTranslation();
    const [button, setButton] = useState(<></>)

    useEffect(() => {
        if(uid !== "session"){
            hasPerms(uid).then(res => {
                if(res.perms !== undefined){
                    if(res.perms === "all" || res.perms.includes('edit')){
                        setButton(<div id="editMore" data-modal="editModal"><span data-modal="editModal">{t('general.buttons.edit')}</span><i className="fi fi-rr-pencil" data-modal="editModal"></i></div>);
                    }
                }
            })
        }else{
            setButton(<div id="editMore" data-modal="editModal"><span data-modal="editModal">{t('general.buttons.edit')}</span><i className="fi fi-rr-pencil" data-modal="editModal"></i></div>);
        }
    }, [uid])

    useEffect(() => {
        // EVENT LISTENERS
        if(document.getElementById('editMore')){
            document.getElementById('editMore').removeEventListener('click');
            document.getElementById('editMore').addEventListener('click', (e) => {
                $('#moreBM').trigger('click');
                openModal(e);
            })
        }
    }, [button])

    return button;
}

// -- COPY BUTTON
const CopyButton = ({data}) => {
    const { t } = useTranslation();

    useEffect(() => {
        // EVENT LISTENERS
        if(document.getElementById('copyMore')){
            document.getElementById('copyMore').removeEventListener('click');
            document.getElementById('copyMore').addEventListener('click', () => {
                copy(data.post[0].textonly);
                // UI
                $('#copyMore.copyText > span').text(t('general.copied'));
                setTimeout(() => {
                    if($('#copyMore.copyText')){
                        $('#copyMore.copyText > span').text(t('home.general.more.copy_text'))
                    }
                }, 2000)
            })
        }
    }, [])

    return (
        <div id="copyMore" className="copyText">
            <span>{t('home.general.more.copy_text')}</span>
            <i className="fi fi-rr-copy-alt"></i>
        </div>
    );
}

// -- REPORT BUTTON
const ReportButton = ({myUID, uid}) => {
    const { t } = useTranslation();

    useEffect(() => {
        // EVENT LISTENERS
        if(document.querySelector('#report.more')){
            document.querySelector('#report.more').removeEventListener('click');
            document.querySelector('#report.more').addEventListener('click', (e) => {
                $('#moreBM').trigger('click');
                openModal(e);
                // Change event for reply
                $('#reportModal #commentBtn_ComReplyMVB1').off('click').on('click', () => {
                    /* ! START MARKDOWN CONFIG ! */
                    // -- Override function
                    const renderer = {
                        link(href) {
                            return `<a href="${href}" target="_blank" rel="noreferrer" title="${href.replaceAll('mailto:', '')}">${href.replaceAll('mailto:', '')}</a>`;
                        }
                    };
                    // -- Set options
                    marked.use({
                        breaks: true,
                        renderer
                    }, mangle());
                    /* ! END MARKDOWN CONFIG ! */
                    
                    var reason = DOMPurify.sanitize(marked.parseInline($('#reportModal #contentReport.input_editMVB1').text().trim()), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']});
                
                    if(reason !== null && reason !== ''){
                        axios.post(
                            `https://api.snot.fr/v${API_VERSION}/report`,
                            { user: myUID, reported: uid, reason: reason, cr: null },
                            {
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                params: {
                                    token: Cookies.get('loginToken'),
                                    app: 'web'
                                }
                            }
                        ).then(response => $('#reportModal').trigger('click'))
                    }
                })
                $('#reportModal #btnWarnCancel_wLB1').off('click').on('click', () => $('#reportModal').trigger('click'))
            })
        }
    }, [])

    return (
        <div id="report" className="more" data-modal="reportModal">
            <span data-modal="reportModal">{t('general.buttons.report')}</span>
            <i className="fi fi-rr-flag" data-modal="reportModal"></i>
        </div>
    );
}

/* COMMENTS */
// -- DELETE BUTTON
const DeleteComButton = ({uid,com,realUID}) => {
    const { t } = useTranslation();
    const [button, setButton] = useState(<></>)

    useEffect(() => {
        if(uid !== "session"){
            hasPerms(uid).then(res => {
                if(res.perms !== undefined){
                    if(res.perms === "all" || res.perms.includes('delete')){
                        setButton(<div id="delete" className={"moreCom_"+com} data-modal="confirmDelete"><span data-modal="confirmDelete">{t('general.buttons.delete')}</span><i className="fi fi-rr-trash" data-modal="confirmDelete"></i></div>);
                    }
                }
            })
        }else{
            setButton(<div id="delete" className={"moreCom_"+com} data-modal="confirmDelete"><span data-modal="confirmDelete">{t('general.buttons.delete')}</span><i className="fi fi-rr-trash" data-modal="confirmDelete"></i></div>);
        }
    }, [])

    useEffect(() => {
        // EVENT LISTENERS
        if(document.querySelector('#delete.moreCom_'+com)){
            document.querySelector('#delete.moreCom_'+com).removeEventListener('click');
            document.querySelector('#delete.moreCom_'+com).addEventListener('click', (e) => {
                $('#moreBMC_'+com).trigger('click');
                openModal(e);
                // UI
                $('#confirmDelete .confirm #title').text(t('home.general.delete.title.comment'));
                $('#confirmDelete .confirm #subtitle').text(t('home.general.delete.subtitle.comment'));
                // EVENT FOR DELETE CONFIRM
                var reqUid = uid;
                if(uid === "session") reqUid = realUID;
                // ...
                $('.button_confirmMVB1').off('click').on('click', () => {
                    axios.delete(`https://api.snot.fr/v${API_VERSION}/comment/${com}`, {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            uid: reqUid,
                            app: 'web'
                        }
                    }).then(response => {
                        $('#confirmDelete').trigger('click');
                        // REMOVE REPLY
                        if($('#moreBMC_'+com) && $('#editModalCom_'+com) && $('.com_'+com)){
                            $('#moreBMC_'+com).remove();
                            $('#editModalCom_'+com).remove();
                            $('.com_'+com).remove();
                        }
                        // -1 TO REPLIES COUNT
                        const comCount = document.querySelector('.countInteractions_sMAMVeB1 #comment > span');
                        let comments = comCount.textContent;
                        comments--;

                        comCount.innerHTML = comments;
                    })
                })
            })
        }
    }, [button])

    return button;
}

// -- EDIT BUTTON
const EditComButton = ({uid,com}) => {
    const { t } = useTranslation();
    const [button, setButton] = useState(<></>)

    useEffect(() => {
        if(uid !== "session"){
            hasPerms(uid).then(res => {
                if(res.perms !== undefined){
                    if(res.perms === "all" || res.perms.includes('edit')){
                        setButton(<div id={"editMoreCom_"+com} data-modal={"editModalCom_"+com}><span data-modal={"editModalCom_"+com}>{t('general.buttons.edit')}</span><i className="fi fi-rr-pencil" data-modal={"editModalCom_"+com}></i></div>);
                    }
                }
            })
        }else{
            setButton(<div id={"editMoreCom_"+com} data-modal={"editModalCom_"+com}><span data-modal={"editModalCom_"+com}>{t('general.buttons.edit')}</span><i className="fi fi-rr-pencil" data-modal={"editModalCom_"+com}></i></div>);
        }
    }, [uid])

    useEffect(() => {
        // EVENT LISTENERS
        if(document.getElementById('editMoreCom_'+com)){
            document.getElementById('editMoreCom_'+com).removeEventListener('click');
            document.getElementById('editMoreCom_'+com).addEventListener('click', (e) => {
                $('#moreBMC_'+com).trigger('click');
                openModal(e);
            })
        }
    }, [button])

    return button;
}

// -- COPY BUTTON
const CopyComButton = ({uid,string}) => {
    const { t } = useTranslation();

    useEffect(() => {
        // EVENT LISTENERS
        if(document.getElementById('copyMoreCom_'+uid)){
            document.getElementById('copyMoreCom_'+uid).removeEventListener('click');
            document.getElementById('copyMoreCom_'+uid).addEventListener('click', () => {
                copy(string);
                // UI
                $('#copyMoreCom_'+uid+'.copyText > span').text(t('general.copied'));
                setTimeout(() => {
                    if($('#copyMoreCom_'+uid+'.copyText')){
                        $('#copyMoreCom_'+uid+'.copyText > span').text(t('home.general.more.copy_text'))
                    }
                }, 2000)
            })
        }
    }, [])

    return (
        <div id={"copyMoreCom_"+uid} className="copyText">
            <span>{t('home.general.more.copy_text')}</span>
            <i className="fi fi-rr-copy-alt"></i>
        </div>
    );
}

// -- REPORT BUTTON
const ReportComButton = ({myUID, uid}) => {
    const { t } = useTranslation();

    useEffect(() => {
        // EVENT LISTENERS
        if(document.querySelector('#report.moreCom')){
            document.querySelector('#report.moreCom').removeEventListener('click');
            document.querySelector('#report.moreCom').addEventListener('click', (e) => {
                $('#moreBMC_'+uid).trigger('click');
                openModal(e);
                // Change event for reply
                $('#reportModal #commentBtn_ComReplyMVB1').off('click').on('click', () => {
                    /* ! START MARKDOWN CONFIG ! */
                    // -- Override function
                    const renderer = {
                        link(href) {
                            return `<a href="${href}" target="_blank" rel="noreferrer" title="${href.replaceAll('mailto:', '')}">${href.replaceAll('mailto:', '')}</a>`;
                        }
                    };
                    // -- Set options
                    marked.use({
                        breaks: true,
                        renderer
                    }, mangle());
                    /* ! END MARKDOWN CONFIG ! */
                    
                    var reason = DOMPurify.sanitize(marked.parseInline($('#reportModal #contentReport.input_editMVB1').text().trim()), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']});
                
                    if(reason !== null && reason !== ''){
                        axios.post(
                            `https://api.snot.fr/v${API_VERSION}/report`,
                            { user: myUID, reported: uid, reason: reason, cr: 'com' },
                            {
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                params: {
                                    token: Cookies.get('loginToken'),
                                    app: 'web'
                                }
                            }
                        ).then(response => $('#reportModal').trigger('click'))
                    }
                })
                $('#reportModal #btnWarnCancel_wLB1').off('click').on('click', () => $('#reportModal').trigger('click'))
            })
        }
    }, [])

    return (
        <div id="report" className="moreCom" data-modal="reportModal">
            <span data-modal="reportModal">{t('general.buttons.report')}</span>
            <i className="fi fi-rr-flag" data-modal="reportModal"></i>
        </div>
    );
}

/* REPLIES */
// -- DELETE BUTTON
const DeleteReplyButton = ({uid,reply,parent,realUID}) => {
    const { t } = useTranslation();
    const [button, setButton] = useState(<></>)

    useEffect(() => {
        if(uid !== "session"){
            hasPerms(uid).then(res => {
                if(res.perms !== undefined){
                    if(res.perms === "all" || res.perms.includes('delete')){
                        setButton(<div id="delete" className={"moreReply_"+reply} data-modal="confirmDelete"><span data-modal="confirmDelete">{t('general.buttons.delete')}</span><i className="fi fi-rr-trash" data-modal="confirmDelete"></i></div>);
                    }
                }
            })
        }else{
            setButton(<div id="delete" className={"moreReply_"+reply} data-modal="confirmDelete"><span data-modal="confirmDelete">{t('general.buttons.delete')}</span><i className="fi fi-rr-trash" data-modal="confirmDelete"></i></div>);
        }
    }, [])

    useEffect(() => {
        // EVENT LISTENERS
        if(document.querySelector('#delete.moreReply_'+reply)){
            document.querySelector('#delete.moreReply_'+reply).removeEventListener('click');
            document.querySelector('#delete.moreReply_'+reply).addEventListener('click', (e) => {
                $('#moreBMR_'+reply).trigger('click');
                openModal(e);
                // UI
                $('#confirmDelete .confirm #title').text(t('home.general.delete.title.reply'));
                $('#confirmDelete .confirm #subtitle').text(t('home.general.delete.subtitle.reply'));
                // EVENT FOR DELETE CONFIRM
                var reqUid = uid;
                if(uid === "session") reqUid = realUID;
                // ...
                $('.button_confirmMVB1').off('click').on('click', () => {
                    axios.delete(`https://api.snot.fr/v${API_VERSION}/reply/${reply}`, {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            uid: reqUid,
                            app: 'web'
                        }
                    }).then(response => {
                        $('#confirmDelete').trigger('click');
                        // REMOVE REPLY
                        if($('#moreBMR_'+reply) && $('#editModalReply_'+reply) && $('.reply_'+reply)){
                            $('#moreBMR_'+reply).remove();
                            $('#editModalReply_'+reply).remove();
                            $('.reply_'+reply).remove();
                        }
                        // -1 TO REPLIES COUNT
                        // -- "SEE REPLY-IES"
                        const replyCountSee = document.querySelector('.linkReply_'+parent+' .count');
                        let repliesSee = replyCountSee.textContent;
                        repliesSee--;

                        replyCountSee.innerHTML = repliesSee;
                        // -- COUNT
                        const replyCount = document.querySelector('.com_'+parent+' .countInteractions_comMAMVeB1 #comment > span');
                        let replies = replyCount.textContent;
                        replies--;

                        replyCount.innerHTML = replies;
                    })
                });
            })
        }
    }, [button])

    return button;
}

// -- EDIT BUTTON
const EditReplyButton = ({uid,reply}) => {
    const { t } = useTranslation();
    const [button, setButton] = useState(<></>)

    useEffect(() => {
        if(uid !== "session"){
            hasPerms(uid).then(res => {
                if(res.perms !== undefined){
                    if(res.perms === "all" || res.perms.includes('edit')){
                        setButton(<div id={"editMoreReply_"+reply} data-modal={"editModalReply_"+reply}><span data-modal={"editModalReply_"+reply}>{t('general.buttons.edit')}</span><i className="fi fi-rr-pencil" data-modal={"editModalReply_"+reply}></i></div>);
                    }
                }
            })
        }else{
            setButton(<div id={"editMoreReply_"+reply} data-modal={"editModalReply_"+reply}><span data-modal={"editModalReply_"+reply}>{t('general.buttons.edit')}</span><i className="fi fi-rr-pencil" data-modal={"editModalReply_"+reply}></i></div>);
        }
    }, [uid])

    useEffect(() => {
        // EVENT LISTENERS
        if(document.getElementById('editMoreReply_'+reply)){
            document.getElementById('editMoreReply_'+reply).removeEventListener('click');
            document.getElementById('editMoreReply_'+reply).addEventListener('click', (e) => {
                $('#moreBMR_'+reply).trigger('click');
                openModal(e);
            })
        }
    }, [button])

    return button;
}

// -- COPY BUTTON
const CopyReplyButton = ({uid,string}) => {
    const { t } = useTranslation();

    useEffect(() => {
        // EVENT LISTENERS
        if(document.getElementById('copyMoreReply_'+uid)){
            document.getElementById('copyMoreReply_'+uid).removeEventListener('click');
            document.getElementById('copyMoreReply_'+uid).addEventListener('click', () => {
                copy(string);
                // UI
                $('#copyMoreReply_'+uid+'.copyText > span').text(t('general.copied'));
                setTimeout(() => {
                    if($('#copyMoreReply_'+uid+'.copyText')){
                        $('#copyMoreReply_'+uid+'.copyText > span').text(t('home.general.more.copy_text'))
                    }
                }, 2000)
            })
        }
    }, [])

    return (
        <div id={"copyMoreReply_"+uid} className="copyText">
            <span>{t('home.general.more.copy_text')}</span>
            <i className="fi fi-rr-copy-alt"></i>
        </div>
    );
}

// -- REPORT BUTTON
const ReportReplyButton = ({myUID, uid}) => {
    const { t } = useTranslation();

    useEffect(() => {
        // EVENT LISTENERS
        if(document.querySelector('#report.moreReply')){
            document.querySelector('#report.moreReply').removeEventListener('click');
            document.querySelector('#report.moreReply').addEventListener('click', (e) => {
                $('#moreBMR_'+uid).trigger('click');
                openModal(e);
                // Change event for reply
                $('#reportModal #commentBtn_ComReplyMVB1').off('click').on('click', () => {
                    /* ! START MARKDOWN CONFIG ! */
                    // -- Override function
                    const renderer = {
                        link(href) {
                            return `<a href="${href}" target="_blank" rel="noreferrer" title="${href.replaceAll('mailto:', '')}">${href.replaceAll('mailto:', '')}</a>`;
                        }
                    };
                    // -- Set options
                    marked.use({
                        breaks: true,
                        renderer
                    }, mangle());
                    /* ! END MARKDOWN CONFIG ! */
                    
                    var reason = DOMPurify.sanitize(marked.parseInline($('#reportModal #contentReport.input_editMVB1').text().trim()), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']});
                
                    if(reason !== null && reason !== ''){
                        axios.post(
                            `https://api.snot.fr/v${API_VERSION}/report`,
                            { user: myUID, reported: uid, reason: reason, cr: 'reply' },
                            {
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                params: {
                                    token: Cookies.get('loginToken'),
                                    app: 'web'
                                }
                            }
                        ).then(response => $('#reportModal').trigger('click'))
                    }
                })
                $('#reportModal #btnWarnCancel_wLB1').off('click').on('click', () => $('#reportModal').trigger('click'))
            })
        }
    }, [])

    return (
        <div id="report" className="moreReply" data-modal="reportModal">
            <span data-modal="reportModal">{t('general.buttons.report')}</span>
            <i className="fi fi-rr-flag" data-modal="reportModal"></i>
        </div>
    );
}

export {
    DeleteButton,
    EditButton,
    CopyButton,
    ReportButton,
    /* COMMENTS */
    DeleteComButton,
    EditComButton,
    ReportComButton,
    CopyComButton,
    /* REPLIES */
    DeleteReplyButton,
    EditReplyButton,
    ReportReplyButton,
    CopyReplyButton
}