// import i18next
import { useTranslation } from "react-i18next";

export default function News() {
    const { t } = useTranslation();

    return (
        <>
            <aside id="newsPopup" className="modal" aria-hidden="true" aria-modal="false" style={{display:"none"}}>
                <div className="modal-wrapper js-modal-stop" style={{width: 750,height:750}}>
                    <button className="js-modal-close" style={{display: "flex",justifyContent:"center",alignItems:"center",padding:10}}>
                        <i className="fi fi-rr-cross"></i>
                    </button>
                    <div id="WhatsNew">
                        <div id="titleTop">
                            <h1>Quoi de neuf ?</h1>
                            <h2>Le 04/09/24</h2>
                        </div>
                        <div id="describePart">
                            <p>
                                <span style={{fontWeight: 500,fontSize: 18}}>Bonjour !</span>
                                <br/>
                                La nouvelle mise à jour est là et elle concerne... tout le monde !
                                <br/><br/>
                                <span style={{fontWeight: 500,fontSize: 20,color: "#2B88FF"}}>Et... les nouveautés ?</span>
                                <div style={{margin:0,height: 0,width: "100%",borderBottom: "2px solid #2B88FF",marginTop: 10,marginBottom: 20}}></div>
                                <span style={{fontWeight: 500,fontSize: 20}}>Premièrement, la "Streak".</span>
                                <br/>
                                La plus "grosse" nouveauté est le système appelé "Streak", si vous ne savez pas ce que c'est : il suffit de regarder la flamme en haut à droite !
                                <br/>
                                Pour faire simple, si vous postez au moins 1 Spost par jour, votre Streak augmente ! Dans le cas contraire, vous recommencerez de zéro.
                                <br/><br/>
                                <span style={{fontWeight: 500,fontSize: 20}}>Les nouvelles fonctionnalités.</span>
                                <br/>
                                L'ajout de commentaire (et de réponses) a été complètement repensé, vous pouvez désormais y ajouter des images, vidéos, etc.
                                <br/><br/>
                                "Qui suit ce créateur ?" - vous vous êtes peut-être posé cette question pour un compte SNOT ? Eh bien on vous a écouté. Vous pouvez maintenant voir la liste d'abonnés et d'abonnements de quelqu'un ! Bien sûr, vous pouvez modifier l'accès à ces listes via les paramètres ("Confidentialité").
                                <br/><br/>
                                <span style={{fontWeight: 500,fontSize: 20}}>Informations.</span>
                                <br/>
                                "Pourquoi l'attente a été si longue ?" - effectivement l'attente pour cette mise à jour a été très... <strong>très</strong> longue et ce pour une raison : la refonte totale du code pour une meilleure stabilité dans le temps.
                                <br/><br/>
                                Pour vous rassurer, c'était la dernière grosse mise à jour. Désormais nous ferons des mises à jour à intervalles réguliers.
                                <br/><br/>
                                <span style={{fontWeight: "500",fontSize: 20,color: "rgb(214, 95, 95)"}}>
                                    C'est à peu près tout !
                                </span>
                                <div style={{margin:0,height: 0,width: "100%",borderBottom: "2px solid rgb(214, 95, 95)",marginTop: 10,marginBottom: 20}}></div>
                                Merci à vous d'avoir lu cet article, on se retrouvera prochainement avec la prochaine mise à jour !
                            </p>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    );
}